import React, { useEffect, useState } from 'react';

import DatePicker from 'react-date-picker';
import Select from 'react-select';

import { Calendar, Edit, Minus, Plus, Save, X } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, Row, TabPane } from 'reactstrap';
import { axiosInstance } from 'utils/axiosInstance';
import { toast } from 'react-toastify';

import './appliedTerritories.scss';
import { useTab } from 'components/tab/useTab';
import Tab from 'components/tab/Tab.';
import TabContentContainer from 'components/tab/TabContent';
import Multiselect from 'components/multiselect/Multiselect';
import {
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';

const StatusBadge = (status) => {
	switch (status) {
		case 'Programado':
			return <div className="event-status not-started">{status}</div>;
		case 'En progreso':
			return <div className="event-status in-progress">{status}</div>;
		case 'Finalizado':
			return <div className="event-status finished">{status}</div>;
		default:
			return <div className="event-status finished">-</div>;
	}
};

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '14px',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '14px',
		borderRadius: '6px'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34',
		fontWeight: '500',
		fontSize: '12px',
		lineHeight: '14px'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34',
			fontWeight: '500',
			fontSize: '12px',
			lineHeight: '14px'
		};
	}
};

const formatEventDate = (date) => {
	return new Date(date).toLocaleDateString('es-ES', {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric'
	});
};

const AppliedTerritoriesDetails = ({ sections, municipalities, lDistricts, fDistrics }) => {
	return (
		<>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{sections.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<span className="optionTitle">Sección</span>
						</div>
						<div>
							<ul className="list-horizontal">
								{sections.map((section, index) => {
									return <li key={`section-${section.section}`}>{`${section.section}`}</li>;
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{municipalities?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Municipios</h5>
						</div>
						<div>
							<ul className="list-horizontal">
								{municipalities.map((municipality, index) => {
									return (
										<li key={`municipalities-${municipality.municipality}`}>
											{`${municipality.municipality}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{lDistricts?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Distritos Locales</h5>
						</div>
						<div>
							<ul className="list-horizontal">
								{lDistricts.map((lDistrict, index) => {
									return (
										<li key={`lDistrict-${lDistrict.local_district_code}`}>
											{`${lDistrict.local_district_code}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{fDistrics?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Distritos Federales</h5>
						</div>
						<div>
							<ul className="list-horizontal">
								{fDistrics.map((fDistrict, index) => {
									return (
										<li key={`fDistrict-${fDistrict.district_code}`}>
											{`${fDistrict.district_code}`}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
		</>
	);
};

const TABS = ['Sección', 'Municipio', 'D. Local', 'D. Federal'];

const BasicEventDetails = ({ setLoadingEdit, data, eventId, entitiesOptions, fetchEventDetails }) => {
	const getEntityValueByName = (entity) => {
		return entity && entity.length > 0
			? entitiesOptions.filter((opt) => opt.label.toLowerCase() === entity[0].entity_name.toLowerCase())
			: null;
	};

	// Allows editing of the event
	const [editEvent, setEditEvent] = useState(false);

	// Handle territories tabs
	const { activeTab, handleChangeTab } = useTab(TABS[0], { replaceUrl: false });

	// The states of the event details
	const [eventName, setEventName] = useState(data?.name);
	const [eventTargetPeople, setEventTargetPeople] = useState(data?.target_people);
	const [eventDate, setEventDate] = useState(data?.start_time);
	const [eventEntity, setEventEntity] = useState(getEntityValueByName(data?.related_entities));
	const [eventPlace, setEventPlace] = useState(data?.location);

	// Fetch territories by entity
	const { data: sections, loading: loadingSections } = useSectionsWithFilters(eventEntity[0]);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(eventEntity[0]);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(eventEntity[0]);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(eventEntity[0]);

	// Handlers for the territories selected for the event to have them as default values for the multiselect component
	const [defaultSections, setDefaultSections] = useState([]);
	const [defaultMunicipality, setDefaultMunicipality] = useState([]);
	const [defaultLDistrict, setDefaultLDistrict] = useState([]);
	const [defaultFDistrict, setDefaultFDistrict] = useState([]);

	// Handle the selected territories
	const [selectedSections, setSelectedSections] = useState([]);
	const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);
	const [selectedLDistricts, setSelectedLDistricts] = useState([]);
	const [selectedFDistricts, setSelectedFDistricts] = useState([]);

	const [confirmModal, setConfirmModal] = useState(false);
	const [confirmModalMsg, setConfirmModalMsg] = useState('');

	const [loadingDefaultTerritories, setLoadingDefaultTerritories] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		try {
			setLoadingDefaultTerritories(true);

			const { sections: dataSections, related_districts, related_districts_local, related_municipality } = data;
			if (sections && sections.length > 0) {
				const defaultS = sections.filter((section) =>
					dataSections.some((ds) => section.value === parseInt(ds.section))
				);
				setDefaultSections(defaultS);
				setSelectedSections(defaultS);
			}

			if (lDistrics && lDistrics.length > 0) {
				const defaultLD = lDistrics.filter((lDistrict) =>
					related_districts_local.some((ld) => lDistrict.value === parseInt(ld.local_district_code))
				);
				setDefaultLDistrict(defaultLD);
				setSelectedLDistricts(defaultLD);
			}

			if (fDistrics && fDistrics.length > 0) {
				const defaultFD = fDistrics.filter((fDistrict) =>
					related_districts.some((fd) => fDistrict.value === parseInt(fd.district_code))
				);
				setDefaultFDistrict(defaultFD);
				setSelectedFDistricts(defaultFD);
			}

			if (municipalities && municipalities.length > 0) {
				const defaultM = municipalities.filter((municipality) =>
					related_municipality.some((m) => municipality.value === parseInt(m.municipality_code))
				);
				setDefaultMunicipality(defaultM);
				setSelectedMunicipalities(defaultM);
			}
		} catch (e) {
		} finally {
			setLoadingDefaultTerritories(false);
		}
	}, [sections, lDistrics, fDistrics, municipalities, data]);

	const handleEditEvent = () => {
		if (
			eventName &&
			eventTargetPeople > 0 &&
			eventPlace &&
			eventDate &&
			(selectedSections.length > 0 ||
				selectedMunicipalities.length > 0 ||
				selectedLDistricts.length > 0 ||
				selectedFDistricts.length > 0)
		) {
			const params = {
				name: eventName,
				target_people: eventTargetPeople,
				start_time: eventDate,
				entities: [eventEntity[0].value],
				location: eventPlace,
				fk_electoral_section: selectedSections.map((section) => section.id),
				municipalities: selectedMunicipalities.map((municipality) => municipality.id),
				districts_local: selectedLDistricts.map((district) => district.id),
				districts: selectedFDistricts.map((district) => district.id)
			};

			setLoadingEdit(true);

			axiosInstance({
				method: 'PUT',
				url: `/events/${eventId}/`,
				data: params
			})
				.then(() => {
					fetchEventDetails();

					toast.success('Se ha modificado el evento con éxito', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				})
				.catch(() => {
					setEventName(data?.name);
					setEventTargetPeople(data?.target_people);
					setEventDate(data?.start_time);
					setEventEntity(getEntityValueByName(data?.related_entities));
					setEventPlace(data?.location);
					toast.error('Error al modificar el evento', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				})
				.finally(() => {
					setLoadingEdit(false);
					setEditEvent(false);
				});
		} else {
			// eventName &&
			// 	eventTargetPeople > 0 &&
			// 	eventPlace &&
			// 	eventDate &&

			if (!eventName)
				toast.error('El nombre del evento no puede estar en blanco', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});

			if (eventTargetPeople <= 0)
				toast.error('El objetivo de personas debe ser mayor a 0', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});

			if (!eventPlace)
				toast.error('El lugar de realización del evento no puede estar en blanco', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});

			if (!eventDate)
				toast.error('La fecha del evento no puede estar en blanco', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});

			if (
				selectedSections.length > 0 ||
				selectedMunicipalities.length > 0 ||
				selectedLDistricts.length > 0 ||
				selectedFDistricts.length > 0
			)
				toast.error('Debe seleccionar un territorio para el evento', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
		}
	};

	const handleDeleteEvent = () => {
		setConfirmModalMsg(`Esta seguro que desea borrar el registro seleccionado?`);
		setConfirmModal(true);
	};

	const toggle = (state) => {
		setConfirmModal(!confirmModal);

		if (state) {
			axiosInstance({
				method: 'DELETE',
				url: `/events/${eventId}/`
			})
				.then(() => {
					toast.success('Se ha eliminado el evento con éxito', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
					navigate(`/tablas/eventos`);
				})
				.catch(() => {
					toast.error('Error al eliminar el evento', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				});
		}
	};
	const entityLabel = eventEntity ? eventEntity[0].label : '-';

	return (
		<Container fluid style={{ display: 'flex' }}>
			<Card style={{ width: '100%' }}>
				<CardHeader className="p-3">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<p
							style={{
								fontWeight: '500',
								fontSize: '16px',
								lineHeight: '19px',
								color: '#d32f34',
								margin: 0
							}}
						>
							Detalles de evento
						</p>
						{data &&
							data.status === 'Programado' &&
							(!editEvent ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-end',
										flexDirection: 'row',
										gap: '12px'
									}}
								>
									<Button
										style={{
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											gap: '4px'
										}}
										outline
										color="primary"
										onClick={() => handleDeleteEvent()}
									>
										Borrar
										<X size={16} />
									</Button>
									<Button
										style={{
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											gap: '4px'
										}}
										color="primary"
										onClick={() => setEditEvent(true)}
										disabled={
											loadingSections ||
											loadingMunicipalities ||
											loadingFDistrics ||
											loadingLDistrics
										}
									>
										Editar
										<Edit size={16} />
									</Button>
								</div>
							) : (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-end',
										flexDirection: 'row',
										gap: '12px'
									}}
								>
									<Button
										style={{
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											gap: '4px'
										}}
										outline
										color="primary"
										onClick={() => handleDeleteEvent()}
									>
										Borrar
										<X size={16} />
									</Button>
									<Button
										style={{
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											gap: '4px'
										}}
										outline
										color="primary"
										onClick={() => {
											setEventName(data?.name);
											setEventTargetPeople(data?.target_people);
											setEventDate(data?.start_time);
											setEventEntity(getEntityValueByName(data?.related_entities));
											setEventPlace(data?.location);
											setEditEvent(false);
										}}
									>
										Cancelar
										<X size={16} />
									</Button>
									<Button
										style={{
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											gap: '4px'
										}}
										color="primary"
										disabled={loadingDefaultTerritories}
										onClick={() => {
											handleEditEvent();
										}}
									>
										Guardar
										<Save size={16} />
									</Button>
								</div>
							))}
						{data && data.status !== 'Programado' && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									flexDirection: 'row',
									gap: '12px'
								}}
							>
								<Button
									style={{
										fontWeight: '400',
										fontSize: '12px',
										lineHeight: '14px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-end',
										gap: '4px'
									}}
									outline
									color="primary"
									onClick={() => handleDeleteEvent()}
								>
									Borrar
									<X size={16} />
								</Button>
							</div>
						)}
					</div>
				</CardHeader>
				<CardBody className="p-3">
					<Row
						className="py-2 event-info-line"
						style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
					>
						<Col>
							<Row className="event-info-titles">
								<span>Nombre</span>
							</Row>
							<Row>
								{editEvent ? (
									<Input
										type="text"
										name="eventName"
										id="eventName"
										placeholder="Nombre de evento"
										value={eventName}
										onChange={(e) => setEventName(e.target.value)}
										className="edit-event-info"
									/>
								) : (
									<span>{eventName}</span>
								)}
							</Row>
						</Col>
						<Col>
							<Row className="event-info-titles" style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<span>{StatusBadge(data?.status)}</span>
							</Row>
							<Row></Row>
						</Col>
					</Row>
					<Row
						className="py-2 event-info-line"
						style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
					>
						<Col>
							<Row className="event-info-titles">
								<span>Objetivo de personas</span>
							</Row>
							<Row>
								{editEvent ? (
									<InputGroup>
										<button
											className="increment-decrement-voters decrement"
											onClick={() => {
												if (eventTargetPeople === 0) {
													setEventTargetPeople(0);
													return;
												}
												setEventTargetPeople((count) => count - 1);
											}}
										>
											<Minus size={12} />
										</button>
										<Input
											style={{
												borderLeft: '0',
												borderRight: '0',
												textAlign: 'center'
											}}
											placeholder="Ingresar número"
											type="number"
											min={0}
											value={eventTargetPeople}
											pattern="[0-9]*"
											onChange={(e) =>
												setEventTargetPeople((v) =>
													Number(e.target.validity.valid ? e.target.value : v)
												)
											}
											className="edit-event-info"
										/>
										<button
											className="increment-decrement-voters increment"
											onClick={() => setEventTargetPeople((count) => count + 1)}
										>
											<Plus size={12} />
										</button>
									</InputGroup>
								) : (
									<span>{`${eventTargetPeople} personas`}</span>
								)}
							</Row>
						</Col>
						<Col>
							<Row className="event-info-titles">
								<span>Fecha de realización</span>
							</Row>
							<Row>
								{editEvent ? (
									<DatePicker
										value={new Date(eventDate)}
										onChange={(value) => {
											if (value.toLocaleDateString() <= new Date().toLocaleDateString()) {
												return;
											}
											setEventDate(value);
										}}
										clearIcon={false}
										format="dd/MM/yyyy"
										calendarIcon={<Calendar size={12} stroke="#d32f34" />}
										className="edit-event-info"
									/>
								) : (
									<span>{formatEventDate(eventDate)}</span>
								)}
							</Row>
						</Col>
					</Row>
					<Row
						className="py-2 event-info-line"
						style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
					>
						<Col>
							<Row className="event-info-titles">
								<span>Entidad</span>
							</Row>
							<Row>
								<Col className="p-0">
									{editEvent ? (
										<Select
											name="selectedPlace"
											options={entitiesOptions}
											id="selectedPlace"
											onChange={(option) => {
												setEventEntity([option]);
											}}
											placeholder="Seleccionar ubicación"
											value={eventEntity[0]}
											styles={colourStyles}
										></Select>
									) : (
										<span>{entityLabel}</span>
									)}
								</Col>
							</Row>
						</Col>
						<Col>
							<Row className="event-info-titles">
								<span>Lugar de realización</span>
							</Row>
							<Row>
								{editEvent ? (
									<Input
										type="text"
										name="eventPlaceDetail"
										id="eventPlaceDetail"
										placeholder="Ejemplo de lugar: Mercado de San Jacinto"
										value={eventPlace}
										onChange={(e) => setEventPlace(e.target.value)}
										className="edit-event-info"
									/>
								) : (
									<span>{eventPlace}</span>
								)}
							</Row>
						</Col>
					</Row>
					<Row
						className="py-2 event-info-line"
						style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
					>
						<Col>
							<Row className="event-info-titles">
								<span>Territorios</span>
							</Row>
							{editEvent ? (
								<>
									<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS} small />
									<TabContentContainer activeTab={activeTab} noPadding>
										<TabPane tabId={TABS[0]}>
											<Multiselect
												options={!eventEntity ? [] : sections}
												onChange={(options) =>
													setSelectedSections(
														options.map(({ value, label, id }) => ({
															value,
															label,
															id
														}))
													)
												}
												loading={loadingSections || loadingDefaultTerritories}
												defaultValue={defaultSections}
												disabled={
													selectedMunicipalities.length > 0 ||
													selectedLDistricts.length > 0 ||
													selectedFDistricts.length > 0
												}
											/>
										</TabPane>
										<TabPane tabId={TABS[1]}>
											<Multiselect
												options={!eventEntity ? [] : municipalities}
												onChange={(options) =>
													setSelectedMunicipalities(
														options.map(({ value, label, id }) => ({
															value,
															label,
															id
														}))
													)
												}
												loading={loadingMunicipalities || loadingDefaultTerritories}
												defaultValue={defaultMunicipality}
												disabled={
													selectedSections.length > 0 ||
													selectedLDistricts.length > 0 ||
													selectedFDistricts.length > 0
												}
											/>
										</TabPane>
										<TabPane tabId={TABS[2]}>
											<Multiselect
												options={!eventEntity ? [] : lDistrics}
												onChange={(options) =>
													setSelectedLDistricts(
														options.map(({ value, label, id }) => ({
															value,
															label,
															id
														}))
													)
												}
												loading={loadingLDistrics || loadingDefaultTerritories}
												defaultValue={defaultLDistrict}
												disabled={
													selectedSections.length > 0 ||
													selectedMunicipalities.length > 0 ||
													selectedFDistricts.length > 0
												}
											/>
										</TabPane>
										<TabPane tabId={TABS[3]}>
											<Multiselect
												options={!eventEntity ? [] : fDistrics}
												onChange={(options) =>
													setSelectedFDistricts(
														options.map(({ value, label, id }) => ({
															value,
															label,
															id
														}))
													)
												}
												loading={loadingFDistrics || loadingDefaultTerritories}
												defaultValue={defaultFDistrict}
												disabled={
													selectedSections.length > 0 ||
													selectedMunicipalities.length > 0 ||
													selectedLDistricts.length > 0
												}
											/>
										</TabPane>
									</TabContentContainer>
								</>
							) : (
								<AppliedTerritoriesDetails
									fDistrics={data.related_districts}
									lDistricts={data.related_districts_local}
									sections={data.sections}
									municipalities={data.related_municipality}
								/>
							)}
						</Col>
					</Row>
				</CardBody>
			</Card>
			<ConfirmModal isOpen={confirmModal} msg={confirmModalMsg} toggle={toggle} />
		</Container>
	);
};

export default BasicEventDetails;
