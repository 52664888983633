import { useState } from 'react';
import { Button, Modal, ModalBody, Row, Progress, Alert } from 'reactstrap';

import './registrar-elector-modal.scss';
// import './cropper.scss';
import { CheckCircle, Database, Edit, Upload, UserCheck, X } from 'react-feather';
import { Step, StepLabel, Stepper } from '@material-ui/core';

import { v4 as uuidv4 } from 'uuid';
import { axiosInstanceV1 } from 'utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { CREAR_ELECTOR_MANUAL } from 'routes';
import { ToastContainer, toast } from 'react-toastify';

import 'cropperjs/dist/cropper.css';
import CustomCropper from 'components/custom-cropper/CustomCropper';

const StepperIcons = (props) => {
	const { active, completed } = props;

	const icons = {
		1: <Upload size={16} />,
		2: <UserCheck size={16} />,
		3: <Database size={16} />
	};

	return (
		<div
			style={{
				backgroundColor: active ? '#fbeaeb' : completed ? '#d32f34' : '#FFF',
				color: completed ? '#FFF' : '#d32f34',
				width: 30,
				height: 30,
				display: 'flex',
				borderRadius: '50%',
				border: '1px solid #d32f34',
				justifyContent: 'center',
				alignItems: 'center',
				boxShadow: active && '0 4px 10px 0 rgba(0,0,0,.25)'
			}}
		>
			{icons[String(props.icon)]}
		</div>
	);
};

const SeleccionarRegistro = ({ setStep }) => {
	const navigate = useNavigate();

	return (
		<>
			<Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="p-4">
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '8px'
					}}
				>
					<span style={{ fontSize: '24px' }}>Nuevo elector</span>
					<span style={{ fontSize: '16px' }}>Seleccione cómo desea realizar la creación</span>
				</div>
			</Row>
			<div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
				<div
					style={{
						flex: '1',
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '16px'
					}}
				>
					<div
						className="creacion-manual-img"
						onClick={() => {
							navigate(CREAR_ELECTOR_MANUAL);
						}}
					></div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '8px'
						}}
					>
						<span style={{ fontSize: '16px' }}>Creación manual</span>
						<span style={{ fontSize: '12px', textAlign: 'center' }}>
							Ingresa los datos del elector, la credencial la puedes subir mas adelante
						</span>
					</div>
				</div>
				<div
					style={{
						flex: '1',
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '16px'
					}}
				>
					<div className="registrar-credencial-img" onClick={() => setStep(1)}></div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '8px'
						}}
					>
						<span style={{ fontSize: '16px' }}>Subir credencial</span>
						<span style={{ fontSize: '12px', textAlign: 'center' }}>
							Sube la imagen de la credencial del elector en formato .jpeg/.png
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

const DragAndDropZone = ({ id, setFiles }) => {
	const [previewCarnet, setPreviewCarnet] = useState({});
	const [imageToCrop, setImageToCrop] = useState({});

	const [dragActive, setDragActive] = useState(false);

	/**
	 * Cropper states
	 */
	const [imageHeight, setImageHeight] = useState(281.25);
	const [cropperIsOpen, setCropperIsOpen] = useState(false);

	const handleImageChange = (event) => {
		const image = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = () => {
			const img = new Image();

			img.src = reader.result;
			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				canvas.width = img.width > 900 ? 900 : img.width;
				canvas.height = img.width > 900 ? img.height * (900 / img.width) : img.height;

				setImageHeight(img.width > 900 ? img.height * (900 / img.width) : img.height);

				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
				setImageToCrop((prev) => ({ ...prev, [id]: canvas.toDataURL() }));
				setCropperIsOpen(true);
			};
		};
	};

	const handleSaveCroppedImage = async (base64Image) => {
		try {
			setPreviewCarnet((prev) => ({ ...prev, [id]: base64Image }));
			// Step 2: Convert the cropped canvas to a Base64 string
			const croppedImageUrl = base64Image;
			console.log('Cropped Image URL:', croppedImageUrl);

			// Step 3: Decode Base64 string into binary data
			const parts = croppedImageUrl.split(',');
			if (parts.length !== 2) {
				throw new Error('Invalid Base64 format');
			}
			const base64Data = parts[1];
			const byteCharacters = atob(base64Data);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);

			// Step 4: Create a Blob from the binary data
			const blob = new Blob([byteArray], { type: 'image/jpeg' });
			console.log('Blob Object:', blob);

			// Step 5: Create a File object with a unique name
			const name = id === 'front' ? `ine_app_front_img_${uuidv4()}.jpg` : `ine_app_back_img_${uuidv4()}.jpg`;
			const file = new File([blob], name, { type: 'image/jpeg' });
			console.log('File Object:', file);

			setFiles((prev) => ({ ...prev, [id]: file }));
		} catch (error) {
			console.error('Error saving cropped image:', error);
		}
	};

	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleDrop = async function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
	};

	return (
		<form onDragEnter={handleDrag} className="form-file-upload">
			<input
				id={`input-file-upload-${id}`}
				type="file"
				style={{ display: 'none' }}
				multiple={false}
				onChange={handleImageChange}
				accept="image/png, image/jpeg"
			/>
			<label
				htmlFor={`input-file-upload-${id}`}
				className={`label-file-upload ${dragActive ? 'drag-active' : ''}`}
			>
				{previewCarnet[id] ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '12px'
						}}
					>
						<CheckCircle size={24} color="#d32f34" />
						{<img src={previewCarnet[id]} alt="rotated" width={490} height={295} />}
						<Button color="primary" onClick={() => setCropperIsOpen(true)}>
							<Edit size={16} />
							Editar
						</Button>
					</div>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '16px'
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '36px',
								height: '36px',
								backgroundColor: 'white',
								border: '1px solid #d32f34',
								borderRadius: '50%'
							}}
						>
							<Upload size={24} color="#d32f34" />
						</div>
						<div className={id === 'front' ? 'front-image' : 'back-image'}></div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '8px'
							}}
						>
							<span
								style={{
									fontWeight: '400',
									fontSize: '12px',
									textAlign: 'center'
								}}
							>
								{'Selecciona el archivo JPEG o PNG a importar'}
							</span>
							<span
								style={{
									fontWeight: '400',
									fontSize: '12px',
									textAlign: 'center',
									color: '#AFAFAF'
								}}
							>
								{'o arrastra y sueéltalo aquí'}
							</span>
						</div>
					</div>
				)}
			</label>
			{dragActive && (
				<div
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						borderRadius: '1rem',
						top: '0px',
						right: '0px',
						bottom: '0px',
						left: '0px'
					}}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			)}
			<CustomCropper
				isOpen={cropperIsOpen}
				onClose={() => setCropperIsOpen(false)}
				src={imageToCrop[id]}
				onSave={handleSaveCroppedImage}
				imageHeight={imageHeight}
			/>
		</form>
	);
};

const SubirCredencialStep1 = ({ setActiveStep, handleBack, setOcrData, files, setFiles, curp }) => {
	const [uploadProgress, setUploadProgress] = useState(0);

	const handleNextStep = async () => {
		let formData = new FormData();

		const front = new File([files.front], `ine_app_front_img_${uuidv4()}.jpg`, { type: files.front.type });
		const back = new File([files.back], `ine_app_back_img_${uuidv4()}.jpg`, { type: files.back.type });

		formData.append('ine_img', front);
		formData.append('reverse_ine', back);

		setUploadProgress(1);

		axiosInstanceV1({
			url: curp ? '/credentials/?update=dashboard' : '/credentials/',
			method: 'POST',
			data: formData,
			onUploadProgress: (data) => {
				setUploadProgress(Math.round((98 * data.loaded) / data.total));
			}
		})
			.then(({ data }) => {
				setOcrData(data);
				setUploadProgress(100);
			})
			.then(() => {
				setTimeout(() => {
					setActiveStep((prev) => prev + 1);
				}, '2500');
			})
			.catch((err) => {
				if (err?.response?.data?.ine_img) {
					if (Array.isArray(err?.response?.data?.ine_img)) {
						err?.response?.data?.ine_img.forEach((item) => {
							if (item) {
								toast.error(item, {
									position: 'top-right',
									autoClose: 3000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'colored'
								});
							}
						});
					} else {
						toast.error(err?.response?.data?.ine_img, {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored'
						});
					}
				}

				if (err?.response?.data?.reverse_ine) {
					if (Array.isArray(err?.response?.data?.reverse_ine)) {
						err?.response?.data?.reverse_ine.forEach((item) => {
							if (item) {
								toast.error(item, {
									position: 'top-right',
									autoClose: 3000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'colored'
								});
							}
						});
					} else {
						toast.error(err?.response?.data?.reverse_ine, {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored'
						});
					}
				}
				setUploadProgress(0);
				setActiveStep(0);
				setFiles({});
			});
	};

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
				{uploadProgress === 0 && (
					<>
						<div
							style={{
								flex: '1',
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '16px'
							}}
						>
							<DragAndDropZone id={'front'} files={files} setFiles={setFiles} />
						</div>
						<div
							style={{
								flex: '1',
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '16px'
							}}
						>
							<DragAndDropZone id={'back'} files={files} setFiles={setFiles} />
						</div>
					</>
				)}
				{uploadProgress > 0 && uploadProgress < 100 && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '16px',
							minHeight: '150px'
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '8px'
							}}
						>
							<span style={{ fontSize: '18px', fontWeight: '400' }}>{uploadProgress.toFixed(0)}%</span>
							<span style={{ fontSize: '16px', fontWeight: '400', color: '#AFAFAF' }}>Subiendo INE</span>
						</div>
						<Progress value={uploadProgress} striped style={{ width: '80%', height: '10px' }} animated />
					</div>
				)}
				{uploadProgress === 100 && (
					<>
						<div
							style={{
								flex: '1',
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '16px'
							}}
						>
							<div className="form-file-upload">
								<div className="label-file-upload" style={{ backgroundColor: 'white' }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
											gap: '16px'
										}}
									>
										<CheckCircle size={24} color="#d32f34" />
										<span
											style={{
												fontWeight: '400',
												fontSize: '16px',
												textAlign: 'center',
												color: '#d32f34'
											}}
										>
											{'Frente subido y procesado'}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div
							style={{
								flex: '1',
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '16px'
							}}
						>
							<div className="form-file-upload">
								<div className="label-file-upload" style={{ backgroundColor: 'white' }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											alignItems: 'center',
											gap: '16px'
										}}
									>
										<CheckCircle size={24} color="#d32f34" />
										<span
											style={{
												fontWeight: '400',
												fontSize: '16px',
												textAlign: 'center',
												color: '#d32f34'
											}}
										>
											{'Reverso subido y procesado'}
										</span>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					alignItems: 'center',
					gap: '8px',
					marginTop: '16px'
				}}
			>
				<Button outline color="light-2x txt-dark" onClick={handleBack} disabled={uploadProgress > 0}>
					Anterior
				</Button>
				<Button color="primary" onClick={handleNextStep} disabled={uploadProgress > 0 && uploadProgress <= 100}>
					Siguiente
				</Button>
			</div>
			<ToastContainer />
		</>
	);
};

const SubirCredencialStep2 = ({ handleBack, ocrData, curp, files, otherData }) => {
	const navigate = useNavigate();

	const {
		text_detect: { fields }
	} = ocrData;

	const [aPaterno, ...aMaterno] = fields?.last_name.split(' ');

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						width: '100%',
						alignItems: 'center',
						gap: '16px',
						backgroundColor: '#FAFAFA',
						borderRadius: '8px',
						padding: '8px'
					}}
				>
					<img
						width="80px"
						height="80px"
						src={fields.passport_photo}
						alt="foto"
						style={{ borderRadius: '50%', border: '2px solid #d32f34' }}
					/>
					<div className="ocr-data-row">
						<div className="ocr-data-column">
							<span className="ocr-data-title">Nombre</span>
							<span className="ocr-data-names">{fields?.first_name || '-'}</span>
						</div>
						<div className="ocr-data-column">
							<span className="ocr-data-title">A. Paterno</span>
							<span className="ocr-data-names">{aPaterno || '-'}</span>
						</div>
						<div className="ocr-data-column">
							<span className="ocr-data-title">A. Materno</span>
							<span className="ocr-data-names">{aMaterno.join(' ') || '-'}</span>
						</div>
					</div>
					<div className="ocr-data-row">
						<div className="ocr-data-column">
							<span className="ocr-data-title">Sexo</span>
							<span className="ocr-data-info">
								{fields?.gender ? (fields?.gender === 'Female' ? 'Femenino' : 'Masculino') : '-'}
							</span>
						</div>
						<div className="ocr-data-column">
							<span className="ocr-data-title">Clave de elector</span>
							<span className="ocr-data-info">{fields?.electoral_key || '-'}</span>
						</div>
					</div>
					<div className="ocr-data-row">
						<div className="ocr-data-column">
							<span className="ocr-data-title">Domicilio</span>
							<span className="ocr-data-info">{fields?.address || '-'}</span>
						</div>
					</div>
					<div className="ocr-data-row">
						<div className="ocr-data-column">
							<span className="ocr-data-title">CURP</span>
							<span className="ocr-data-info">{fields?.curp || '-'}</span>
						</div>
					</div>
					<div className="ocr-data-row">
						<div className="ocr-data-column">
							<span className="ocr-data-title">Fecha de Nacimiento</span>
							<span className="ocr-data-info">{fields?.date_birth || '-'}</span>
						</div>
						<div className="ocr-data-column">
							<span className="ocr-data-title">Vigencia</span>
							<span className="ocr-data-info">{fields?.validity || '-'}</span>
						</div>
					</div>
				</div>
			</div>
			{curp && curp !== fields?.curp && (
				<Alert style={{ marginTop: '2em', textAlign: 'center' }} color="danger">
					El CURP a modificar y el CURP escaneado no coinciden.
				</Alert>
			)}

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					alignItems: 'center',
					gap: '8px',
					marginTop: '16px'
				}}
			>
				<Button outline color="light-2x txt-dark" onClick={handleBack}>
					Cancelar
				</Button>
				<Button
					color="primary"
					onClick={() => {
						navigate(
							curp ? `/electores/actualizar/${curp}` : `/electores/crear/${fields?.curp}`,
							curp
								? {
										state: { ocrData: ocrData, otherData: otherData }
								  }
								: {}
						);
					}}
					disabled={curp ? curp !== fields?.curp : false}
				>
					Confirmar y continuar
				</Button>
			</div>
		</>
	);
};

export const SubirCredencial = ({ handleBack, curp = '', otherData = {} }) => {
	const steps = ['Subir credencial', 'Confirmar datos', 'Completar datos'];

	const [files, setFiles] = useState({});

	const [activeStep, setActiveStep] = useState(0);

	const [ocrData, setOcrData] = useState(null);

	return (
		<>
			<Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="p-4">
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						gap: '8px'
					}}
				>
					<span style={{ fontSize: '24px' }}>Sube frente y reverso</span>
					<span style={{ fontSize: '16px', color: '#d32f34' }}>{`Paso ${activeStep + 1} de 3`}</span>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end'
					}}
				>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={StepperIcons}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</div>
			</Row>
			<>
				{activeStep === 0 && (
					<SubirCredencialStep1
						setActiveStep={setActiveStep}
						handleBack={handleBack}
						setOcrData={setOcrData}
						files={files}
						setFiles={setFiles}
						curp={curp}
					/>
				)}
				{activeStep === 1 && ocrData && (
					<SubirCredencialStep2
						handleBack={handleBack}
						ocrData={ocrData}
						curp={curp}
						files={files}
						otherData={otherData}
					/>
				)}
			</>
		</>
	);
};

const RegistrarElectorModal = ({ isOpen, toggle }) => {
	const [step, setStep] = useState(0);

	const closeModal = () => {
		setStep(0);
		toggle();
	};

	const handleBack = () => setStep(0);

	return (
		<Modal isOpen={isOpen} toggle={closeModal} centered size={'xl'}>
			<ModalBody style={{ width: '100%', padding: '8px 32px 32px 32px' }}>
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={closeModal} />
				</Row>
				{step === 0 && <SeleccionarRegistro setStep={setStep} />}
				{step === 1 && <SubirCredencial handleBack={handleBack} toggle={toggle} />}
			</ModalBody>
		</Modal>
	);
};

export default RegistrarElectorModal;
