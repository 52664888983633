import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Input, InputGroup, InputGroupText, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-date-picker';

import MaskedInput from 'react-maskedinput';

import { SocialIcon } from 'react-social-icons';

import './crear-elector.scss';
import { axiosInstance, axiosInstanceV1 } from 'utils/axiosInstance';
import {
	useEntities,
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import { AlertCircle, Calendar, Check, MapPin, X } from 'react-feather';

import { gql, useMutation, useQuery } from '@apollo/client';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ELECTORES } from 'routes';
import { ToastContainer, toast } from 'react-toastify';
import { graphQlClient } from 'store/graphql';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';
import { getLocalStorageItem } from 'services/localStorageService';
import { USER_DATA } from 'constant/localStorage';
import { CreateByOCR, CreateManually, EditManually } from 'constant';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import InternalLoader from 'layout/internal-loader';
import CustomCropper from 'components/custom-cropper/CustomCropper';

const fields = {
	address: 'Domicilio',
	curp: 'CURP',
	electoral_key: 'Clave Electoral',
	first_name: 'Nombre',
	id_state: 'Estado',
	last_name_maternal: 'Apellido materno',
	last_name_paternal: 'Apellido paterno',
	location: 'Ubicación',
	municipality: 'Municipio',
	phone_number: 'Teléfono',
	section: 'Sección',
	social_facebook: 'Facebook',
	social_instagram: 'Instagram',
	social_tiktok: 'Tiktok',
	social_twitter: 'X',
	special_group: 'Grupo Especial',
	type_affiliate: 'Tipo'
};

const getSocialUser = (url) => {
	if (!url) return '';

	const urlSplitted = url.split('/');

	return urlSplitted[urlSplitted.length - 1].replace(/@/g, '');
};

const getGender = (sexo) => {
	switch (sexo) {
		case 'Male':
			return 'H';
		case 'Female':
			return 'M';
		case 'Non-binary':
		default:
			return 'x';
	}
};

const genderOptions = [
	{ label: 'Masculino', value: 'Male' },
	{ label: 'Femenino', value: 'Female' },
	{ label: 'No binario', value: 'Non-binary' }
];

const selectStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const INE_ONE = gql`
	query IneOne($filter: FilterFindOneMexican_VoterInput) {
		ineOne(filter: $filter) {
			ocr
			userId
			status
			phoneNumber
			scanLocation {
				lat
				lng
			}
			socialMedia
			updatedAt
			createdAt
			user
			need
			fields
			specialGroups
			typeAffiliate
			nameSpecialGroups
		}
	}
`;

const INE_REVIEW = gql`
	mutation IneReview($record: ineReviewInputTCInput!) {
		ineReview(record: $record) {
			fields
			features
			ocr
			userId
			status
			socialMedia
			phoneNumber
			needId
		}
	}
`;

const RequiredField = () => (
	<span className="required-span">
		<AlertCircle color="#d32f34" size={12} />
		Este campo es requerido
	</span>
);

const InvalidField = () => (
	<span className="required-span">
		<AlertCircle color="#d32f34" size={12} />
		Este campo no cumple con los requisitos
	</span>
);

const CrearElector = ({ mode }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { curp } = useParams();

	/**
	 * Load data from apollo
	 */
	const { data: dataIne, loading: loadingIne } = useQuery(INE_ONE, {
		skip: mode === CreateByOCR || mode === CreateManually,
		client: graphQlClient,
		variables: {
			filter: {
				ocr: {
					curp: curp
				}
			}
		}
	});

	/**
	 * Load data from credentials
	 */
	const [loadingCredentials, setLoadingCredentials] = useState(false);

	/**
	 * Manejador de valores requeridos
	 */
	const [errors, setErrors] = useState([]);

	/**
	 * Opciones de selects de tipo y grupos especiales
	 */
	const [affiliateTypeOptions, setAffiliateTypeOptions] = useState([]);
	const [specialGroupsOptions, setSpecialGroupsOptions] = useState([]);

	/**
	 * States de demarcaciones
	 */
	const [selectedEntity, setSelectedEntity] = useState(null);

	const { loading: loadingEntities, data: entities } = useEntities();

	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedEntity);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedEntity);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedEntity);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedEntity);

	const [sectionsOptions, setSectionsOptions] = useState([]);

	const [selectedSection, setSelectedSection] = useState(null);
	const [selectedFDistrict, setSelectedFDistrict] = useState(null);
	const [selectedLDistrict, setSelectedLDistrict] = useState(null);
	const [selectedMunicipality, setSelectedMunicipality] = useState(null);

	const [selectedType, setSelectedType] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState(null);

	const [selectedDate, setSelectedDate] = useState(
		new Date(new Date().getFullYear() - 15, new Date().getMonth(), new Date().getDate())
	);

	const [selectedGender, setSelectedGender] = useState(null);

	const [localizationData, setLocalizationData] = useState({
		lat: '',
		lng: ''
	});

	const [telefono, setTelefono] = useState('');
	const [sociales, setSociales] = useState({
		instagram: '',
		twitter: '',
		facebook: '',
		tiktok: ''
	});

	const initialFormData = {
		address: '',
		aMaterno: '',
		aPaterno: '',
		cic: '',
		curp: '',
		date_birth: null,
		district: null,
		electoral_key: '',
		emision: '',
		first_name: '',
		full_name: null,
		gender: null,
		id_state: null,
		last_name: null,
		local_district_id: null,
		local_district: null,
		municipality: null,
		nameSpecialGroups: '',
		ocr: '',
		passport_photo: '',
		section: null,
		specialGroups: true,
		state: null,
		typeAffiliate: null,
		validity: null,
		vigencia: ''
	};

	const [dataCredentials, setDataCredentials] = useState(null);

	const [dataFormulario, setDataFormulario] = useState(initialFormData);

	const [loadingCreate, setLoadingCreate] = useState(false);

	/**
	 * Validaciones de campos con reglas especificas
	 */
	const [curpValidation, setCurpValidation] = useState([]);

	const [electoralKeyValidation, setElectoralKeyValidation] = useState([]);

	const [cicValidation, setCicValidation] = useState([]);

	const [ocrValidation, setOcrValidation] = useState([]);

	const [emisionValidation, setEmisionValidation] = useState([]);

	const [vigenciaValidation, setVigenciaValidation] = useState([]);

	const [blockSection, setBlockSection] = useState(false);

	const [ineReview] = useMutation(INE_REVIEW, {
		errorPolicy: 'all',
		client: graphQlClient
	});

	/**
	 * Passport photo and cropper states
	 */
	const [imageToCrop, setImageToCrop] = useState({});
	const [imageHeight, setImageHeight] = useState(281.25);
	const [cropperIsOpen, setCropperIsOpen] = useState(false);

	const fileInputRef = useRef(null);

	useEffect(() => {
		if (mode !== CreateManually) {
			if (location?.state?.ocrData) {
				const ocrData = location?.state?.ocrData;
				const [aPaterno, ...aMaterno] = ocrData?.text_detect?.fields?.last_name?.split(' ');

				const dataVoter = {
					typeAffiliate: ocrData?.affiliate_type || null,
					address: ocrData?.text_detect?.fields?.address || '',
					curp: ocrData?.text_detect?.fields?.curp || '',
					electoral_key: ocrData?.text_detect?.fields?.electoral_key || '',
					first_name: ocrData?.text_detect?.fields?.first_name || '',
					full_name: ocrData?.text_detect?.fields?.full_name || '',
					gender: ocrData?.text_detect?.fields?.gender || null,
					id_state: ocrData?.text_detect.fields.id_state || null,
					last_name: ocrData?.text_detect.fields.last_name || '',
					municipality: ocrData?.text_detect?.fields.municipality || null,
					section: ocrData?.text_detect?.fields.section || null,
					state: ocrData?.text_detect?.fields.state || null,
					validity: ocrData?.text_detect?.fields.validity || null,
					date_birth: ocrData?.text_detect?.fields.date_birth || '',
					nameSpecialGroups: '',
					aPaterno: aPaterno || '',
					aMaterno: aMaterno.join(' ') || '',
					passport_photo: ocrData?.text_detect?.fields?.passport_photo || '',
					cic: ocrData?.text_detect?.fields?.cic || '',
					ocr: ocrData?.text_detect?.fields?.ocr || '',
					emision: ocrData?.text_detect?.fields?.emision,
					vigencia: ocrData?.text_detect?.fields?.expiration,
					district: ocrData?.text_detect?.fields?.district || null,
					local_district: ocrData?.text_detect?.fields?.local_district || null,
					local_district_id: ocrData?.text_detect?.fields?.local_district_id || null
				};

				setDataFormulario((prev) => ({ ...prev, ...dataVoter }));

				setDataCredentials((prev) => ({ ...prev, ...dataVoter }));

				setSelectedGender(
					genderOptions.filter((gen) => gen.value === ocrData?.text_detect?.fields?.gender || null)
				);
			} else {
				setLoadingCredentials(true);

				axiosInstanceV1({ url: `/credentials/${curp}`, method: 'GET' })
					.then(({ data: dataCredentials }) => {
						const [aPaterno, ...aMaterno] = dataCredentials?.text_detect?.fields?.last_name?.split(' ');

						const dataVoter = {
							typeAffiliate: dataCredentials?.affiliate_type || null,
							address: dataCredentials?.text_detect?.fields?.address || '',
							curp: dataCredentials?.text_detect?.fields?.curp || '',
							electoral_key: dataCredentials?.text_detect?.fields?.electoral_key || '',
							first_name: dataCredentials?.text_detect?.fields?.first_name || '',
							full_name: dataCredentials?.text_detect?.fields?.full_name || '',
							gender: dataCredentials?.text_detect?.fields?.gender || null,
							id_state: dataCredentials?.text_detect.fields.id_state || null,
							last_name: dataCredentials?.text_detect.fields.last_name || '',
							municipality: dataCredentials?.text_detect?.fields.municipality || null,
							section: dataCredentials?.text_detect?.fields.section || null,
							state: dataCredentials?.text_detect?.fields.state || null,
							validity: dataCredentials?.text_detect?.fields.validity || null,
							date_birth: dataCredentials?.text_detect?.fields.date_birth || '',
							nameSpecialGroups: '',
							aPaterno: aPaterno || '',
							aMaterno: aMaterno.join(' ') || '',
							passport_photo: dataCredentials?.text_detect?.fields?.passport_photo || '',
							cic: dataCredentials?.text_detect?.fields?.cic || '',
							ocr: dataCredentials?.text_detect?.fields?.ocr || '',
							emision: dataCredentials?.text_detect?.fields?.emision,
							vigencia: dataCredentials?.text_detect?.fields?.expiration,
							district: dataCredentials?.text_detect?.fields?.district || null,
							local_district: dataCredentials?.text_detect?.fields?.local_district || null,
							local_district_id: dataCredentials?.text_detect?.fields?.local_district_id || null
						};

						setDataFormulario((prev) => ({ ...prev, ...dataVoter }));

						setDataCredentials((prev) => ({ ...prev, ...dataVoter }));

						setSelectedGender(
							genderOptions.filter(
								(gen) => gen.value === dataCredentials?.text_detect?.fields?.gender || null
							)
						);
					})
					.catch((err) => console.log(err))
					.finally(() => setLoadingCredentials(false));
			}

			if (mode === EditManually && dataIne) {
				setSociales({
					facebook: getSocialUser(dataIne?.ineOne?.socialMedia?.facebook),
					instagram: getSocialUser(dataIne?.ineOne?.socialMedia?.instagram),
					tiktok: getSocialUser(dataIne?.ineOne?.socialMedia?.tiktok),
					twitter: getSocialUser(dataIne?.ineOne?.socialMedia?.twitter)
				});

				setLocalizationData({
					lat: dataIne?.ineOne?.scanLocation?.lat,
					lng: dataIne?.ineOne?.scanLocation?.lng
				});

				setTelefono(dataIne?.ineOne?.phoneNumber);
			}
		}
	}, [curp, dataIne, mode, location?.state?.ocrData]);

	useEffect(() => {
		if (dataFormulario.passport_photo) {
			// Optionally resize the image before setting it to imageToCrop
			const img = new Image();
			img.src = dataFormulario.passport_photo;

			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				// Resize logic (optional)
				const maxWidth = 900; // Set a maximum width for the image
				let width = img.width;
				let height = img.height;

				if (img.width > maxWidth) {
					width = maxWidth;
					height = img.height * (maxWidth / img.width);
				}

				canvas.width = width;
				canvas.height = height;
				setImageHeight(height); // Update the image height for the cropper

				ctx.drawImage(img, 0, 0, width, height);
				setImageToCrop(canvas.toDataURL()); // Set the resized image to imageToCrop
			};

			img.onerror = (error) => {
				console.error('Error loading image:', error);
			};
		}
	}, [dataFormulario.passport_photo]);

	useEffect(() => {
		axiosInstance({ url: '/type-affiliate/', method: 'GET' })
			.then(({ data }) => {
				setAffiliateTypeOptions(data.results.map((aff) => ({ value: aff.id, label: aff.name })));

				if (dataIne && dataIne?.ineOne && dataIne?.ineOne?.typeAffiliate) {
					const affiliate = data.results.filter((aff) => aff.name === dataIne?.ineOne?.typeAffiliate);
					setSelectedType({ value: affiliate[0].id, label: affiliate[0].name });
				}
			})
			.catch((err) => console.log(err));

		axiosInstance({ url: '/special-groups/', method: 'GET' })
			.then(({ data }) => setSpecialGroupsOptions(data.results.map((sg) => ({ value: sg.id, label: sg.name }))))
			.catch((err) => console.log(err));
	}, [dataIne]);

	useEffect(() => {
		if (dataCredentials) {
			const { id_state, date_birth } = dataCredentials;

			if (id_state && entities && entities?.length > 0) {
				entities.forEach((ent) => {
					if (ent.code === Number(id_state)) {
						setSelectedEntity({ label: ent.name, value: ent.code });
					}
				});
			}

			if (date_birth) {
				setSelectedDate(
					moment(date_birth, 'DD/MM/YYYY').isValid()
						? moment(date_birth, 'DD/MM/YYYY').toDate()
						: moment(date_birth, 'YYYY/MM/DD').isValid()
						? moment(date_birth, 'YYYY/MM/DD').toDate()
						: moment().subtract(15, 'years').toDate()
				);
			}
		}
	}, [dataCredentials, entities]);

	useEffect(() => {
		if (blockSection === false && dataCredentials) {
			const { section, municipality, local_district, district } = dataCredentials;

			if (section && sections && sections.length > 0) {
				const sect = sections.find((option) => option.value === Number(section));

				if (!sect) {
					const obj = { label: section, value: section };

					setSectionsOptions([...sections, obj]);
					setSelectedSection(obj);
					setBlockSection(true);
				} else {
					if (lDistrics && lDistrics.length > 0) {
						setSelectedLDistrict(lDistrics.find((option) => option.value === Number(local_district)));
					}

					if (fDistrics && fDistrics.length > 0) {
						setSelectedFDistrict(fDistrics.find((option) => option.value === Number(district)));
					}

					setSelectedSection(sect);
					setSectionsOptions(sections);
				}

				if (municipalities && municipalities.length > 0) {
					for (const mun of municipalities) {
						if (Number(municipality) === mun.value) setSelectedMunicipality(mun);
					}
				}
			} else {
				setSectionsOptions(sections);
			}
		} else {
			setSectionsOptions(sections);
		}
	}, [sections, fDistrics, lDistrics, municipalities, blockSection, dataCredentials]);

	const handleEntityChange = async (estado) => {
		setSelectedSection(null);
		setSelectedFDistrict(null);
		setSelectedLDistrict(null);
		setSelectedMunicipality(null);

		setDataFormulario((prev) => ({
			...prev,
			section: null,
			municipality: null,
			local_district: null,
			local_district_id: null,
			district: null
		}));

		setSelectedEntity(estado);
	};

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	const handleSaveVoter = async (event) => {
		event.preventDefault();

		setLoadingCreate(true);

		const newErrors = [];

		if (!dataFormulario.typeAffiliate) newErrors.push('tipo');

		if (!dataFormulario.specialGroups) newErrors.push('grupoEspecial');

		if (!dataFormulario.first_name) newErrors.push('nombre');

		if (!dataFormulario.aPaterno) newErrors.push('aPaterno');

		if (!dataFormulario.aMaterno) newErrors.push('aMaterno');

		if (!dataFormulario.gender) newErrors.push('sexo');

		if (!dataFormulario.curp) newErrors.push('curp-requerido');

		if (!dataFormulario.state) newErrors.push('estado');

		if (!dataFormulario.section) newErrors.push('seccion');

		if (!dataFormulario.municipality) newErrors.push('municipio');

		if (!dataFormulario.date_birth) newErrors.push('date_birth');

		if (!localizationData.lat) newErrors.push('latitud');

		if (!localizationData.lng) newErrors.push('longitud');

		if (!dataFormulario.address) newErrors.push('domicilio');

		if (!telefono) newErrors.push('telefono');

		if (!dataFormulario.passport_photo) newErrors.push('passport_photo');

		// if (!sociales.instagram) newErrors.push('instagram');

		// if (!sociales.twitter) newErrors.push('twitter');

		// if (!sociales.facebook) newErrors.push('facebook');

		// if (!sociales.tiktok) newErrors.push('tiktok');

		if (curpValidation.filter(({ type }) => type === 'error').length > 0) {
			newErrors.push('curp-invalido');
		}

		if (electoralKeyValidation.filter(({ type }) => type === 'error').length > 0) {
			newErrors.push('claveElector-invalido');
		}

		if (cicValidation.filter(({ type }) => type === 'error').length > 0) {
			newErrors.push('cic-invalido');
		}

		if (ocrValidation.filter(({ type }) => type === 'error').length > 0) {
			newErrors.push('ocr-invalido');
		}

		if (emisionValidation.filter(({ type }) => type === 'error').length > 0) {
			newErrors.push('emision-invalido');
		}

		if (vigenciaValidation.filter(({ type }) => type === 'error').length > 0) {
			newErrors.push('vigencia-invalido');
		}

		if (mode === CreateByOCR) {
			if (!dataFormulario.electoral_key) {
				newErrors.push('claveElector-requerido');
			} else {
				if (electoralKeyValidation.filter(({ type }) => type === 'error').length > 0) {
					newErrors.push('claveElector-invalido');
				}
			}

			if (!dataFormulario.cic) {
				newErrors.push('cic-requerido');
			} else {
				if (cicValidation.filter(({ type }) => type === 'error').length > 0) {
					newErrors.push('cic-invalido');
				}
			}

			if (!dataFormulario.ocr) {
				newErrors.push('ocr-requerido');
			} else {
				if (ocrValidation.filter(({ type }) => type === 'error').length > 0) {
					newErrors.push('ocr-invalido');
				}
			}

			if (!dataFormulario.emision) {
				newErrors.push('emision-requerido');
			} else {
				if (emisionValidation.filter(({ type }) => type === 'error').length > 0) {
					newErrors.push('emision-invalido');
				}
			}

			if (!dataFormulario.vigencia) {
				newErrors.push('vigencia-requerido');
			} else {
				if (vigenciaValidation.filter(({ type }) => type === 'error').length > 0) {
					newErrors.push('vigencia-invalido');
				}
			}

			if (!dataFormulario.vigencia) newErrors.push('vigencia-requerido');

			if (!dataFormulario.address) newErrors.push('domicilio');
		}

		if (newErrors.length > 0) {
			setErrors(newErrors);

			setLoadingCreate(false);

			return;
		}

		const userData = await getLocalStorageItem(USER_DATA);

		const ocr = {
			...dataFormulario,
			date_birth: moment(dataFormulario.date_birth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			validity: `${dataFormulario.emision} - ${dataFormulario.vigencia}`
		};

		const socialMedia = {
			instagram: sociales.instagram,
			twitter: sociales.twitter,
			facebook: sociales.facebook,
			tiktok: sociales.tiktok
		};

		const phoneNumber = telefono;

		const typeAffiliate = dataFormulario.typeAffiliate;

		const specialGroups = dataFormulario.specialGroups;

		const nameSpecialGroups = dataFormulario.nameSpecialGroups;

		const scanLocation = {
			lat: localizationData.lat.toString(),
			lng: localizationData.lng.toString()
		};

		const record = {
			status: 'REVIEWED',
			ocr,
			userId: userData.user_id,
			socialMedia,
			phoneNumber,
			typeAffiliate,
			specialGroups,
			nameSpecialGroups,
			scanLocation,
			createdAt: new Date()
		};

		let changedData = {};

		for (const key in dataFormulario) {
			if (dataFormulario[key] !== initialFormData[key]) {
				if (key === 'date_birth') {
					changedData[key] = moment(dataFormulario[key], 'DD/MM/YYYY').format('YYYY-MM-DD');
					continue;
				}

				if (key === 'gender') {
					changedData[key] = getGender(dataFormulario.gender);
					continue;
				}

				changedData[key] = dataFormulario[key];
			}
		}

		if (socialMedia.facebook) changedData.social_networks_facebook = socialMedia.facebook;

		if (socialMedia.twitter) changedData.social_networks_twitter = socialMedia.twitter;

		if (socialMedia.instagram) changedData.social_networks_instagram = socialMedia.instagram;

		if (socialMedia.tiktok) changedData.social_networks_tiktok = socialMedia.tiktok;

		if (mode === EditManually) {
			try {
				await ineReview({ variables: { record } });

				navigate(`/electores/${dataFormulario.curp}`);
			} catch (error) {
				toast.error('Error al actualizar el elector', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			} finally {
				setLoadingCreate(false);
			}
		} else {
			try {
				if (mode === CreateManually) {
					const data = {
						address: dataFormulario.address,
						curp: dataFormulario.curp,
						date_birth: moment(dataFormulario.date_birth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
						electoral_key: dataFormulario.electoral_key,
						gender: getGender(dataFormulario.gender),
						location: `${localizationData.lat} ${localizationData.lng}`,
						municipality: dataFormulario.municipality,
						last_name_paternal: dataFormulario.aPaterno,
						last_name_maternal: dataFormulario.aMaterno,
						first_name: dataFormulario.first_name,
						section: dataFormulario.section,
						id_state: dataFormulario.id_state,
						social_facebook: socialMedia.facebook,
						social_twitter: socialMedia.twitter,
						social_instagram: socialMedia.instagram,
						social_tiktok: socialMedia.tiktok,
						phone_number: phoneNumber,
						type_affiliate: dataFormulario.typeAffiliate,
						special_group: dataFormulario.nameSpecialGroups
					};

					await axiosInstanceV1({
						url: '/credentials/manual-register/',
						data,
						method: 'POST',
						headers: { 'Content-Type': 'application/json' }
					});
				}

				await ineReview({
					variables: {
						record: {
							...record,
							isManual: mode === CreateManually
						}
					}
				});

				navigate(`/electores/${dataFormulario.curp}`);
			} catch (error) {
				const messages = error?.response?.data;

				Object.entries(messages).forEach(([key, messages]) => {
					if (Array.isArray(messages)) {
						messages.forEach((message) => {
							toast.error(`${fields[key]}: ${message}`, {
								position: 'top-right',
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: 'colored'
							});
						});
					} else {
						toast.error(`Error al crear el elector: ${messages}`, {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored'
						});
					}
				});
			} finally {
				setLoadingCreate(false);
			}
		}
	};

	const getLocation = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				setLocalizationData(() => ({
					lat: position.coords.latitude,
					lng: position.coords.longitude
				}));
			},
			(error) => {
				toast.error('No pudimos obtener tu localización', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			}
		);
	};

	/**
	 * Validacion de campo CURP para que cumpla con los valores correspondientes
	 * CURP -> longitud 18 caracteres
	 * Pt1 -> 4 letras
	 * Pt2 -> 6 numeros
	 * Pt3 -> 1 letra
	 * Pt4 -> 2 letras
	 * Pt5 -> 3 letras
	 * Pt6 -> 2 numeros
	 */
	const handleCurpChange = (e) => {
		const { value: curp } = e.target;

		const unformattedCURP = curp.replace(/-/g, '').replace(/_/g, '');

		setDataFormulario((prev) => ({ ...prev, curp: unformattedCURP }));

		let messages = [];

		messages.push({
			type: unformattedCURP.length < 18 ? 'error' : 'success',
			message: 'El CURP debe tener 18 caracteres.'
		});

		messages.push({
			type: !unformattedCURP.slice(0, 4).match(/[a-zA-Z]{4}/) ? 'error' : 'success',
			message: 'La parte 1 del CURP debe tener 4 letras.'
		});

		messages.push({
			type: !unformattedCURP.slice(4, 10).match(/[0-9]{6}/) ? 'error' : 'success',
			message: 'La parte 2 del CURP debe tener 6 números.'
		});

		messages.push({
			type: !unformattedCURP.slice(10, 11).match(/[a-zA-Z]{1}/) ? 'error' : 'success',
			message: 'La parte 3 del CURP debe tener 1 letra.'
		});

		messages.push({
			type: !unformattedCURP.slice(11, 13).match(/[a-zA-Z]{2}/) ? 'error' : 'success',
			message: 'La parte 4 del CURP debe tener 2 letras.'
		});

		messages.push({
			type: !unformattedCURP.slice(13, 16).match(/[a-zA-Z]{3}/) ? 'error' : 'success',
			message: 'La parte 5 del CURP debe tener 3 letras.'
		});

		messages.push({
			type: !unformattedCURP.slice(16).match(/[0-9]{2}/) ? 'error' : 'success',
			message: 'La parte 6 del CURP debe tener 2 números.'
		});

		setCurpValidation(messages);
	};

	/**
	 * Validacion de campo Clave electoral para que cumpla con los valores correspondientes
	 * Clave electoral -> longitud 18 caracteres
	 * Pt1 -> 6 letras
	 * Pt2 -> 6 numeros
	 * Pt3 -> 2 numeros
	 * Pt4 -> 1 letra
	 * Pt5 -> 3 numeros
	 */
	const handleElectoralKeyChange = (e) => {
		const { value: electoralKey } = e.target;

		const unformattedEK = electoralKey.replace(/-/g, '').replace(/_/g, '');

		setDataFormulario((prev) => ({ ...prev, electoral_key: unformattedEK }));

		let messages = [];

		messages.push({
			type: unformattedEK.length < 18 ? 'error' : 'success',
			message: 'La clave de elector debe tener 18 caracteres.'
		});

		messages.push({
			type: !unformattedEK.slice(0, 6).match(/[a-zA-Z]{6}/) ? 'error' : 'success',
			message: 'La parte 1 de la clave de elector debe tener 6 letras.'
		});

		messages.push({
			type: !unformattedEK.slice(6, 12).match(/[0-9]{6}/) ? 'error' : 'success',
			message: 'La parte 2 de la clave de elector debe tener 6 números.'
		});

		messages.push({
			type: !unformattedEK.slice(12, 14).match(/[0-9]{2}/) ? 'error' : 'success',
			message: 'La parte 3 de la clave de elector debe tener 2 números.'
		});

		messages.push({
			type: !unformattedEK.slice(14, 15).match(/[a-zA-Z]{1}/) ? 'error' : 'success',
			message: 'La parte 4 de la clave de elector debe tener 1 letra.'
		});

		messages.push({
			type: !unformattedEK.slice(15).match(/[0-9]{3}/) ? 'error' : 'success',
			message: 'La parte 5 de la clave de elector debe tener 3 números.'
		});

		setElectoralKeyValidation(messages);
	};

	const handleCICChange = (e) => {
		const { value: cic } = e.target;

		const unformattedCIC = cic.replace(/_/g, '');

		setDataFormulario((prev) => ({ ...prev, cic: unformattedCIC }));

		let messages = [];

		messages.push({
			type: unformattedCIC.length < 10 ? 'error' : 'success',
			message: 'El CIC debe tener 10 caracteres.'
		});

		setCicValidation(messages);
	};

	const handleOCRChange = (e) => {
		const { value: ocr } = e.target;

		const unformattedOCR = ocr.replace(/_/g, '');

		setDataFormulario((prev) => ({ ...prev, ocr: unformattedOCR }));

		let messages = [];

		messages.push({
			type: unformattedOCR.length < 13 ? 'error' : 'success',
			message: 'El OCR debe tener 13 caracteres.'
		});

		setOcrValidation(messages);
	};

	const handleValidityChange = (e, field) => {
		const { value: year } = e.target;

		const unformattedYear = year.replace(/-/g, '');
		const emision = field === 'emision' ? unformattedYear : dataFormulario.emision;
		const vigencia = field === 'vigencia' ? unformattedYear : dataFormulario.vigencia;

		setDataFormulario((prev) => ({
			...prev,
			[field]: unformattedYear,
			validity:
				field === 'vigencia' ? `${prev.emision} - ${unformattedYear}` : `${unformattedYear} - ${prev.vigencia}`
		}));

		let emisionMessages = [];
		let vigenciaMessages = [];

		emisionMessages.push({
			type: emision.length < 4 ? 'error' : 'success',
			message: 'El año de Emisión debe tener 4 caracteres.'
		});

		emisionMessages.push({
			type: emision > vigencia ? 'error' : 'success',
			message: 'El año de Emisión no debe ser mayor al año de Vigencia.'
		});

		vigenciaMessages.push({
			type: vigencia.length < 4 ? 'error' : 'success',
			message: 'El año de Vigencia debe tener 4 caracteres.'
		});

		vigenciaMessages.push({
			type: vigencia < emision ? 'error' : 'success',
			message: 'El año de Vigencia no debe ser menor al año de Emisión.'
		});

		setEmisionValidation(emisionMessages);
		setVigenciaValidation(vigenciaMessages);
	};

	const handlePassportPhotoChange = (event) => {
		const image = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onload = () => {
			const img = new Image();

			img.src = reader.result;
			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				canvas.width = img.width > 900 ? 900 : img.width;
				canvas.height = img.width > 900 ? img.height * (900 / img.width) : img.height;

				setImageHeight(img.width > 900 ? img.height * (900 / img.width) : img.height);

				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
				setImageToCrop(canvas.toDataURL());
				setCropperIsOpen(true);
			};
		};
	};

	const handleSaveCroppedImage = (imageUrl) => {
		setDataFormulario((prev) => ({ ...prev, passport_photo: imageUrl }));
	};

	const handleSelectImage = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click(); // Programmatically trigger the file input
		}
	};

	if (loadingCredentials || loadingIne) return <InternalLoader />;

	return (
		<Fragment>
			<Container
				fluid
				style={{
					backgroundColor: 'white',
					padding: '24px',
					borderRadius: '8px'
				}}
			>
				<Row>
					<Col xs="6">
						<h3>{mode === EditManually ? EditManually : 'Crear'} elector</h3>
					</Col>
					<Col xs="6" style={{ textAlign: 'end' }}>
						<span
							style={{
								fontWeight: '500',
								fontSize: '24px',
								lineHeight: '28px',
								color: '#d32f34',
								justifyContent: 'right'
							}}
						>
							{mode === CreateByOCR && `3 de 3`}
						</span>
					</Col>
				</Row>
				<div className="elector-data-container">
					{/* PASSPORT PHOTO */}
					<div className="elector-data-row" style={{ width: '100%', justifyContent: 'center' }}>
						<div
							className="elector-data-column"
							style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
						>
							<input
								id="passport_photo"
								type="file"
								accept="image/png, image/jpeg"
								style={{ display: 'none' }}
								onChange={handlePassportPhotoChange}
								ref={fileInputRef}
							/>
							<label htmlFor={`passport_photo`}>
								{dataFormulario.passport_photo ? (
									<div className="cropped-image-preview">
										<img
											src={dataFormulario.passport_photo}
											alt="Cropped Preview"
											width="80px"
											height="80px"
										/>
										<Button color="primary" onClick={() => setCropperIsOpen(true)}>
											Modificar
										</Button>
									</div>
								) : (
									<Button color="primary" onClick={handleSelectImage}>
										Seleccionar imagen
									</Button>
								)}
							</label>
							{errors.includes('passport_photo') && <RequiredField />}
						</div>
					</div>
					{/* INFORMACION DEL REGISTRO */}
					<div className="elector-title-row">Información del registro</div>
					<div className="elector-data-row">
						<div className="elector-data-column">
							<span className="elector-data-title">
								Tipo <span className="required-label">*</span>
							</span>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								name="affiliateType"
								id="affiliateType"
								options={affiliateTypeOptions}
								onChange={(option) => {
									setDataFormulario((prev) => ({
										...prev,
										typeAffiliate: option ? option.label : ''
									}));

									setSelectedType(option);
								}}
								placeholder="Tipo"
								value={selectedType}
								isClearable
								styles={selectStyles}
							/>
							{errors.includes('tipo') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Grupo especial <span className="required-label">*</span>
							</span>
							<Select
								isDisabled={mode !== CreateManually}
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="specialGroup"
								id="specialGroup"
								options={specialGroupsOptions}
								onChange={(option) => {
									setDataFormulario((prev) => ({
										...prev,
										specialGroups: option !== null ? true : false,
										nameSpecialGroups: option !== null ? option.label : ''
									}));

									setSelectedGroup(option);
								}}
								placeholder="Grupo especial"
								value={selectedGroup}
								styles={selectStyles}
							/>
							{errors.includes('grupoEspecial') && <RequiredField />}
						</div>
					</div>

					{/* DATOS DEL ELECTOR */}
					<div className="elector-title-row">Datos del elector</div>
					<div className="elector-data-row">
						<div className="elector-data-column">
							<span className="elector-data-title">
								Nombre <span className="required-label">*</span>
							</span>
							<Input
								type="text"
								name="nombre"
								id="nombre"
								placeholder="Nombre"
								value={dataFormulario.first_name}
								onChange={(e) =>
									setDataFormulario((prev) => ({
										...prev,
										first_name: e.target.value,
										full_name: `${prev.aPaterno} ${prev.aMaterno} ${e.target.value}`
									}))
								}
							/>
							{errors.includes('nombre') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Apellido paterno <span className="required-label">*</span>
							</span>
							<Input
								type="text"
								name="aPaterno"
								id="aPaterno"
								placeholder="Apellido paterno"
								value={dataFormulario.aPaterno}
								onChange={(e) =>
									setDataFormulario((prev) => ({
										...prev,
										aPaterno: e.target.value,
										full_name: `${e.target.value} ${prev.aMaterno} ${prev.first_name}`,
										last_name: `${e.target.value} ${prev.aMaterno}`
									}))
								}
							/>
							{errors.includes('aPaterno') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Apellido materno <span className="required-label">*</span>
							</span>
							<Input
								type="text"
								name="aMaterno"
								id="aMaterno"
								placeholder="Apellido materno"
								value={dataFormulario.aMaterno}
								onChange={(e) =>
									setDataFormulario((prev) => ({
										...prev,
										aMaterno: e.target.value,
										full_name: `${prev.aPaterno} ${e.target.value} ${prev.first_name}`,
										last_name: `${prev.aPaterno} ${e.target.value}`
									}))
								}
							/>
							{errors.includes('aMaterno') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Sexo <span className="required-label">*</span>
							</span>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								name="sexo"
								id="sexo"
								options={genderOptions}
								onChange={(option) => {
									setDataFormulario((prev) => ({ ...prev, gender: option.value }));

									setSelectedGender(option);
								}}
								placeholder={'Sexo'}
								value={selectedGender}
								styles={selectStyles}
							/>
							{errors.includes('sexo') && <RequiredField />}
						</div>
					</div>
					<div className="elector-data-row">
						<div className="elector-data-column">
							<span className="elector-data-title">
								Estado <span className="required-label">*</span>
							</span>
							<Select
								name="state"
								id="selectedState"
								options={entitiesOptions}
								placeholder={'Seleccionar Estado'}
								styles={selectStyles}
								onChange={(option) => {
									setDataFormulario((prev) => ({
										...prev,
										state: option.label,
										id_state: option.value
									}));
									handleEntityChange(option);
								}}
								value={selectedEntity}
								isLoading={loadingEntities}
								loadingMessage={() => 'Cargando...'}
								isDisabled={
									loadingSections || loadingMunicipalities || loadingFDistrics || loadingLDistrics
								}
							/>
							{errors.includes('estado') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">Distrito federal</span>
							<Select
								name="fDistrict"
								id="selectedFDistrict"
								options={fDistrics}
								placeholder={'-'}
								styles={selectStyles}
								onChange={(option) => {
									setDataFormulario((prev) => ({ ...prev, district: option.value }));
									setSelectedFDistrict(option);
								}}
								value={selectedFDistrict}
								isLoading={loadingFDistrics}
								loadingMessage={() => 'Cargando...'}
								isDisabled={blockSection}
							/>
							{errors.includes('dFederal') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">Distrito local</span>
							<Select
								name="lDistrict"
								id="selectedLDistrict"
								options={lDistrics}
								placeholder={'-'}
								styles={selectStyles}
								onChange={(option) => {
									setDataFormulario((prev) => ({
										...prev,
										local_district: option.value,
										local_district_id: option.id
									}));
									setSelectedLDistrict(option);
								}}
								value={selectedLDistrict}
								isLoading={loadingLDistrics}
								loadingMessage={() => 'Cargando...'}
								isDisabled={blockSection}
							/>
							{errors.includes('dLocal') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Municipio <span className="required-label">*</span>
							</span>
							<Select
								name="municiplaity"
								id="selectedMunicipality"
								options={municipalities}
								placeholder={'Seleccionar municipio'}
								styles={selectStyles}
								onChange={(option) => {
									setDataFormulario((prev) => ({ ...prev, municipality: option.value }));
									setSelectedMunicipality(option);
								}}
								value={selectedMunicipality}
								isLoading={loadingMunicipalities}
								loadingMessage={() => 'Cargando...'}
							/>
							{errors.includes('municipio') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Sección <span className="required-label">*</span>
							</span>
							<Select
								name="section"
								id="selectedSection"
								options={sectionsOptions}
								placeholder={'Seleccionar sección'}
								styles={selectStyles}
								onChange={(option) => {
									setDataFormulario((prev) => ({
										...prev,
										section: option.value,
										municipality: option.municipality.value,
										local_district: option.lDistrict.value,
										local_district_id: lDistrics.filter((d) => d.value === option.lDistrict.value)
											?.id,
										district: option.district.value
									}));

									setSelectedMunicipality(option.municipality);
									setSelectedLDistrict(option.lDistrict);
									setSelectedFDistrict(option.district);
									setSelectedSection(option);
								}}
								value={selectedSection}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
								isDisabled={blockSection}
							/>
							{errors.includes('seccion') && <RequiredField />}
						</div>
					</div>
					<div className="elector-data-row">
						<div className="elector-data-column">
							<span className="elector-data-title">
								CURP <span className="required-label">*</span>
							</span>
							<MaskedInput
								name="curp"
								id="curp"
								mask="AAAA-111111-A-AA-AAA-11"
								placeholder="CURP: AAAA-123456-A-AA-AAA-12"
								value={dataFormulario.curp}
								onChange={handleCurpChange}
								className="form-control"
								disabled={mode === EditManually}
							/>
							{errors.includes('curp-requerido') && <RequiredField />}
							{errors.includes('curp-invalido') && <InvalidField />}
							{curpValidation.length > 0 && (
								<Tooltip
									anchorSelect="#curp"
									style={{
										backgroundColor: '#F8F8F8',
										boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
										borderRadius: '12px',
										color: '#000',
										padding: '8px'
									}}
								>
									El CURP debe cumplir las siguientes condiciones:
									<ul>
										{curpValidation.map(({ type, message }, index) => {
											return (
												<li style={{ marginTop: '12px' }} key={index}>
													{type === 'error' ? (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																color: '#dc3545',
																gap: '12px'
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	borderRadius: '50%',
																	width: '16px',
																	height: '16px',
																	backgroundColor: '#dc3545'
																}}
															>
																<X size={12} color="#FFF" />
															</div>
															<span>{message}</span>
														</div>
													) : (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																color: '#198754',
																gap: '12px'
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	borderRadius: '50%',
																	width: '16px',
																	height: '16px',
																	backgroundColor: '#198754'
																}}
															>
																<Check size={12} color="#FFF" />
															</div>
															<span>{message}</span>
														</div>
													)}
												</li>
											);
										})}
									</ul>
								</Tooltip>
							)}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Clave de elector <span className="required-label">*</span>
							</span>
							<MaskedInput
								name="electoral_key"
								id="electoral_key"
								placeholder="Clave del elector: AAAAAA-111111-11-A-111"
								mask="AAAAAA-111111-11-A-111"
								value={dataFormulario.electoral_key}
								onChange={handleElectoralKeyChange}
								className="form-control"
							/>
							{errors.includes('claveElector-requerido') && <RequiredField />}
							{errors.includes('claveElector-invalido') && <InvalidField />}
							{electoralKeyValidation.length > 0 && (
								<Tooltip
									anchorSelect="#electoral_key"
									style={{
										backgroundColor: '#F8F8F8',
										boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
										borderRadius: '12px',
										color: '#000',
										padding: '8px'
									}}
								>
									La clave de elector debe cumplir las siguientes condiciones:
									<ul>
										{electoralKeyValidation.map(({ type, message }, index) => {
											return (
												<li style={{ marginTop: '12px' }} key={index}>
													{type === 'error' ? (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																color: '#dc3545',
																gap: '12px'
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	borderRadius: '50%',
																	width: '16px',
																	height: '16px',
																	backgroundColor: '#dc3545'
																}}
															>
																<X size={12} color="#FFF" />
															</div>
															<span>{message}</span>
														</div>
													) : (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																color: '#198754',
																gap: '12px'
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	borderRadius: '50%',
																	width: '16px',
																	height: '16px',
																	backgroundColor: '#198754'
																}}
															>
																<Check size={12} color="#FFF" />
															</div>
															<span>{message}</span>
														</div>
													)}
												</li>
											);
										})}
									</ul>
								</Tooltip>
							)}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								CIC {mode === CreateByOCR && <span className="required-label">*</span>}
							</span>
							<MaskedInput
								name="cic"
								id="cic"
								placeholder="CIC"
								mask="1111111111"
								value={dataFormulario.cic}
								onChange={handleCICChange}
								className="form-control"
								disabled={mode === EditManually}
							/>
							{errors.includes('cic-requerido') && <RequiredField />}
							{errors.includes('cic-invalido') && <InvalidField />}
							{cicValidation.length > 0 && (
								<Tooltip
									anchorSelect="#cic"
									style={{
										backgroundColor: '#F8F8F8',
										boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
										borderRadius: '12px',
										color: '#000',
										padding: '8px'
									}}
								>
									{cicValidation.map(({ type, message }, index) => (
										<div
											key={index}
											style={{
												display: 'flex',
												flexDirection: 'row',
												color: type === 'error' ? '#dc3545' : '#198754',
												gap: '12px'
											}}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: '50%',
													width: '16px',
													height: '16px',
													backgroundColor: type === 'error' ? '#dc3545' : '#198754'
												}}
											>
												{type === 'error' ? (
													<X size={12} color="#FFF" />
												) : (
													<Check size={12} color="#FFF" />
												)}
											</div>
											<span>{message}</span>
										</div>
									))}
								</Tooltip>
							)}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								OCR {mode === CreateByOCR && <span className="required-label">*</span>}
							</span>
							<MaskedInput
								name="ocr"
								id="ocr"
								placeholder="OCR"
								mask="1111111111111"
								value={dataFormulario.ocr}
								onChange={handleOCRChange}
								className="form-control"
							/>
							{errors.includes('ocr-requerido') && <RequiredField />}
							{errors.includes('ocr-invalido') && <InvalidField />}
							{ocrValidation.length > 0 && (
								<Tooltip
									anchorSelect="#ocr"
									style={{
										backgroundColor: '#F8F8F8',
										boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
										borderRadius: '12px',
										color: '#000',
										padding: '8px'
									}}
								>
									{ocrValidation.map(({ type, message }, index) => (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												color: type === 'error' ? '#dc3545' : '#198754',
												gap: '12px'
											}}
											key={index}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: '50%',
													width: '16px',
													height: '16px',
													backgroundColor: type === 'error' ? '#dc3545' : '#198754'
												}}
											>
												{type === 'error' ? (
													<X size={12} color="#FFF" />
												) : (
													<Check size={12} color="#FFF" />
												)}
											</div>
											<span>{message}</span>
										</div>
									))}
								</Tooltip>
							)}
						</div>
					</div>
					<div className="elector-data-row">
						<div className="elector-data-column">
							<span className="elector-data-title">
								Domicilio <span className="required-label">*</span>
							</span>
							<Input
								type="text"
								name="domicilio"
								id="domicilio"
								placeholder="Domicilio"
								value={dataFormulario.address}
								onChange={(e) => setDataFormulario((prev) => ({ ...prev, address: e.target.value }))}
							/>
							{errors.includes('domicilio') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Fecha de nacimiento <span className="required-label">*</span>
							</span>
							<DatePicker
								clearIcon={false}
								value={selectedDate}
								onChange={(value) => {
									setDataFormulario((prev) => ({
										...prev,
										date_birth: moment(value, 'DD/MM/YYYY')
									}));

									setSelectedDate(moment(value).toDate());
								}}
								format="dd/MM/yyyy"
								minDate={moment().subtract(100, 'years').startOf('year').toDate()}
								maxDate={moment().subtract(15, 'years').toDate()}
								onKeyDown={(e) => {
									e.preventDefault();
								}}
								calendarIcon={<Calendar size={16} stroke="#d32f34" />}
							/>
							{errors.includes('date_birth') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Emisión {mode === CreateByOCR && <span className="required-label">*</span>}
							</span>
							<MaskedInput
								name="emision"
								id="emision"
								placeholder="Emisión"
								mask="1111"
								value={dataFormulario.emision}
								maxLength={4}
								onChange={(e) => handleValidityChange(e, 'emision')}
								className="form-control"
							/>
							{errors.includes('emision-requerido') && <RequiredField />}
							{errors.includes('emision-invalido') && <InvalidField />}
							{emisionValidation.length > 0 && (
								<Tooltip
									anchorSelect="#emision"
									style={{
										backgroundColor: '#F8F8F8',
										boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
										borderRadius: '12px',
										color: '#000',
										padding: '8px'
									}}
								>
									{emisionValidation.map(({ type, message }, index) => (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												color: type === 'error' ? '#dc3545' : '#198754',
												gap: '12px'
											}}
											key={index}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: '50%',
													width: '16px',
													height: '16px',
													backgroundColor: type === 'error' ? '#dc3545' : '#198754'
												}}
											>
												{type === 'error' ? (
													<X size={12} color="#FFF" />
												) : (
													<Check size={12} color="#FFF" />
												)}
											</div>
											<span>{message}</span>
										</div>
									))}
								</Tooltip>
							)}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Vigencia {mode === CreateByOCR && <span className="required-label">*</span>}
							</span>
							<MaskedInput
								name="vigencia"
								id="vigencia"
								placeholder="Vigencia"
								mask="1111"
								value={dataFormulario.vigencia}
								maxLength={4}
								onChange={(e) => handleValidityChange(e, 'vigencia')}
								className="form-control"
							/>
							{errors.includes('vigencia-requerido') && <RequiredField />}
							{vigenciaValidation.length > 0 && (
								<Tooltip
									anchorSelect="#vigencia"
									style={{
										backgroundColor: '#F8F8F8',
										boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
										borderRadius: '12px',
										color: '#000',
										padding: '8px'
									}}
								>
									{vigenciaValidation.map(({ type, message }, index) => (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												color: type === 'error' ? '#dc3545' : '#198754',
												gap: '12px'
											}}
											key={index}
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: '50%',
													width: '16px',
													height: '16px',
													backgroundColor: type === 'error' ? '#dc3545' : '#198754'
												}}
											>
												{type === 'error' ? (
													<X size={12} color="#FFF" />
												) : (
													<Check size={12} color="#FFF" />
												)}
											</div>
											<span>{message}</span>
										</div>
									))}
								</Tooltip>
							)}
						</div>
					</div>

					{/* DATOS ADICIONALES */}
					<div className="elector-title-row">Datos adicionales</div>
					<div className="elector-data-row">
						<div className="elector-data-column localizacion">
							<span className="elector-data-title">
								Latitud <span className="required-label">*</span>
							</span>
							<Input
								type="text"
								name="latitud"
								id="latitud"
								placeholder="Latitud"
								value={localizationData.lat}
								onChange={(e) => setLocalizationData((prev) => ({ ...prev, lat: e.target.value }))}
							/>
							{errors.includes('latitud') && <RequiredField />}
						</div>
						<div className="elector-data-column localizacion">
							<span className="elector-data-title">
								Longitud <span className="required-label">*</span>
							</span>
							<Input
								type="text"
								name="longitud"
								id="longitud"
								placeholder="Longitud"
								value={localizationData.lng}
								onChange={(e) => setLocalizationData((prev) => ({ ...prev, lng: e.target.value }))}
							/>
							{errors.includes('longitud') && <RequiredField />}
						</div>
						<div className="elector-data-column-button">
							<></>
							<Button color="primary" id="get-localization-tooltip" onClick={getLocation}>
								<MapPin color="#FFF" size={16} />
							</Button>
							<UncontrolledTooltip placement="top" target="get-localization-tooltip">
								Obtener localizacion
							</UncontrolledTooltip>
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">
								Número de teléfono <span className="required-label">*</span>
							</span>
							<PhoneInput
								country={'mx'}
								inputStyle={{
									width: '100%',
									fontSize: '12px',
									borderRadius: '0.25rem',
									border: '1px solid #ccc',
									backgroundColor: '#fff'
								}}
								dropdownStyle={{
									borderRadius: '5px',
									boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
									backgroundColor: '#fff'
								}}
								value={telefono}
								onChange={(e) => setTelefono(e)}
							/>
							{errors.includes('telefono') && <RequiredField />}
						</div>
					</div>
					<div className="elector-data-row">
						<div className="elector-data-column">
							<span className="elector-data-title">Instagram</span>
							<InputGroup>
								<InputGroupText className="social-logo">
									<SocialIcon
										network="instagram"
										bgColor="#FFFFFF"
										fgColor="#000000"
										style={{ height: '28px', width: '28px', padding: '0px' }}
									/>
								</InputGroupText>
								<Input
									type="text"
									name="instagram"
									id="instagram"
									placeholder="Instagram"
									className="social-input"
									value={sociales.instagram}
									onChange={(e) =>
										setSociales((prev) => ({
											...prev,
											instagram: e.target.value
										}))
									}
								/>
							</InputGroup>
							{errors.includes('instagram') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">X</span>
							<InputGroup>
								<InputGroupText className="social-logo">
									<SocialIcon
										network="x"
										bgColor="#FFFFFF"
										fgColor="#000000"
										style={{ height: '28px', width: '28px', padding: '0px' }}
									/>
								</InputGroupText>
								<Input
									type="text"
									name="twitter"
									id="twitter"
									placeholder="X"
									className="social-input"
									value={sociales.twitter}
									onChange={(e) => setSociales((prev) => ({ ...prev, twitter: e.target.value }))}
								/>
							</InputGroup>
							{errors.includes('twitter') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">Facebook</span>
							<InputGroup>
								<InputGroupText className="social-logo">
									<SocialIcon
										network="facebook"
										bgColor="#FFFFFF"
										fgColor="#000000"
										style={{ height: '28px', width: '28px', padding: '0px' }}
									/>
								</InputGroupText>
								<Input
									type="text"
									name="facebook"
									id="facebook"
									placeholder="Facebook"
									className="social-input"
									value={sociales.facebook}
									onChange={(e) => setSociales((prev) => ({ ...prev, facebook: e.target.value }))}
								/>
							</InputGroup>
							{errors.includes('facebook') && <RequiredField />}
						</div>
						<div className="elector-data-column">
							<span className="elector-data-title">TikTok</span>
							<InputGroup>
								<InputGroupText className="social-logo">
									<SocialIcon
										network="tiktok"
										bgColor="#FFFFFF"
										fgColor="#000000"
										style={{ height: '28px', width: '28px', padding: '0px' }}
									/>
								</InputGroupText>
								<Input
									type="text"
									name="tiktok"
									id="tiktok"
									placeholder="Tiktok"
									className="social-input"
									value={sociales.tiktok}
									onChange={(e) =>
										setSociales((prev) => ({
											...prev,
											tiktok: e.target.value
										}))
									}
								/>
							</InputGroup>
							{errors.includes('tiktok') && <RequiredField />}
						</div>
					</div>

					{/* FOOTER */}
					<div className="elector-footer">
						<Button
							outline
							color="light-2x txt-dark"
							onClick={() =>
								mode === EditManually ? navigate(`/electores/${curp}`) : navigate(ELECTORES)
							}
						>
							Cancelar
						</Button>
						<Button
							color="primary"
							disabled={
								loadingEntities ||
								loadingSections ||
								loadingLDistrics ||
								loadingFDistrics ||
								loadingMunicipalities
							}
							onClick={handleSaveVoter}
						>
							{/* Continuar y crear */}
							{mode === EditManually ? 'Continuar y actualizar' : 'Continuar y crear'}
						</Button>
					</div>
				</div>
			</Container>
			<ToastContainer />
			<LoadingAffiliation isOpen={loadingCreate} />
			<CustomCropper
				isOpen={cropperIsOpen}
				onClose={() => setCropperIsOpen(false)}
				src={imageToCrop}
				onSave={handleSaveCroppedImage}
				imageHeight={imageHeight}
				orientation="portrait"
			/>
		</Fragment>
	);
};

export default CrearElector;
