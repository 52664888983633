import React from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { X } from 'react-feather';

import DatePicker from 'react-date-picker';
import { Calendar } from 'react-feather';

const orderByOptions = [
	{ label: 'Nombre A-Z', value: 'aToZ' },
	{ label: 'Nombre Z-A', value: 'zToA' },
	{ label: 'Más Antiguo', value: 'older' },
	{ label: 'Más Reciente', value: 'newer' }
];

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const FilterEventModal = ({
	isOpen,
	toggle,
	selectedOrder,
	setSelectedOrder,
	selectedDate,
	setSelectedDate,
	selectedState,
	setSelectedState,
	selectedStatus,
	setSelectedStatus,
	statesOptions,
	statusOptions,
	handleFilterEvent
}) => {
	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalBody className="events-filter-modal">
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Ordenar por'}</Label>
							<Select
								isClearable
								name="selectedOrder"
								id="selectedOrder"
								options={orderByOptions}
								onChange={(option) => {
									setSelectedOrder(option);
								}}
								placeholder={orderByOptions[0].label}
								value={selectedOrder}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Fecha de registro'}</Label>
							<DatePicker
								value={selectedDate}
								onChange={(value) => {
									setSelectedDate(value);
								}}
								clearIcon={false}
								format="dd/MM/yyyy"
								calendarIcon={<Calendar size={16} stroke="#d32f34" />}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Estado'}</Label>
							<Select
								isClearable
								name="selectedState"
								id="selectedState"
								options={statesOptions}
								onChange={(option) => {
									setSelectedState(option);
								}}
								placeholder={'Todos los estados'}
								value={selectedState || ''}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Status'}</Label>
							<Select
								isClearable
								isMulti
								name="selectedState"
								id="selectedState"
								options={statusOptions}
								onChange={(e) => setSelectedStatus(e)}
								placeholder={'Todos los status'}
								value={selectedStatus || ''}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={() => {
						setSelectedDate();
						setSelectedOrder(null);
						setSelectedState();
						setSelectedStatus([]);
					}}
				>
					Restablecer
				</Button>{' '}
				<Button
					color="primary"
					onClick={() => {
						handleFilterEvent();
						toggle();
					}}
				>
					Aplicar Filtro
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default FilterEventModal;
