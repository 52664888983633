import React, { useEffect, useState } from 'react';
import {
	Col,
	Container,
	FormGroup,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	TabPane,
	UncontrolledTooltip
} from 'reactstrap';

import Select from 'react-select';

import './details.scss';
import Tab from 'components/tab/Tab.';
import { useTab } from 'components/tab/useTab';

import '../../../tab/tab.scss';
import InternalLoader from 'layout/internal-loader';
import { axiosInstance } from 'utils/axiosInstance';
import TabContentContainer from 'components/tab/TabContent';

import DataTable from 'react-data-table-component';
import { AlertCircle, Download } from 'react-feather';
import moment from 'moment';
import 'moment/locale/es';

import * as XLSX from 'xlsx';

moment.updateLocale('es', {
	months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_')
});

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const tableStyles = {
	headCells: {
		style: {
			color: '#d32f34',
			fontSize: '16px',
			fontWeight: 500
		}
	}
};

const StageInfo = ({ stageId, poll, isLoading, setIsLoading }) => {
	const [data, setData] = useState(null);

	const [tableColumns, setTableColumns] = useState([]);

	useEffect(() => {
		setIsLoading(true);

		axiosInstance({ url: `/polls/question/?poll=${poll}&stage=${stageId}`, method: 'GET' })
			.then(({ data }) => {
				if (data.results.length > 0) {
					const questionType = data.results[0].type_question_info.id;

					let columns = [];

					switch (questionType) {
						case 2:
							const maxTotalOptions = Math.max(
								...data.results.map((question) => question.options.length)
							);

							const multipleArrayOptions = Array.from({ length: maxTotalOptions }, (_, i) => ({
								name: `Opción ${i + 1}`,
								cell: (row) => (
									<span
										style={{
											fontSize: '12px',
											fontWeight: 500
										}}
									>
										{row.options[i] ? (
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'center',
													alignItems: 'center',
													gap: '8px'
												}}
											>
												<span>{row.options[i].number_times_selected}</span>
												<AlertCircle
													color="#AFAFAF"
													id={`option-${row.id}-${row.options[i].id}`}
													size={18}
												/>
												<UncontrolledTooltip
													placement="top"
													target={`option-${row.id}-${row.options[i].id}`}
												>
													{row.options[i].option}
												</UncontrolledTooltip>
											</div>
										) : (
											'-'
										)}
									</span>
								),
								width: '15%',
								center: true
							}));

							columns = [
								{
									name: 'Preguntas múltiples',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.question}
										</span>
									),
									minWidth: '20%'
								},
								...multipleArrayOptions,
								{
									name: 'Total',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.options.reduce((acc, option) => {
												return (acc += option.number_times_selected);
											}, 0)}
										</span>
									),
									width: '10%',
									center: true
								}
							];
							break;
						case 1:
							columns = [
								{
									name: 'Preguntas simples',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.question}
										</span>
									),
									width: '50%'
								},
								{
									name: 'Verdadero',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.options.map((option) => {
												if (option.option === 'Sí') {
													return (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'center',
																alignItems: 'center',
																gap: '8px'
															}}
														>
															<span>{option?.number_times_selected || '-'}</span>
														</div>
													);
												}

												return false;
											})}
										</span>
									),
									width: '20%',
									center: true
								},
								{
									name: 'Falso',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.options.map((option) => {
												if (option.option === 'No') {
													return (
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'center',
																alignItems: 'center',
																gap: '8px'
															}}
														>
															<span>{option?.number_times_selected || '-'}</span>
														</div>
													);
												}

												return false;
											})}
										</span>
									),
									width: '20%',
									center: true
								},
								{
									name: 'Total',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.options.reduce((acc, option) => {
												return (acc += option.number_times_selected);
											}, 0)}
										</span>
									),
									width: '10%',
									center: true
								}
							];
							break;
						case 4:
							columns = [
								{
									name: 'Preguntas por valoración',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.question}
										</span>
									),
									width: '90%'
								},
								{
									name: 'Promedio',
									cell: (row) => (
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												gap: '4px',
												height: '100%',
												width: '100%',
												justifyContent: 'right',
												alignItems: 'center'
											}}
										>
											<div
												style={{
													display: 'flex',
													padding: '4px 8px',
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: '4px',
													border: '#F8F8F8',
													backgroundColor: '#F8F8F8'
												}}
											>
												4.8
											</div>
											<span className="star" style={{ fontSize: '24px', color: '#00C89F' }}>
												&#9733;
											</span>
										</div>
									)
								}
							];
							break;
						case 5:
							columns = [
								{
									name: 'Preguntas libres',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.question}
										</span>
									)
								},
								{
									name: 'Respuestas',
									cell: (row) => {
										return (
											<span
												style={{
													fontSize: '12px',
													fontWeight: 500
												}}
											>
												{row?.total_open_response}
											</span>
										);
									},
									width: '20%',
									center: true
								},
								{
									name: 'Descargar',
									cell: (row) => (
										<button className="px-2 download-button">
											Descargar <Download size={16} />
										</button>
									),
									width: '15%',
									center: true
								}
							];
							break;
						case 6:
							const maxTotalOptionsRelacionable = Math.max(
								...data.results.map((question) => question.options.length)
							);

							const multipleArrayOptionsRelacionable = Array.from(
								{ length: maxTotalOptionsRelacionable },
								(_, i) => ({
									name: `Opción ${i + 1}`,
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.options[i] ? (
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'center',
														alignItems: 'center',
														gap: '8px'
													}}
												>
													<span>{row.options[i].number_times_selected}</span>
													<AlertCircle
														color="#AFAFAF"
														id={`option-${row.id}-${row.options[i].id}`}
														size={18}
													/>
													<UncontrolledTooltip
														placement="top"
														target={`option-${row.id}-${row.options[i].id}`}
													>
														{row.options[i].option}
													</UncontrolledTooltip>
												</div>
											) : (
												'-'
											)}
										</span>
									),
									width: '15%',
									center: true
								})
							);

							columns = [
								{
									name: 'Preguntas Relacionables',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.question}
										</span>
									),
									minWidth: '20%'
								},
								...multipleArrayOptionsRelacionable,
								{
									name: 'Total',
									cell: (row) => (
										<span
											style={{
												fontSize: '12px',
												fontWeight: 500
											}}
										>
											{row.options.reduce((acc, option) => {
												return (acc += option.number_times_selected);
											}, 0)}
										</span>
									),
									width: '10%',
									center: true
								}
							];

							break;
						default:
							setTableColumns([]);
							break;
					}

					setTableColumns(columns);

					setData(data.results);
				}
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	}, [poll, stageId, setIsLoading]);

	if (isLoading) return <InternalLoader />;

	return (
		<>
			{data?.length > 0 ? (
				<>
					<div style={{ display: 'flex', width: '100%', height: '100%' }}>
						<DataTable
							noHeader
							columns={tableColumns}
							customStyles={tableStyles}
							data={data}
							highlightOnHover
						/>
					</div>
				</>
			) : (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<span
						style={{
							color: '#d32f34',
							fontSize: '16px',
							fontWeight: 400
						}}
					>
						No hay preguntas para esta etapa
					</span>
				</div>
			)}
		</>
	);
};

const CategoryInfo = ({ category, isLoading, setIsLoading }) => {
	/**
	 * State for subcategories
	 */
	const [subcategories, setSubcategories] = useState([]);

	const [selectedRow, setSelectedRow] = useState(null);

	const [tableColumns, setTableColumns] = useState([]);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/category/?parent=${category.id}`,
			method: 'GET'
		})
			.then(({ data }) => {
				setSubcategories(data.results);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	}, [category.id, setIsLoading]);

	useEffect(() => {
		if (selectedRow) {
			if (selectedRow === category.id) {
				const { qty_answers, total_value } = category;
				setTableData([{ participants: qty_answers, values: total_value }]);

				setTableColumns([
					{
						name: <span className="category-subcategory-info-title">Participaciones</span>,
						cell: (row) => <span className="category-subcategory-info-cell">{row.participants}</span>,
						width: '50%'
					},
					{
						name: <span className="category-subcategory-info-title">Valores</span>,
						cell: (row) => <span className="category-subcategory-info-cell">{row.values}</span>,
						width: '50%'
					}
				]);
			} else {
				const selectedSubcategory = subcategories.find((obj) => obj.id === selectedRow);

				setTableData(
					selectedSubcategory.info_stages.map((stage) => ({
						stage: stage.stage,
						participants: stage.qty_total,
						values: stage.total_value
					}))
				);

				setTableColumns([
					{
						name: <span className="category-subcategory-info-title">Etapas</span>,
						cell: (row) => <span className="category-subcategory-info-cell">{row.stage}</span>,
						width: '45%'
					},
					{
						name: <span className="category-subcategory-info-title">Participaciones</span>,
						cell: (row) => <span className="category-subcategory-info-cell">{row.participants}</span>,
						width: '30%'
					},
					{
						name: <span className="category-subcategory-info-title">Valores</span>,
						cell: (row) => <span className="category-subcategory-info-cell">{row.values}</span>,
						width: '25%'
					}
				]);
			}
		}
	}, [selectedRow, category, subcategories]);

	if (isLoading) return <InternalLoader />;

	return (
		<div style={{ display: 'flex', width: '100%', height: '100%' }}>
			<Col lg={4} md={4} sm={6}>
				<div className="survey-detail-card">
					<div className={`survey-category-card ${selectedRow === category.id ? 'selected' : ''}`}>
						<span style={{ color: '#d32f34' }}>{category.name}</span>
						<i
							className="fa fa-eye"
							style={{ cursor: 'pointer' }}
							onClick={() => setSelectedRow(category.id)}
						></i>
					</div>
					{subcategories.map((subcategory) => (
						<div className={`survey-subcategory-card ${selectedRow === subcategory.id ? 'selected' : ''}`}>
							<div className="subcategory-info">
								<span className="subcategory-span">Subcategoría</span>
								<span className="subcategory-name">{subcategory.name}</span>
							</div>
							<i
								className="fa fa-eye"
								style={{ cursor: 'pointer' }}
								onClick={() => setSelectedRow(subcategory.id)}
							></i>
						</div>
					))}
				</div>
			</Col>
			<Col>
				{!selectedRow && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<span
							style={{
								color: '#d32f34',
								fontSize: '16px',
								fontWeight: 400
							}}
						>
							Sin categoría/subcategoría seleccionada
						</span>
						<span
							style={{
								fontSize: '14px',
								fontWeight: 400,
								color: '#AFAFAF'
							}}
						>
							Selecciona una para clasificar los resultadosx
						</span>
					</div>
				)}
				{selectedRow && (
					<div
						className="category-subcategory-info"
						style={{ display: 'flex', width: '100%', height: '100%' }}
					>
						<DataTable
							columns={tableColumns}
							customStyles={tableStyles}
							data={tableData}
							highlightOnHover
							noDataComponent={'No se ha encontrado información'}
						/>
					</div>
				)}
			</Col>
		</div>
	);
};

const downloadDetails = async (surveyStages, poll) => {
	try {
		const stages = await Promise.all(
			surveyStages.map(async (stage) => {
				const { data } = await axiosInstance({
					url: `/polls/question/?poll=${poll.id}&stage=${stage.id}`,
					method: 'GET'
				});

				return { ...stage, questions: data.results };
			})
		);

		const file = XLSX.utils.book_new();

		stages.forEach((stage) => {
			let typeQuestion = '';

			const questions = stage.questions.map((question) => {
				typeQuestion = question.type_question_info.type_question;

				const options = question.options.reduce((acc, curr) => {
					acc[curr.option] = curr.number_times_selected;
					return acc;
				}, {});

				const total =
					question.total_open_response ||
					question.options.reduce((acc, curr) => {
						return acc + curr.number_times_selected;
					}, 0);

				return {
					Pregunta: question.question,
					...options,
					Total: total
				};
			});

			const page = XLSX.utils.json_to_sheet(questions);

			XLSX.utils.book_append_sheet(file, page, `${stage.name} - ${typeQuestion}`);
		});

		XLSX.writeFile(file, `${poll.name}.xlsx`);
	} catch (err) {
		console.log(err);
	}
};

const SurveyDetails = ({ isOpen, toggle, surveyDetails }) => {
	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Selected view state
	 */
	const [selectedView, setSelectedView] = useState(null);

	/**
	 * Stage tabs handlers
	 */
	const { activeTab: activeStagetab, handleChangeTab: handleChangeStageTab } = useTab([], {
		replaceUrl: false
	});

	/**
	 * Category tabs handlers
	 */
	const { activeTab: activeCategoryTab, handleChangeTab: handleChangeCategoryTab } = useTab([], {
		replaceUrl: false
	});

	const [surveyCategories, setSurveyCategories] = useState([]);
	const [surveyStages, setSurveyStages] = useState([]);

	useEffect(() => {
		if (surveyDetails) {
			setSurveyCategories(surveyDetails?.catergory_subcategory);
			setSurveyStages(surveyDetails?.stage_info);
		}
	}, [surveyDetails]);

	return (
		<Modal isOpen={isOpen} toggle={toggle} style={{ minWidth: '1000px' }}>
			<ModalHeader
				toggle={() => {
					setSelectedView(null);
					toggle();
				}}
			></ModalHeader>
			<ModalBody>
				<Row>
					<Col lg="6" sm="12">
						<div
							style={{
								display: 'flex',
								flexDirection: 'row'
							}}
						>
							<div style={{ fontSize: '16px', fontWeight: '500' }}>{surveyDetails?.name || '-'} </div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row'
							}}
						>
							<div style={{ fontSize: '16px', fontWeight: '400' }}>{surveyDetails?.num_poll || '-'} </div>
						</div>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Seleccionar vista de consulta'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								name="selectedView"
								id="selectedView"
								options={[
									{ label: 'Por categorías', value: 'byCategory' },
									{ label: 'Por etapas', value: 'byStage' }
								]}
								onChange={(e) => setSelectedView(e)}
								placeholder={'Seleccionar vista'}
								value={selectedView}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Container style={{ height: '520px', overflow: 'auto' }}>
						{!selectedView && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									height: '100%',
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<span
									style={{
										color: '#d32f34',
										fontSize: '16px',
										fontWeight: 400
									}}
								>
									Sin vista seleccionada
								</span>
								<span
									style={{
										fontSize: '14px',
										fontWeight: 400,
										color: '#AFAFAF'
									}}
								>
									Seleccione una vista para clasificar los resultados
								</span>
							</div>
						)}
						{selectedView?.value === 'byCategory' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
									height: '85%'
								}}
							>
								<Col className="p-0" style={{ width: '100%', height: '100%' }}>
									<Tab
										activeTab={activeCategoryTab}
										setActiveTab={handleChangeCategoryTab}
										tabs={surveyCategories.map((cat) => cat.name)}
										small
									/>
									<TabContentContainer activeTab={activeCategoryTab} fullHeight={true}>
										{surveyCategories.map((category) => (
											<TabPane tabId={category.name} style={{ height: '100%' }}>
												<CategoryInfo
													category={category}
													isLoading={isLoading}
													setIsLoading={setIsLoading}
												/>
											</TabPane>
										))}
									</TabContentContainer>
								</Col>
							</div>
						)}
						{selectedView?.value === 'byStage' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
									height: '85%'
								}}
							>
								<Col className="p-0" style={{ width: '100%', height: '100%' }}>
									<Tab
										activeTab={activeStagetab}
										setActiveTab={handleChangeStageTab}
										tabs={surveyStages.map((stage) => stage.name)}
										small
									/>
									<TabContentContainer activeTab={activeStagetab}>
										{surveyStages.map((stage) => (
											<TabPane tabId={stage.name}>
												<StageInfo
													poll={surveyDetails.id}
													stageId={stage.id}
													isLoading={isLoading}
													setIsLoading={setIsLoading}
												/>
											</TabPane>
										))}
									</TabContentContainer>
								</Col>
							</div>
						)}
					</Container>
				</Row>
				<Row style={{ height: '40px', marginTop: '16px' }}>
					<Col lg="6" sm="12">
						<div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
							<div className="answered-number-badge">
								{surveyDetails?.info_total_participated?.total_participated || 0}
							</div>{' '}
							<span style={{ color: '#AFAFAF', fontSize: '14px', fontWeight: 400 }}>
								veces respondida
							</span>
						</div>
					</Col>
					<Col lg="6" sm="12">
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								alignItems: 'center',
								gap: '12px'
							}}
						>
							<div>
								<div
									style={{
										height: '50%',
										color: '#d32f34',
										alignItems: 'center',
										textAlign: 'end'
									}}
								>
									{moment(surveyDetails?.create_at).format('DD MMMM YYYY - hh:mm A') || '-'}
								</div>
								<div
									style={{
										height: '50%',
										alignItems: 'center',
										textAlign: 'end',
										fontSize: '14px',
										fontWeight: 400,
										color: '#AFAFAF'
									}}
								>
									Fecha de creación
								</div>
							</div>
							<button
								className={`px-2 download-button ${isLoading && 'disabled'}`}
								onClick={() => downloadDetails(surveyStages, surveyDetails)}
								disabled={isLoading}
							>
								Descargar <Download size={16} />
							</button>
						</div>
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export default SurveyDetails;
