import React, { useEffect, useState, useReducer } from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Card,
	Button,
	Alert,
	CardBody,
	CardHeader
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../layout/breadcrumb';
import { Password, EmailAddress, ConfirmPassword, CreateUser, SelectRole } from '../../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { ROLE_OPTIONS } from '../../../constant/variables';
import { createUser, fetchCleanCreateUserState } from '../../../redux/actions';
import { validateInputEmail, validateInputFilled } from '../../../services/dataValidationService';
import userImage from '../../../assets/images/avtar/3.jpg';
import { ACCOUNTS_LIST } from '../../../routes';
import Select from 'react-select';
import { ROLE_VALUES } from '../../../constant/roles-object';

const CreateAccount = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [togglePassword, setTogglePassword] = useState(false);

	const [userForm, setUserForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		email: '',
		name: '',
		password: '',
		confirmPassword: '',
		roles: [ROLE_OPTIONS[0]],
		picture: ''
	});

	const [errorForm, setErrorForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		emailError: false,
		nameError: false,
		usernameError: false,
		passwordError: false,
		confirmPasswordError: false
	});
	const createUserError = useSelector((state) => state.User.createUserError);
	const createUserSuccess = useSelector((state) => state.User.createUserSucceded);

	useEffect(() => {
		dispatch(fetchCleanCreateUserState());
	}, [dispatch]);

	useEffect(() => {
		if (createUserSuccess) {
			dispatch(fetchCleanCreateUserState());
			navigate(ACCOUNTS_LIST, { refresh: true });
		}
	}, [createUserSuccess, navigate, dispatch]);

	const selectPicture = (event) => {
		const fileReader = new FileReader();
		if (event.target.files.length > 0) {
			const photo = event.target.files[0];
			const file = photo;

			fileReader.onload = () => {
				const img = new Image();
				const dataURL = fileReader.result;
				img.src = dataURL;

				img.onload = () => {
					setUserForm({
						picture: fileReader.result
					});
				};
			};
			fileReader.readAsDataURL(file);
		}
	};

	const doCreateUser = () => {
		const formKeys = Object.keys(userForm).filter((key) => key !== 'picture' && key !== 'roles');
		if (userForm.password === userForm.confirmPassword) {
			const inputFilled = inputValidator(formKeys);

			if (inputFilled.length === formKeys.length) {
				dispatch(
					createUser({
						input: {
							name: userForm.name,
							username: userForm.email,
							password: userForm.password,
							roles: userForm.roles,
							picture: userForm.picture
						}
					})
				);
			}
		}
	};

	const inputValidator = (formKeys) => {
		let result = [];

		formKeys.forEach((key) => {
			let check = null;
			if (key === 'email') {
				check = validateInputEmail(userForm[key]);

				if (!check) {
					setErrorForm({
						[`${key}Error`]: true
					});
				}
			} else {
				check = validateInputFilled(userForm[key]);
				if (!check) {
					setErrorForm({
						[`${key}Error`]: true
					});
				}
			}
			result.push(check);
		});

		return result.filter((result) => result);
	};

	const handleRoles = (value) => {
		let rolesArr = value.map((role) => {
			return role.value;
		});

		setUserForm({ roles: rolesArr });
	};

	return (
		<Container fluid={true}>
			<Breadcrumb parent="Configuración" title="Crear Usuario" isAccount={true} />
			<Row>
				<Col sm={{ size: 8, offset: 2 }}>
					<Card>
						<CardHeader>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									cursor: 'pointer',
									userSelect: 'none'
								}}
							>
								<span>Crear usuario</span>
								<span onClick={() => setTogglePassword(!togglePassword)}>
									{togglePassword ? 'Ocultar' : 'Mostrar'}
								</span>
							</div>
						</CardHeader>
						<CardBody>
							<div
								style={{
									backgroundColor: '#FFF',
									padding: 20,
									minHeight: '80vh',
									marginTop: 5,
									marginBottom: 5
								}}
							>
								<Form className="theme-form">
									<FormGroup>
										<Label className="col-form-label">Imagen de usuario</Label>
										<label
											htmlFor="avatar-picture"
											className="d-flex justify-content-center align-items-center"
										>
											<img
												src={userForm.picture === '' ? userImage : userForm.picture}
												alt="Generic placeholder"
												height={100}
												width={100}
												style={{ cursor: 'pointer' }}
											/>
										</label>
										<input
											type="file"
											id="avatar-picture"
											style={{ display: 'none' }}
											onChange={selectPicture}
										/>
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											{EmailAddress} <span className="text-danger">*</span>
										</Label>
										<Input
											className="form-control"
											type="email"
											required=""
											onChange={(e) => {
												setUserForm({ email: e.target.value });
												setErrorForm({ emailError: false });
											}}
											value={userForm.email}
										/>
										{errorForm.emailError && (
											<span className="text-danger input-error">
												Por favor ingrese un email válido.
											</span>
										)}
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											Nombre y Apellido <span className="text-danger">*</span>
										</Label>
										<Input
											className="form-control"
											type="text"
											required=""
											onChange={(e) => {
												setUserForm({ name: e.target.value });
												setErrorForm({ nameError: false });
											}}
											value={userForm.name}
										/>
										{errorForm.nameError && (
											<span className="text-danger input-error">
												Por favor ingrese un nombre y apellido.
											</span>
										)}
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											{Password} <span className="text-danger">*</span>
										</Label>
										<Input
											className="form-control"
											type={togglePassword ? 'text' : 'password'}
											onChange={(e) => {
												setUserForm({ password: e.target.value });
												setErrorForm({ passwordError: false });
											}}
											value={userForm.password}
											required=""
										/>
										{userForm.password.length > 0 && userForm.password.length < 6 && (
											<span className="text-danger input-error">
												La contraseña debe tener al menos 8 caracteres, entre mayúsculas,
												números y caracteres especiales.
											</span>
										)}
										{errorForm.passwordError && (
											<span className="text-danger input-error">
												Por favor ingrese una contraseña.
											</span>
										)}
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label">
											{ConfirmPassword} <span className="text-danger">*</span>
										</Label>
										<Input
											className="form-control"
											type={togglePassword ? 'text' : 'password'}
											onChange={(e) => {
												setUserForm({ confirmPassword: e.target.value });
												setErrorForm({ confirmPasswordError: false });
											}}
											value={userForm.confirmPassword}
											required=""
										/>

										{userForm.confirmPassword.length > 0 &&
											userForm.password !== userForm.confirmPassword && (
												<span className="text-danger input-error">
													Las contraseñas deben ser iguales.
												</span>
											)}
										{errorForm.confirmPasswordError && (
											<span className="text-danger input-error">
												Por favor confirme la contraseña.
											</span>
										)}
									</FormGroup>
									<FormGroup>
										<Label for="select-role">{SelectRole}</Label>
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											isMulti
											isSearchable
											closeMenuOnSelect={false}
											options={ROLE_VALUES}
											onChange={(e) => handleRoles(e)}
											placeholder="Roles para asignar"
										/>
									</FormGroup>
									<div className="form-group mb-0">
										<Button color="primary" className="btn-block" onClick={doCreateUser}>
											{CreateUser}
										</Button>

										{createUserError && (
											<Alert style={{ marginTop: '2em' }} color="danger">
												{createUserError}
											</Alert>
										)}
										{createUserSuccess && (
											<Alert style={{ marginTop: '2em' }} color="success">
												Usuario creado satisfactoriamente
											</Alert>
										)}
									</div>
								</Form>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default CreateAccount;
