import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogOut, Map, Menu } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import configDB from '../../data/customizer/config';
import Footer from 'layout/footer';
import * as ROUTES from '../../routes';
import { BarChart, Calendar, Home, Settings, Users } from 'react-feather';

// eslint-disable-next-line
import LOGO_BRAND, { LOGO_PSI } from 'brand';
import { logout } from 'redux/actions';
import { getLocalStorageItem } from '../../services/localStorageService';
import { USER_DATA } from '../../constant/localStorage';
import { ROLE_OPTIONS } from '../../constant/variables';
import { axiosInstanceCSV } from 'utils/axiosInstance';
import { toast } from 'react-toastify';

import * as XLSX from 'xlsx';
import { useGetRoles } from 'utils/fetch/roles';

const userData = getLocalStorageItem(USER_DATA);

const exportCsv = async () => {
	try {
		const promise = toast.promise(
			async () => {
				const csvData = await axiosInstanceCSV({
					url: '/general-report-vote/',
					method: 'GET'
				});

				const rows = csvData.data.trim().split('\n');
				const headers = rows[0].split(',');
				const data = rows.slice(1).map((row) => {
					const values = row.split(',');
					return headers.reduce((obj, header, index) => {
						obj[header] = values[index];
						return obj;
					}, {});
				});

				const workbook = XLSX.utils.book_new();
				const worksheet = XLSX.utils.json_to_sheet(data);
				XLSX.utils.book_append_sheet(workbook, worksheet, 'Reclutados');
				XLSX.writeFile(workbook, 'Reclutados.xlsx', { compression: true });
			},
			{
				pending: 'Descargando archivo...',
				success: 'Archivo descargado con éxito!',
				error: 'Error al descargar el archivo'
			},
			{
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			}
		);

		await promise;
	} catch (error) {
		console.error('Error al descargar el archivo:', error);
	}
};

function getConfigItems(role) {
	let childrenList = [];
	switch (role) {
		case ROLE_OPTIONS[2]:
			childrenList = [
				{ path: ROUTES.ACCOUNT, title: 'Mi Perfil', type: 'link', active: false },
				{ path: ROUTES.ACCOUNTS_LIST, title: 'Lista de Usuarios', type: 'link', active: false },
				{ path: ROUTES.NEEDS, title: 'Necesidades', type: 'link', active: false },
				{ path: ROUTES.GOALS, title: 'Metas', type: 'link', active: false },
				{ path: ROUTES.CSV_IMPORTER, title: 'CSV Importer', type: 'link', active: false },
				{ title: 'Descargar electores', type: 'button', active: false, method: () => exportCsv() },
				{ path: ROUTES.ANALISIS, title: 'Panel de control', type: 'link', active: false },
				{ path: ROUTES.VOTERS_GOALS, title: 'Metas de electores', type: 'link', active: false }
			];
			break;
		default:
			childrenList = [{ path: ROUTES.ACCOUNT, title: 'Mi Perfil', type: 'link', active: false }];
			break;
	}
	return childrenList;
}

function getMapItems() {
	return [
		{ path: ROUTES.VOTERS, title: 'Electores', type: 'link', active: false },
		{ path: ROUTES.PYRAMID, title: 'Pirámide', type: 'link', active: false },
		{ path: ROUTES.CARTOGRAPHY, title: 'Cartografía', type: 'link', active: false }
	];
}

// function getSupportsItems() {
// 	return [
// 		{ path: ROUTES.SUPPORTS, title: 'Lista de Apoyos', type: 'link', active: false },
// 		{ path: ROUTES.CAMPAIGNS, title: 'Campañas', type: 'link', active: false }
// 	];
// }

const Sidebar = (props) => {
	const { roles } = useGetRoles();
	const [mainmenu, setMainMenu] = useState([]);
	const [menuCb, setMenuCb] = useState([]);
	const [margin /* setMargin */] = useState(0);
	const [, /* width */ setWidth] = useState(0);
	const [sidebartoogle, setSidebartoogle] = useState(!(window.innerWidth <= 991));
	const User = useSelector((state) => state.User);
	const wrapper =
		useSelector((content) => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
	const currentUrl = window.location.pathname;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		document.querySelector('.left-arrow').classList.add('d-none');

		window.addEventListener('resize', handleResize);
		handleResize();

		mainmenu?.map((items) => {
			items.Items.filter((Items) => {
				if (Items.path === currentUrl) setNavActive(Items);
				if (!Items.children) return false;
				Items.children.filter((subItems) => {
					if (subItems.path === currentUrl) setNavActive(subItems);
					if (!subItems.children) return false;
					subItems.children.filter((subSubItems) => {
						if (subSubItems.path === currentUrl) {
							setNavActive(subSubItems);
							return true;
						} else {
							return false;
						}
					});
					return subItems;
				});
				return Items;
			});
			return items;
		});

		return () => {
			window.removeEventListener('resize', handleResize);
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (userData) {
			const userIsAdmin = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[2]);
			const userHasVoterTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[3]);
			const userHasEventsTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[4]);
			const userHasPyramidTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[5]);
			// const userHasSmsTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[6]);
			// const userHasNeedTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[7]);
			const userHasMapsTab = userData && userData.app_metadata.roles.includes(ROLE_OPTIONS[8]);

			let configItemsByRole = getConfigItems(userIsAdmin ? ROLE_OPTIONS[2] : 'NO_ADMIN');

			let menu = [
				{
					menutitle: 'General',
					// menucontent: 'Tablas y gráficos',
					Items: [
						{
							title: 'Dashboard',
							icon: Home,
							type: 'link',
							active: false,
							path: ROUTES.DASHBOARD
						}
					]
				}
			];

			if (userIsAdmin) {
				menu[0].Items.push(
					{
						title: 'Electores',
						icon: Users,
						type: 'link',
						active: false,
						path: ROUTES.ELECTORES
					},
					{
						title: 'Eventos',
						icon: Calendar,
						type: 'link',
						active: false,
						path: ROUTES.EVENTOS
					},
					{
						title: 'Piramides',
						icon: BarChart,
						type: 'link',
						active: false,
						path: '/piramides'
					},
					// {
					// 	title: 'SMS',
					// 	icon: MessageCircle,
					// 	type: 'link',
					// 	active: false,
					// 	path: ROUTES.SMS
					// },
					// {
					// 	title: 'Reuniones',
					// 	icon: Calendar,
					// 	type: 'link',
					// 	active: false,
					// 	path: ROUTES.REUNIONES
					// },
					// {
					// 	title: 'Necesidades',
					// 	icon: Activity,
					// 	type: 'link',
					// 	active: false,
					// 	path: ROUTES.NEEDS
					// },
					// {
					// 	title: 'Encuestas',
					// 	icon: BarChart2,
					// 	type: 'link',
					// 	active: false,
					// 	path: ROUTES.ENCUESTAS
					// },
					{
						title: 'Mapas',
						icon: Map,
						type: 'sub',
						active: false,
						path: '#mapas',
						children: getMapItems()
					}
					// {
					// 	title: 'Apoyos',
					// 	icon: Gift,
					// 	type: 'sub',
					// 	active: false,
					// 	path: '#apoyos',
					// 	children: getSupportsItems()
					// }
				);
			} else {
				if (userHasVoterTab) {
					menu[0].Items.push({
						title: 'Electores',
						icon: Users,
						type: 'link',
						active: false,
						path: ROUTES.ELECTORES
					});
				}
				if (userHasEventsTab) {
					menu[0].Items.push({
						title: 'Eventos',
						icon: Calendar,
						type: 'link',
						active: false,
						path: ROUTES.EVENTOS
					});
				}
				if (userHasPyramidTab) {
					menu[0].Items.push({
						title: 'Piramides',
						icon: BarChart,
						type: 'link',
						active: false,
						path: '/piramides'
					});
				}
				// if (userHasSmsTab) {
				// 	menu[0].Items.push({
				// 		title: 'SMS',
				// 		icon: MessageCircle,
				// 		type: 'link',
				// 		active: false,
				// 		path: ROUTES.SMS
				// 	});
				// }
				// if (userHasNeedTab) {
				// 	menu[0].Items.push({
				// 		title: 'Necesidades',
				// 		icon: Activity,
				// 		type: 'link',
				// 		active: false,
				// 		path: ROUTES.NEEDS
				// 	});
				// }
				if (userHasMapsTab) {
					menu[0].Items.push({
						title: 'Mapas',
						icon: Map,
						type: 'sub',
						active: false,
						path: '#mapas',
						children: getMapItems()
					});
				}
				// if (userHasMapsTab) {
				// 	menu[0].Items.push({
				// 		title: 'Apoyos',
				// 		icon: Gift,
				// 		type: 'sub',
				// 		active: false,
				// 		path: '#apoyos',
				// 		children: getSupportsItems()
				// 	});
				// }
			}

			// menu[0].Items.push({
			// 	title: 'Bitácora',
			// 	icon: Users,
			// 	type: 'link',
			// 	active: false,
			// 	path: ROUTES.RECRUITED_LOGS
			// });

			menu[0].Items.push({
				title: 'Configuración',
				icon: Settings,
				type: 'sub',
				active: false,
				path: '#configuracion',
				children: configItemsByRole
			});

			setMenuCb(menu);
		}
		// eslint-disable-next-line
	}, [userData]);
	const handleResize = () => {
		setWidth(window.innerWidth - 500);
	};

	const setNavActive = (item) => {
		mainmenu &&
			Array.isArray(mainmenu) &&
			mainmenu?.map((menuCb) => {
				menuCb.Items?.filter((Items) => {
					if (Items !== item) Items.active = false;
					if (Items.children && Items.children.includes(item)) Items.active = true;
					if (Items.children) {
						Items.children.filter((submenuCb) => {
							if (submenuCb.children && submenuCb.children.includes(item)) {
								Items.active = true;
								submenuCb.active = true;
								return true;
							} else {
								return false;
							}
						});
					}
					return Items;
				});
				return menuCb;
			});
		item.active = !item.active;
		setMainMenu({ mainmenu: menuCb });
	};

	const toggletNavActive = (item) => {
		if (window.innerWidth <= 991) {
			document.querySelector('.page-header').className = 'page-header close_icon';
			document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
			if (item.type === 'sub') {
				document.querySelector('.page-header').className = 'page-header ';
				document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
			}
		}

		// TODO: Why this lines??
		// item.active = !item.active;
		// setMainMenu({mainmenu: menuCb()});
	};

	useEffect(() => {
		if (window.innerWidth <= 991) {
			document.querySelector('.page-header').className = 'page-header close_icon';
			document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
		}
	}, []);

	const openCloseSidebar = () => {
		if (sidebartoogle) {
			setSidebartoogle(!sidebartoogle);
			document.querySelector('.page-header').className = 'page-header close_icon';
			document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
		} else {
			setSidebartoogle(!sidebartoogle);
			document.querySelector('.page-header').className = 'page-header';
			document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
		}
	};

	const role = User?.user?.app_metadata?.roles?.[0] === 'ADMIN' ? 'Administrador' : 'Usuario';

	const doLogOut = () => {
		dispatch(logout());
		navigate(ROUTES.INITIAL, { replace: true });
	};

	return (
		<Fragment>
			<div className="sidebar-wrapper">
				<div className="logo-wrapper">
					<Link to={ROUTES.DASHBOARD}>
						<img className="img-fluid for-light" width="150rem" src={LOGO_PSI} alt="" />
						<img className="img-fluid for-dark" width="150rem" src={LOGO_PSI} alt="" />
					</Link>
					<div className="back-btn" onClick={openCloseSidebar}>
						<Menu />
					</div>
					{/* <div className="toggle-sidebar"  onClick={openCloseSidebar}>
						<Grid className="status_toggle middle sidebar-toggle" />
					</div> */}
				</div>
				<div className="logo-icon-wrapper">
					<Link to={ROUTES.DASHBOARD}>
						<img className="img-fluid" src={LOGO_PSI} alt="" />
					</Link>
				</div>
				<nav className="sidebar-main">
					<div className="left-arrow" onClick={openCloseSidebar}>
						<Menu />
					</div>
					<div
						id="sidebar-menu"
						style={wrapper === 'horizontal-wrapper' ? { marginLeft: margin + 'px' } : { margin: '0px' }}
					>
						<ul className="sidebar-links custom-scrollbar">
							<li className="back-btn">
								<div className="mobile-back text-right">
									<span>{'Back'}</span>
									<Menu />
								</div>
							</li>
							{menuCb?.map((Item, i) => {
								return (
									<Fragment key={i}>
										{Item.Items?.map((menuItem, i) => (
											<li className="sidebar-list" key={i}>
												{menuItem.type === 'sub' && (
													<a
														className={`sidebar-link sidebar-title ${
															currentUrl.includes(
																menuItem.path.toLowerCase().replaceAll('#', '')
															) || menuItem.active
																? 'active'
																: ''
														}`}
														href={menuItem.path}
														onClick={() => setNavActive(menuItem)}
													>
														<menuItem.icon />
														<span>{props.t(menuItem.title)}</span>
														{menuItem.badge ? (
															<label className={menuItem.badge}>
																{menuItem.badgetxt}
															</label>
														) : (
															''
														)}
														<div className="according-menu">
															{menuItem.active ? (
																<i className="fa fa-angle-down"></i>
															) : (
																<i className="fa fa-angle-right"></i>
															)}
														</div>
													</a>
												)}

												{menuItem.type === 'link' && (
													<Link
														to={menuItem.path}
														className={`sidebar-link sidebar-title link-nav  ${
															currentUrl.includes(menuItem.path.toLowerCase()) ||
															menuItem.active
																? 'active'
																: ''
														}`}
														onClick={() => toggletNavActive(menuItem)}
													>
														<menuItem.icon />
														<span>{props.t(menuItem.title)}</span>
														{menuItem.badge ? (
															<label className={menuItem.badge}>
																{menuItem.badgetxt}
															</label>
														) : (
															''
														)}
													</Link>
												)}

												{menuItem.children ? (
													<ul
														className="sidebar-submenu"
														style={
															menuItem.active ||
															currentUrl.includes(
																menuItem.path.toLowerCase().replaceAll('#', '')
															)
																? { display: 'block' }
																: { display: 'none' }
														}
													>
														{menuItem.children.map((childrenItem, index) => {
															return (
																<li key={index}>
																	{childrenItem.type === 'button' &&
																	roles &&
																	roles?.length > 0 &&
																	roles?.includes('SuperAdmin') ? (
																		<div
																			style={{ cursor: 'pointer' }}
																			onClick={childrenItem.method}
																		>
																			{props.t(childrenItem.title)}
																		</div>
																	) : (
																		''
																	)}

																	{childrenItem.type === 'sub' ? (
																		<a
																			className={`${
																				currentUrl.includes(
																					childrenItem.path.toLowerCase()
																				) || childrenItem.active
																					? 'active'
																					: ''
																			}`}
																			href={childrenItem.path}
																			onClick={() =>
																				toggletNavActive(childrenItem)
																			}
																		>
																			{props.t(childrenItem.title)}
																			<span className="sub-arrow">
																				<i className="fa fa-chevron-right"></i>
																			</span>
																			<div className="according-menu">
																				{childrenItem.active ? (
																					<i className="fa fa-angle-down"></i>
																				) : (
																					<i className="fa fa-angle-right"></i>
																				)}
																			</div>
																		</a>
																	) : (
																		''
																	)}

																	{childrenItem.type === 'link' ? (
																		<Link
																			to={childrenItem.path}
																			className={`${
																				currentUrl.includes(
																					childrenItem.path.toLowerCase()
																				) || childrenItem.active
																					? 'active'
																					: ''
																			}`}
																			onClick={() =>
																				toggletNavActive(childrenItem)
																			}
																		>
																			{props.t(childrenItem.title)}
																		</Link>
																	) : (
																		''
																	)}

																	{childrenItem.type === 'exteral_link' ? (
																		<a href={childrenItem.path}>
																			{props.t(childrenItem.title)}
																		</a>
																	) : (
																		''
																	)}

																	{childrenItem.children ? (
																		<ul
																			className="nav-sub-childmenu submenu-content"
																			style={
																				childrenItem.active
																					? { display: 'block' }
																					: { display: 'none' }
																			}
																		>
																			{childrenItem.children.map(
																				(childrenSubItem, key) => (
																					<li key={key}>
																						{childrenSubItem.type ===
																						'link' ? (
																							<Link
																								to={
																									childrenSubItem.path
																								}
																								className={`${
																									childrenSubItem.active
																										? 'active'
																										: ''
																								}`}
																								onClick={() =>
																									toggletNavActive(
																										childrenSubItem
																									)
																								}
																							>
																								{props.t(
																									childrenSubItem.title
																								)}
																							</Link>
																						) : (
																							''
																						)}
																					</li>
																				)
																			)}
																		</ul>
																	) : (
																		''
																	)}
																</li>
															);
														})}
													</ul>
												) : (
													''
												)}
											</li>
										))}
									</Fragment>
								);
							})}
						</ul>
						<div className="user-sidebar-info">
							<img className="img-fluid for-light" src={LOGO_PSI} alt="" />
							<div className="ml-2 user-data">
								<div>
									<h6 className="mb-1">{User?.user?.name}</h6>
									<p className="m-0">{role}</p>
								</div>
								<LogOut onClick={doLogOut} />
							</div>
						</div>
						<Footer />
					</div>
				</nav>
				<div className="right-arrow" onClick={openCloseSidebar}>
					<Menu />
				</div>
			</div>
		</Fragment>
	);
};

export default translate(Sidebar);
