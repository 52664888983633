import Breadcrumbs from 'layout/breadcrumb';
import React, { Fragment, useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	InputGroup,
	Label,
	Row
} from 'reactstrap';

import Select from 'react-select';
import {
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import InternalLoader from 'layout/internal-loader';
import { Minus, Plus } from 'react-feather';

import * as ROUTES from '../../../routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { graphQlClient } from '../../../store/graphql';
import { toast } from 'react-toastify';
import { useGetStatesByCredentials } from '../../../utils/fetch/useGetStatesByCredentials';

const getVoterGoalById = gql`
	query Query($id: String!) {
		goalGetOne(_id: $id)
	}
`;

const CREATE_VOTER_GOALS = gql`
	mutation GoalCreateOne($record: CreateGoalInputTCInput) {
		goalCreateOne(record: $record)
	}
`;

const EDIT_VOTER_GOALS = gql`
	mutation GoalUpdateOne($goalUpdateOneRecord2: UpdateGoalInputTCInput) {
		goalUpdateOne(record: $goalUpdateOneRecord2)
	}
`;

const demarcationTypesOptions = [
	{
		label: 'Sección',
		value: 'section'
	},
	{
		label: 'Municipio',
		value: 'municipality'
	},
	{
		label: 'Distrito Federal',
		value: 'federal_district'
	},
	{
		label: 'Distrito Local',
		value: 'local_district'
	}
];

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const CreateVoterGoals = () => {
	const navigate = useNavigate();
	const { voterGoalId } = useParams();

	const [getGoalDetails, { data: voterGoalDetails }] = useLazyQuery(getVoterGoalById, {
		client: graphQlClient,
		variables: {
			id: voterGoalId
		}
	});

	const voterGoalData = voterGoalDetails?.goalGetOne || {};

	const [selectedEntity, setSelectedEntity] = useState(null);
	const [selectedDemarcation, setSelectedDemarcation] = useState(null);
	const [selectedIdentifier, setSelectedIdentifier] = useState(null);
	const [votersNumber, setVotersNumber] = useState(0);
	const [votersReached, setVotersReached] = useState(0);

	const { loading: loadingEntities, data: entities } = useGetStatesByCredentials();

	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedEntity);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedEntity);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedEntity);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedEntity);

	const [identifierOptions, setIdentifierOptions] = useState([]);

	const entitiesOptions = Object.values(entities || {})?.map(({ name, code }) => ({ label: name, value: code }));

	const [createVoterGoal] = useMutation(CREATE_VOTER_GOALS, {
		errorPolicy: 'all',
		client: graphQlClient,
		onCompleted: () => {
			toast.success('Meta creada exitosamente!', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
			navigate(`/configuracion/voter-goals`);
		},
		onError: () => {
			toast.error('Error al crear la meta', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	});

	const [editVoterGoal] = useMutation(EDIT_VOTER_GOALS, {
		errorPolicy: 'all',
		client: graphQlClient,
		onCompleted: () => {
			toast.success('Meta editada exitosamente!', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
			navigate(`/configuracion/voter-goals`);
		},
		onError: () => {
			toast.error('Error al crear la meta', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	});

	const handleDemarcationTypeChange = (selectedDemarcation) => {
		const { value } = selectedDemarcation;

		switch (value) {
			case 'section':
				setIdentifierOptions(sections);
				break;
			case 'municipality':
				setIdentifierOptions(municipalities);
				break;
			case 'federal_district':
				setIdentifierOptions(fDistrics);
				break;
			case 'local_district':
				setIdentifierOptions(lDistrics);
				break;
			default:
				setIdentifierOptions([]);
				break;
		}
		setSelectedDemarcation(selectedDemarcation);
	};

	const handleEntityChange = (entity) => {
		setSelectedEntity(entity);
		setSelectedIdentifier(null);

		if (selectedDemarcation) {
			const { value } = selectedDemarcation;
			switch (value) {
				case 'section':
					setIdentifierOptions(sections);
					break;
				case 'municipality':
					setIdentifierOptions(municipalities);
					break;
				case 'federal_district':
					setIdentifierOptions(fDistrics);
					break;
				case 'local_district':
					setIdentifierOptions(lDistrics);
					break;
				default:
					setIdentifierOptions([]);
					break;
			}
		}
	};

	const create = () => {
		console.log(selectedEntity);
		console.log(selectedDemarcation);
		console.log(selectedIdentifier);
		console.log(votersNumber);

		let record = {};

		if (!selectedEntity) {
			return toast.error('El estado es requerido', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}

		if (!selectedDemarcation) {
			return toast.error('El tipo de demarcación es requerido', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}

		if (!selectedIdentifier) {
			return toast.error('El identificador es requerido', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}

		if (!votersNumber) {
			return toast.error('El número de electores es requerido', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}

		record.state = selectedEntity.value.toString();
		record.demarcation = selectedDemarcation.value;
		record.indicator = selectedIdentifier.value.toString();
		record.qtyElectors = votersNumber;

		console.log(record);

		if (!voterGoalId) {
			createVoterGoal({
				variables: {
					record
				}
			});
		} else {
			let goalUpdateOneRecord2 = record;
			goalUpdateOneRecord2._id = voterGoalData._id;

			editVoterGoal({
				variables: {
					goalUpdateOneRecord2
				}
			});
		}
	};

	useEffect(() => {
		if (voterGoalDetails && entities) {
			let entityFound = entities.find((entity) => entity.code === Number(voterGoalData.state));
			let demarcationFound = demarcationTypesOptions.find((entity) => entity.value === voterGoalData.demarcation);

			if (entityFound) setSelectedEntity({ label: entityFound.name, value: entityFound.code });
			if (demarcationFound) setSelectedDemarcation(demarcationFound);
			setSelectedIdentifier({ label: voterGoalData.indicator, value: voterGoalData.demarcationName });
			setVotersNumber(Number(voterGoalData.qtyElectors));

			if (voterGoalData.avg) {
				const avg = voterGoalData.avg.slice(0, -1);
				const votersPercentage = Number(avg);
				const target = voterGoalData.qtyElectors;
				const qtyVoters = (votersPercentage / 100) * target;

				setVotersReached(Math.ceil(qtyVoters));
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [voterGoalDetails, entities]);

	useEffect(() => {
		if (voterGoalId) {
			getGoalDetails();
		}

		// eslint-disable-next-line
	}, [voterGoalId]);

	if (loadingEntities) return <InternalLoader />;

	return (
		<Fragment>
			<Breadcrumbs parent="Meta de electores" title="Crear nueva" />
			<Container fluid={true}>
				<Row>
					<Col>
						<Card>
							<CardHeader className="p-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">Datos de meta</Row>
							</CardHeader>
							<CardBody className="p-3">
								<Row className="mb-2 px-3">
									<Col xs="12" sm="6" lg="3">
										<FormGroup>
											<Label>Estado</Label>
											<Select
												isClearable
												options={entitiesOptions}
												onChange={(option) => handleEntityChange(option)}
												placeholder="Seleccione un estado"
												value={selectedEntity}
												noOptionsMessage={() => 'No hay opciones'}
												defaultValue={[]}
												isSearchable
												closeMenuOnSelect={true}
												styles={colourStyles}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" sm="6" lg="3">
										<FormGroup>
											<Label>Tipo Demarcación</Label>
											<Select
												isClearable
												options={demarcationTypesOptions}
												name="selectedDemarcation"
												id="selectedDemarcation"
												onChange={(option) => handleDemarcationTypeChange(option)}
												placeholder="Seleccione una demarcación"
												value={selectedDemarcation}
												closeMenuOnSelect={true}
												styles={colourStyles}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" sm="6" lg="3">
										<Label>Identificador</Label>
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											defaultValue={[]}
											isSearchable
											value={selectedIdentifier}
											closeMenuOnSelect={true}
											options={identifierOptions}
											onChange={(option) => setSelectedIdentifier(option)}
											styles={colourStyles}
											placeholder="Seleccione un identificador"
											isLoading={
												loadingSections ||
												loadingLDistrics ||
												loadingFDistrics ||
												loadingMunicipalities
											}
											loadingMessage={() => 'Cargando...'}
											isDisabled={
												loadingSections ||
												loadingLDistrics ||
												loadingFDistrics ||
												loadingMunicipalities
											}
										/>
									</Col>
									<Col xs="12" sm="6" lg="3">
										<FormGroup>
											<Label>{'Número de electores'}</Label>
											<InputGroup>
												<button
													className="increment-decrement-voters decrement"
													onClick={() => {
														if (votersNumber === 0) {
															setVotersNumber(0);
															return;
														}
														setVotersNumber((prevState) => prevState - 1);
													}}
												>
													<Minus size={16} />
												</button>
												<Input
													style={{ borderLeft: '0', borderRight: '0', textAlign: 'center' }}
													placeholder="Ingresar número"
													type="number"
													min={0}
													value={votersNumber}
													pattern="[0-9]*"
													onChange={(e) =>
														setVotersNumber((v) =>
															e.target.validity.valid ? e.target.value : v
														)
													}
												/>
												<button
													className="increment-decrement-voters increment"
													onClick={() => setVotersNumber((prevState) => prevState + 1)}
												>
													<Plus size={16} />
												</button>
											</InputGroup>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col lg="4">
										<Container fluid style={{ height: '100%', display: 'flex' }}>
											<Card style={{ width: '100%' }}>
												<CardHeader className="p-3">
													<p
														style={{
															fontWeight: '500',
															fontSize: '16px',
															lineHeight: '19px',
															color: '#d32f34',
															margin: 0
														}}
													>
														Vista previa de meta
													</p>
												</CardHeader>
												<CardBody className="p-3">
													<Row
														className="py-2 event-info-line"
														style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
													>
														<Col>
															<Row className="event-info-titles">
																<span>Nombre</span>
															</Row>
															<Row>
																<span>
																	{selectedEntity ? selectedEntity.label : '-'}
																</span>
															</Row>
														</Col>
													</Row>
													<Row
														className="py-2 event-info-line"
														style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
													>
														<Col>
															<Row className="event-info-titles">
																<span>Tipo Demarcación</span>
															</Row>
															<Row>
																<span>
																	{selectedDemarcation
																		? selectedDemarcation.label
																		: '-'}
																</span>
															</Row>
														</Col>
													</Row>
													<Row
														className="py-2 event-info-line"
														style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
													>
														<Col>
															<Row className="event-info-titles">
																<span>Identificador</span>
															</Row>
															<Row>
																<span>
																	{selectedIdentifier
																		? selectedIdentifier.label
																		: '-'}
																</span>
															</Row>
														</Col>
													</Row>
													{voterGoalId && (
														<Row
															className="py-2 event-info-line"
															style={{
																display: 'flex',
																flexDirection: 'row',
																gap: '10px'
															}}
														>
															<Col>
																<Row className="event-info-titles">
																	<span>Electores actuales</span>
																</Row>
																<Row>
																	<span>{votersReached}</span>
																</Row>
															</Col>
														</Row>
													)}
													<Row
														className="py-2 event-info-line"
														style={{
															display: 'flex',
															flexDirection: 'row',
															gap: '10px'
														}}
													>
														<Col>
															<Row className="event-info-titles">
																<span>Objetivo electores</span>
															</Row>
															<Row>
																<span>{votersNumber}</span>
															</Row>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Container>
									</Col>
								</Row>
								<Row
									className="pt-2 px-3"
									style={{ display: 'flex', flex: 'row', justifyContent: 'flex-end' }}
								>
									<Link to={ROUTES.VOTERS_GOALS}>
										<Button outline color="primary" style={{ marginRight: '4px' }}>
											Cancelar
										</Button>{' '}
									</Link>
									<Button color="primary" style={{ marginLeft: '4px' }} onClick={() => create()}>
										{voterGoalId ? 'Editar ahora' : 'Crear ahora'}
									</Button>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default CreateVoterGoals;
