import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, InputGroup, Input } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import InternalLoader from '../../../layout/internal-loader';
import { EDIT_ACCOUNT } from '../../../routes';
import * as ROUTES from '../../../routes';
import { PlusCircle, Search } from 'react-feather';
import { usePagination } from 'utils/fetch/pagination';
import { useGetUsersWithPagination } from 'utils/fetch/useGetUsers';
import { customStyles } from 'components/dashboard/electores/tableStyles';

const filter = {};

const AccountsList = () => {
	const navigate = useNavigate();

	const [filterParams, setFilterParams] = useState(filter);

	const { page, perPage, handlePageChange, handlePerRowsChange } = usePagination();

	const { loading, data } = useGetUsersWithPagination({ perPage: perPage, page: page, filter: filterParams });

	const [search, setSearch] = useState('');

	const goToEditUser = (account) => {
		const data = {
			...account,
			picture: account.picture.props.src
		};

		navigate(EDIT_ACCOUNT, { state: { account: data } });
	};

	const columns = [
		{
			name: 'Usuario',
			center: true,
			cell: (row) => {
				return (
					<div className="cell-avatar">
						<div>
							<img
								src={row.picture.props.src}
								style={{ minWidth: 50, minHeight: 50, width: 50, height: 50 }}
								alt={'accountPic'}
							/>
						</div>
						<div className="ml-2 mb-0">
							<p className="mb-0">{row.email?.toLowerCase()}</p>
						</div>
					</div>
				);
			},
			width: '40%'
		},
		{
			name: 'Rol',
			center: true,
			cell: (row) => row.role
		},
		{
			name: 'Estado',
			center: true,
			cell: (row) => row.status
		},
		{
			cell: (row) => (
				<div className="actionButton" onClick={() => goToEditUser(row)}>
					<i className="fa fa-angle-right actionButton"></i>
				</div>
			),
			width: '40px',
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	const usersData = Object.values(data.items || {})?.map((user) => {
		const status = user?.blocked ? 'Bloqueado' : 'Activo';
		return {
			picture: <img src={user?.picture} style={{ width: 40, height: 40, borderRadius: 80 }} alt="" />,
			user_id: user?.user_id,
			email: user?.email,
			name: user?.name,
			role: user?.app_metadata.roles.join(', '),
			status: status
		};
	});

	const handleApplyFilter = () => {
		let filterToApply = {};

		if (search !== '') {
			filterToApply.name = search;

			setFilterParams(filterToApply);
		} else {
			setFilterParams({});
		}
	};

	return (
		<Fragment>
			<Breadcrumb parent="Configuración" title="Lista de usuarios" isAccount={true} />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<Row className="align-items-center">
									<Col sm="auto" className="mr-auto">
										<span>Lista de usuarios</span>
									</Col>
									<Col lg="5" sm="3" style={{ height: '100%', padding: '0px 4px' }}>
										<InputGroup className="search-bar">
											<Input
												placeholder="Buscar..."
												className="pl-4 search-input"
												onChange={(e) => setSearch(e.target.value)}
												value={search}
											/>
											<Search
												style={{
													position: 'absolute',
													left: '6px',
													bottom: '10px',
													color: 'gray',
													zIndex: 10
												}}
												size={16}
											/>
											<Button
												className="search-elector-button"
												color="primary"
												onClick={() => handleApplyFilter()}
											>
												Buscar
											</Button>
										</InputGroup>
									</Col>
									<Col className="header-options" sm="auto">
										<Link to={ROUTES.CREATE_ACCOUNT}>
											<Button
												size="md"
												className="filterButton ml-2 px-2 d-flex align-items-center"
												color="primary"
											>
												<PlusCircle className="ml-2" size={16} />
												<span className="ml-1 mr-1">Crear nuevo</span>
											</Button>
										</Link>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className="table-responsive product-table">
									<DataTable
										customStyles={customStyles}
										noHeader
										columns={columns}
										data={usersData}
										progressPending={loading}
										progressComponent={<InternalLoader />}
										pagination
										paginationServer
										paginationTotalRows={data.count}
										onChangeRowsPerPage={handlePerRowsChange}
										onChangePage={handlePageChange}
										paginationDefaultPage={page}
										paginationPerPage={perPage}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AccountsList;
