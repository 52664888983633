import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import InputPlusMinus from 'components/inputPlusMinus/InputPlusMinus';
import Checkbox from 'components/checkbox/Checkbox';
import { Plus, X } from 'react-feather';
import { axiosInstance } from 'utils/axiosInstance';
import { useEffect, useState } from 'react';
import InternalLoader from 'layout/internal-loader';
import DataTable from 'react-data-table-component';
import { customStyles } from 'components/dashboard/electores/tableStyles';

const DropdownIndicator = (props) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<Plus size={14} color="#F04F62" />
			</components.DropdownIndicator>
		)
	);
};

export default function CreateLevelForm({
	structrure,
	level,
	levels,
	handleLevelChange,
	formData,
	selectedRecruiters,
	levelsData
}) {
	const [selectedRecruitersValue, setSelectedRecruitersValue] = useState([]);

	const handleNumberRecruitersChange = (value) => {
		if (!value) {
			handleLevelChange(level, { recruiters: [], number_people_recruit: value });
			return;
		}
		const valueNumber = parseInt(value, 10);
		if (formData?.recruiters?.length > valueNumber) {
			handleLevelChange(level, {
				number_people_recruit: value,
				recruiters: formData?.recruiters.slice(0, valueNumber)
			});
			return;
		}
		handleLevelChange(level, { number_people_recruit: value });
	};

	const handleNumbeOfParticipantsChange = (value) => {
		if (!value) {
			handleLevelChange(level, { recruiters: [], number_managers: value });
			return;
		}

		const valueNumber = parseInt(value, 10);

		if (formData?.recruiters?.length > valueNumber) {
			handleLevelChange(level, {
				number_managers: value,
				recruiters: formData?.recruiters.slice(0, valueNumber)
			});
			return;
		}

		handleLevelChange(level, { number_managers: value });
	};

	const handleRecruitersChange = (selectedOptions) => {
		if (selectedOptions?.length > formData?.number_managers) {
			return;
		}

		setSelectedRecruitersValue([...selectedOptions]);
		handleLevelChange(level, { recruiters: selectedOptions });
	};

	const handleRecruitersOptions = async (query) => {
		try {
			const { data } = await axiosInstance({
				url: `users/profiles/?full_name=${query}&user__groups__name=Reclutadores`,
				method: 'GET'
			});

			return data.results.map((recruiter) => {
				const {
					id,
					info_voter: {
						info: { curp, electoral_key, first_name, full_name, last_name }
					}
				} = recruiter;

				return {
					value: id,
					label: full_name,
					recruiter: { curp, electoral_key, first_name, full_name, last_name }
				};
			});
		} catch (err) {
			return console.log(err.response);
		}
	};

	const filterOption = (option) => {
		const recruiters = selectedRecruiters();
		return !recruiters.includes(option.value);
	};

	const handleRemoveRecruiterFromTable = (id) => {
		const recruiters = selectedRecruitersValue.filter((rec) => rec.value !== id);

		setSelectedRecruitersValue(recruiters);
		handleLevelChange(level, { recruiters: recruiters });
		// setSelectedRecruitersValue((prev) => prev.filter((rec) => rec.value !== id));
	};

	useEffect(() => {
		const data = levelsData[level]?.recruiters || [];

		setSelectedRecruitersValue(data);
	}, [level, levelsData]);

	const recruitersColumns = [
		{
			name: 'Nombre y Apellido',
			selector: ({ recruiter }) => recruiter.full_name
		},
		{
			name: 'Clave de elector',
			selector: ({ recruiter }) => recruiter.electoral_key
		},
		{
			cell: (row) => (
				<X
					color="#d32f34"
					onClick={() => handleRemoveRecruiterFromTable(row.value)}
					style={{ cursor: 'pointer' }}
				/>
			),
			width: '40px',
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	if (!formData) return <InternalLoader />;

	return (
		<>
			<Row>
				<Col sm="6" lg="6">
					<FormGroup>
						<Label className="col-form-label">Nombre de nivel</Label>
						<Input
							className="form-control"
							type="text"
							required=""
							placeholder="Ingresar nombre"
							value={formData?.name || ''}
							onChange={(evt) => handleLevelChange(level, { name: evt.target.value })}
						/>
					</FormGroup>
				</Col>
				<Col sm="3" lg="3">
					<FormGroup>
						<Label className="col-form-label">Número de participantes</Label>
						<InputPlusMinus
							noNegativeNumbers
							placeholder="Ingresar número"
							onChange={handleNumbeOfParticipantsChange}
							value={formData?.number_managers}
						/>
					</FormGroup>
				</Col>
				<Col sm="3" xs="3">
					<FormGroup>
						<Label className="col-form-label">Cantidad a reclutar por persona</Label>
						<InputPlusMinus
							noNegativeNumbers
							placeholder="Ingresar número"
							disabled={!formData?.recruiter}
							onChange={handleNumberRecruitersChange}
							value={formData?.number_people_recruit || ''}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col sm="2">
					<FormGroup>
						<Label className="col-form-label">¿Este nivel es recluta?</Label>
						<Checkbox
							label="Sí"
							id={`isRecruiterLevel-${level}`}
							checked={level === 0 ? true : formData?.recruiter}
							// disabled={!formData?.parent_lvl}
							onChange={(value) => {
								return level !== 0 && handleLevelChange(level, { recruiter: value });
							}}
						/>
					</FormGroup>
				</Col>
				<Col sm="10" lg="10">
					<FormGroup>
						<Label className="col-form-label">Definir reclutadores</Label>
						<AsyncSelect
							components={{ DropdownIndicator }}
							isClearable
							name="pyramidRecruiter"
							placeholder="Ingresar nombre"
							valueKey="value"
							labelKey="label"
							matchPos="any"
							loadOptions={handleRecruitersOptions}
							hideSelectedOptions={true}
							onChange={handleRecruitersChange}
							loadingMessage={() => 'Cargando...'}
							noOptionsMessage={() => 'Ingresar nombre'}
							filterOption={filterOption}
							isMulti
							value={selectedRecruitersValue}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col sm="12" xs="12">
					<h4 className="title">Lista de reclutadores</h4>
				</Col>
			</Row>

			<Row>
				<DataTable
					customStyles={customStyles}
					noDataComponent={'No se han encontrado reclutadores'}
					columns={recruitersColumns}
					data={selectedRecruitersValue}
				/>
			</Row>
		</>
	);
}
