import CustomSlider from 'components/custom-slider/CustomSlider';
import { useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { RotateCcw, RotateCw, X } from 'react-feather';
import { Button, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import './cropper.scss';
import { toast } from 'react-toastify';

const CustomCropper = ({ isOpen, onClose, src, onSave, imageHeight = 281.25, orientation = 'landscape' }) => {
	const [filters, setFilters] = useState({
		brightness: 100,
		contrast: 100,
		saturate: 100,
		sepia: 0
	});
	const [scale, setScale] = useState(1);
	const cropperRef = useRef(null);
	const [activeTab, setActiveTab] = useState('adjust');

	const onReset = () => {
		const cropper = cropperRef.current?.cropper;
		if (cropper) {
			cropper.reset();
			setScale(1);
			setFilters({
				brightness: 100,
				contrast: 100,
				saturate: 100,
				sepia: 0
			});
		}
	};

	const applyFilters = () => ({
		filter: `
        brightness(${filters.brightness}%)
        contrast(${filters.contrast}%)
        saturate(${filters.saturate}%)
        sepia(${filters.sepia}%)
      `
	});

	const handleSaveCroppedImage = async () => {
		try {
			const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
			if (!croppedCanvas) {
				console.error('Cropped canvas is invalid or undefined');
				return;
			}

			// Orientation Validation
			if (orientation === 'landscape' && croppedCanvas.height > croppedCanvas.width) {
				toast.error('La imagen debe ser de orientación horizontal', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
				return;
			} else if (orientation === 'portrait' && croppedCanvas.width > croppedCanvas.height) {
				toast.error('La imagen debe ser de orientación vertical', {
					position: 'top-right',
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
				return;
			}

			// Step 1: Create a new canvas to apply filters
			const filteredCanvas = document.createElement('canvas');
			const ctx = filteredCanvas.getContext('2d');
			filteredCanvas.width = croppedCanvas.width;
			filteredCanvas.height = croppedCanvas.height;
			ctx.drawImage(croppedCanvas, 0, 0);

			// Step 2: Apply filters programmatically
			const imageData = ctx.getImageData(0, 0, filteredCanvas.width, filteredCanvas.height);
			const data = imageData.data;
			const { brightness, contrast, saturate, sepia } = filters;

			for (let i = 0; i < data.length; i += 4) {
				// Brightness
				data[i] += (brightness - 100) * 2.55;
				data[i + 1] += (brightness - 100) * 2.55;
				data[i + 2] += (brightness - 100) * 2.55;

				// Contrast
				data[i] = (data[i] - 128) * (contrast / 100) + 128;
				data[i + 1] = (data[i + 1] - 128) * (contrast / 100) + 128;
				data[i + 2] = (data[i + 2] - 128) * (contrast / 100) + 128;

				// Saturation
				const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
				data[i] = avg + (data[i] - avg) * (saturate / 100);
				data[i + 1] = avg + (data[i + 1] - avg) * (saturate / 100);
				data[i + 2] = avg + (data[i + 2] - avg) * (saturate / 100);

				// Sepia
				if (sepia > 0) {
					data[i] = Math.min(
						255,
						data[i] * (1 - 0.607 * (sepia / 100)) +
							data[i + 1] * 0.769 * (sepia / 100) +
							data[i + 2] * 0.189 * (sepia / 100)
					);
					data[i + 1] = Math.min(
						255,
						data[i] * 0.349 * (sepia / 100) +
							data[i + 1] * (1 - 0.314 * (sepia / 100)) +
							data[i + 2] * 0.168 * (sepia / 100)
					);
					data[i + 2] = Math.min(
						255,
						data[i] * 0.272 * (sepia / 100) +
							data[i + 1] * 0.534 * (sepia / 100) +
							data[i + 2] * (1 - 0.869 * (sepia / 100))
					);
				}
			}

			// Step 3: Put the modified image data back onto the canvas
			ctx.putImageData(imageData, 0, 0);

			// Step 4: Convert the filtered canvas to a Base64 string
			const filteredImageUrl = filteredCanvas.toDataURL('image/jpeg');

			// Step 5: Save the file
			onSave(filteredImageUrl); // Notify the parent component
			onClose(); // Close the modal
		} catch (error) {
			console.error('Error saving cropped image:', error);
		}
	};

	const toggleTab = (tabId) => {
		if (activeTab !== tabId) {
			setActiveTab(tabId);
		}
	};

	const onRotate = (direction) => {
		let angle = 0;
		let angleConfig = {
			left: -90,
			right: 90
		};
		angle = angleConfig[direction] ?? 0;
		cropperRef.current?.cropper.rotate(angle);
	};

	const onScale = (value) => {
		const scaleValue = parseFloat(value);
		setScale(scaleValue);
		cropperRef.current?.cropper.scale(scaleValue);
	};

	const handleFilterChange = (value, filterName) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[filterName]: parseInt(value, 10)
		}));
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			centered
			size={'xl'}
			onClosed={() => {
				onReset();
				setActiveTab('adjust');
			}}
		>
			<ModalBody style={{ width: '100%', padding: '8px 32px 32px 32px' }}>
				<Row style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={onClose} />
				</Row>
				<div className="imageEditor">
					<div>
						<div style={({ width: 'auto', height: 'auto' }, applyFilters())}>
							<Cropper
								src={src}
								style={{ height: imageHeight, width: 900 }}
								initialAspectRatio={16 / 9}
								guides={false}
								ref={cropperRef}
							/>
						</div>
						<div className="mt-4">
							<Nav tabs horizontal="center" fill>
								<NavItem onClick={() => toggleTab('adjust')}>
									<NavLink className={activeTab === 'adjust' ? 'active' : ''}>Ajustar</NavLink>
								</NavItem>
								<NavItem onClick={() => toggleTab('appearance')}>
									<NavLink className={activeTab === 'appearance' ? 'active' : ''}>Apariencia</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="adjust">
									<div className="controlsBlock">
										<div className="controlItem">
											<div>
												<RotateCcw
													color="#d32f34"
													style={{ cursor: 'pointer', margin: '0 10px' }}
													onClick={() => onRotate('left')}
													size={18}
												/>
												<RotateCw
													color="#d32f34"
													style={{ cursor: 'pointer', margin: '0 10px' }}
													onClick={() => onRotate('right')}
													size={18}
												/>
											</div>
											<span
												style={{
													fontSize: '12px',
													color: '#333',
													fontWeight: '500',
													lineHeight: '16px'
												}}
											>
												Rotar
											</span>
										</div>
										<CustomSlider
											label="Zoom"
											min="0.2"
											max="2"
											step="0.2"
											value={scale}
											onChange={(value) => onScale(value)}
										/>
									</div>
								</TabPane>
								<TabPane tabId="appearance">
									<div className="controlsBlock">
										<CustomSlider
											label="Brillo"
											min="0"
											max="200"
											step="10"
											value={filters.brightness}
											onChange={(value) => handleFilterChange(value, 'brightness')}
										/>
										{/* Contraste */}
										<CustomSlider
											label="Contraste"
											min="0"
											max="200"
											step="10"
											value={filters.contrast}
											onChange={(value) => handleFilterChange(value, 'contrast')}
										/>
										{/* Saturación */}
										<CustomSlider
											label="Saturación"
											min="0"
											max="200"
											step="10"
											value={filters.saturate}
											onChange={(value) => handleFilterChange(value, 'saturate')}
										/>
										{/* Sepia */}
										<CustomSlider
											label="Sepia"
											min="0"
											max="100"
											step="5"
											value={filters.sepia}
											onChange={(value) => handleFilterChange(value, 'sepia')}
										/>
									</div>
								</TabPane>
							</TabContent>
						</div>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'center',
						gap: '8px',
						marginTop: '16px'
					}}
				>
					<Button outline color="light-2x txt-dark" onClick={onReset}>
						Reiniciar
					</Button>
					<Button outline color="light-2x txt-dark" onClick={onClose}>
						Cancelar
					</Button>
					<Button color="primary" onClick={handleSaveCroppedImage}>
						Aplicar
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default CustomCropper;
