import { CellGender } from 'components/dashboard/electores/Electores';
import { Button, Modal, ModalBody, Row } from 'reactstrap';
import './detailMessage.scss';
import { CellStatus } from './SmsTable';
import moment from 'moment/moment';
import { useState } from 'react';
import { ChevronLeft, Eye, X } from 'react-feather';

import '../../eventDetails/appliedTerritories.scss';

import { useNavigate } from 'react-router-dom';

const AppliedTerritoriesDetails = ({ state, sections, municipality, lDistricts, fDistrics }) => {
	return (
		<>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{state && (
					<div className="selected-items">
						<div className="items-header">
							<span className="optionTitle">Estado</span>
						</div>
						<div>
							<ul className="list-horizontal">
								<li key={`section-${state}`}>{`${state}`}</li>
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{sections.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<span className="optionTitle">Sección</span>
						</div>
						<div>
							<ul className="list-horizontal">
								{sections.map((section, index) => {
									return <li key={`section-${section}`}>{`${section}`}</li>;
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{municipality && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Municipios</h5>
						</div>
						<div>
							<ul className="list-horizontal">
								<li key={`municipalities-${municipality}`}>{`${municipality}`}</li>
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{lDistricts?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Distritos Locales</h5>
						</div>
						<div>
							<ul className="list-horizontal">
								{lDistricts.map((lDistrict, index) => {
									return <li key={`lDistrict-${lDistrict}`}>{`${lDistrict}`}</li>;
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
			<Row style={{ width: '100%', alignItems: 'flex-start', padding: '0px' }}>
				{fDistrics?.length > 0 && (
					<div className="selected-items">
						<div className="items-header">
							<h5 className="optionTitle">Distritos Federales</h5>
						</div>
						<div>
							<ul className="list-horizontal">
								{fDistrics.map((fDistrict, index) => {
									return <li key={`fDistrict-${fDistrict}`}>{`${fDistrict}`}</li>;
								})}
							</ul>
						</div>
					</div>
				)}
			</Row>
		</>
	);
};

const StatusAndTimeMessage = ({ status, dateTimeToSend }) => {
	return (
		<>
			<div className="px-3">
				<CellStatus status={status} />
			</div>
			<div className="px-3">
				<span style={{ fontSize: '14px', color: '#d32f34' }}>
					{moment(dateTimeToSend).format('DD MMMM YYYY - hh:mm a')}
				</span>
			</div>
		</>
	);
};

export const ShowMessage = ({ id, data, isDetail = false }) => {
	const navigate = useNavigate();

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [modalPage, setModalPage] = useState('main');

	const {
		filter: { city, state, sections, localDistrict, federalDistrict, gender },
		peopleContacted,
		territoriesReached
	} = data;

	return (
		<div className="d-flex align-items-center">
			<p className="m-0" id={'ID' + id} onClick={toggle}>
				<span className="d-flex">
					<Eye className="font-primary" />
				</span>
			</p>

			<Modal isOpen={modal} toggle={toggle} centered size={modalPage === 'destinataries' && 'xl'}>
				<ModalBody style={{ width: '100%' }}>
					{modalPage === 'main' && (
						<>
							<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div className="px-3">
									<span className="f-16">Vista previa del mensaje</span>{' '}
									<span className="messageId">{data._id?.slice?.(0, 7)}</span>
								</div>
								<div className="px-3">
									<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
								</div>
							</Row>
							<Row className="py-2">
								<div className="px-3" style={{ width: '100%' }}>
									<p
										style={{
											backgroundColor: '#F8F8F8',
											padding: '8px',
											border: '1px solid #ECF3FA',
											borderRadius: '8px',
											height: '100%',
											wordWrap: 'break-word'
										}}
									>
										{data?.message}
									</p>
								</div>
							</Row>
							<Row className="py-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
								<StatusAndTimeMessage status={data.status} dateTimeToSend={data.dateTimeToSend} />
							</Row>
							{isDetail && (
								<>
									<Row
										className="py-2"
										style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}
									>
										<div
											className="px-3"
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '8px',
												justifyContent: 'flex-start'
											}}
										>
											{gender && (
												<div className="sms-info">
													<span className="title">Sexo: </span>
													<span>
														<CellGender gender={gender} />
													</span>
												</div>
											)}

											{state && (
												<div className="sms-info">
													<span className="title">Estado: </span>
													<span className="info">{state}</span>
												</div>
											)}

											{sections.length > 0 && (
												<div className="sms-info">
													<span className="title">Sección: </span>
													<span className="info">{sections.join(', ')}</span>
												</div>
											)}

											{city && (
												<div className="sms-info">
													<span className="title">Municipio: </span>
													<span className="info">{city}</span>
												</div>
											)}

											{localDistrict.length > 0 && (
												<div className="sms-info">
													<span className="title">D. Local: </span>
													<span className="info">{localDistrict.join(', ')}</span>
												</div>
											)}

											{federalDistrict.length > 0 && (
												<div className="sms-info">
													<span className="title">D. Federal: </span>
													<span className="info">{federalDistrict.join(', ')}</span>
												</div>
											)}
										</div>
										<div
											className="px-3"
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '16px',
												justifyContent: 'flex-end',
												alignItems: 'start'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '8px',
													justifyContent: 'flex-end',
													width: '100%'
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														gap: '8px',
														justifyContent: 'flex-end',
														width: '100%'
													}}
												>
													<span className="territories-info">
														{territoriesReached} territorios alcanzados
													</span>
													<Button
														color="primary"
														disabled={Number(territoriesReached) === 0}
														onClick={() => setModalPage('territories')}
													>
														Ver territorios
													</Button>
												</div>
											</div>

											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '8px',
													justifyContent: 'flex-end',
													width: '100%'
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														gap: '8px',
														justifyContent: 'flex-end',
														width: '100%'
													}}
												>
													<span className="territories-info">
														{peopleContacted} personas contactadas
													</span>
													<Button
														color="primary"
														disabled={Number(peopleContacted) === 0}
														onClick={() => {
															navigate(`/sms/${data._id}/destinataries`, {
																state: { destinataries: data.destinataries }
															});
														}}
													>
														Ver personas
													</Button>
												</div>
											</div>
										</div>
									</Row>
								</>
							)}
						</>
					)}
					{modalPage === 'territories' && (
						<>
							<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div
									className="px-3"
									style={{
										cursor: 'pointer',
										display: 'flex',
										flexDirection: 'row',
										gap: '2px',
										alignItems: 'center',
										justifyContent: 'center'
									}}
									onClick={() => setModalPage('main')}
								>
									<ChevronLeft size={16} color="gray" />
									<span style={{ color: 'gray' }}>Volver atrás</span>
								</div>
								<div className="px-3">
									<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
								</div>
							</Row>
							<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div className="px-3">
									<span className="f-16">Demarcación de territorio</span>
								</div>
							</Row>
							<Row className="py-2">
								<div className="px-3" style={{ width: '100%' }}>
									<AppliedTerritoriesDetails
										state={state}
										sections={sections}
										municipality={city}
										fDistrics={federalDistrict}
										lDistricts={localDistrict}
									/>
								</div>
							</Row>
							<Row className="py-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
								<StatusAndTimeMessage status={data.status} dateTimeToSend={data.dateTimeToSend} />
							</Row>
						</>
					)}
				</ModalBody>
			</Modal>
		</div>
	);
};
