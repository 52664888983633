import { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import InternalLoader from 'layout/internal-loader';
import './bitacora.scss';
import DataTable from 'react-data-table-component';
import FilterLogs from './FilterLogs';
import { CustomRangePicker } from 'components/range-picker/range-picker';
import moment from 'moment';
import { Filter } from 'react-feather';
import { axiosInstance } from 'utils/axiosInstance';

const demarcaciones = {
	state: 'Estado',
	municipality: 'Municipio',
	local_district: 'D. Local',
	district: 'D. Federal',
	section: 'Sección'
};

const tableStyles = {
	headCells: {
		style: {
			color: '#d32f34',
			paddingLeft: '8px',
			paddingRight: '8px',
			whiteSpace: 'nowrap', // Prevent text from wrapping
			overflow: 'hidden', // Hide the overflow
			textOverflow: 'ellipsis', // Show ellipsis for overflowing text
			':first-child': {
				position: 'sticky',
				left: '0',
				backgroundColor: '#fbeaeb',
				zIndex: '1' // Ensure the first column is on top
			},
			':last-child': {
				position: 'sticky',
				right: '0',
				backgroundColor: '#fbeaeb',
				zIndex: '1' // Ensure the last column is on top
			}
		}
	},
	cells: {
		style: {
			paddingLeft: '8px',
			paddingRight: '8px',
			whiteSpace: 'nowrap', // Prevent text from wrapping
			overflow: 'hidden', // Hide the overflow
			textOverflow: 'ellipsis', // Show ellipsis for overflowing text
			':first-child': {
				position: 'sticky',
				left: '0',
				backgroundColor: '#fbeaeb',
				zIndex: '1' // Ensure the first column is on top
			},
			':last-child': {
				position: 'sticky',
				right: '0',
				backgroundColor: '#fbeaeb',
				zIndex: '1' // Ensure the last column is on top
			}
		}
	}
};

const getRange = (firstDay, lastDay) => {
	const dates = [];

	const start = moment(firstDay);
	const end = moment(lastDay);

	// Recorrer los días y agregarlos al arreglo
	while (start.isSameOrBefore(end)) {
		dates.push({ table: start.format('ddd DD/MM'), request: start.format('YYYY-MM-DD') });
		start.add(1, 'days');
	}

	return dates;
};

const Bitacora = () => {
	const [openFilter, setOpenFilter] = useState(false);
	const [loading, setLoading] = useState(false);

	const [selectedDemarcation, setSelectedDemarcation] = useState(null);
	const [demarcationType, setDemarcationType] = useState(null);

	const [selectedRange, setSelectedRange] = useState(undefined);

	const [data, setData] = useState([]);

	const [columns, setColumns] = useState([]);

	useEffect(() => {
		if (selectedRange) {
			const { firstDay, lastDay } = selectedRange;

			const dates = getRange(firstDay, lastDay);

			setColumns([
				{
					name: demarcationType ? `Demarcación - ${demarcaciones[demarcationType]}` : 'Demarcación',
					selector: (row) => row?.demarcation?.label || '-',
					width: '20%'
				},
				...dates.map((date, index) => ({
					name: date.table,
					selector: (row) => {
						if (row && row[`day_${index + 1}`] && row[`day_${index + 1}`]?.total) {
							return row[`day_${index + 1}`].total?.toLocaleString();
						} else {
							return 0;
						}
					},
					center: true
				})),
				{
					name: 'Total',
					selector: (row) => row?.total_record?.toLocaleString() || 0,
					width: '150px',
					right: true
				}
			]);
		}
	}, [selectedRange, demarcationType]);

	const handleApplyFilter = useCallback(
		async (firstDay, lastDay) => {
			try {
				setOpenFilter(false);
				if (selectedDemarcation && demarcationType && selectedRange) {
					setLoading(true);

					const datesRange = getRange(firstDay, lastDay);

					const datesInfo = await Promise.all(
						datesRange.map(async ({ request }) => {
							try {
								const {
									data: { results }
								} = await axiosInstance({
									url: `/logs-records/?created_at=${request}&${demarcationType}=${selectedDemarcation.value}`
								});

								if (results.length > 0) {
									return {
										date: request,
										total: results[0][`total_${demarcationType}`]
									};
								} else {
									return { date: request, total: 0 };
								}
							} catch (error) {
								return { date: request, total: 0 };
							}
						})
					);

					const result = datesInfo.reduce(
						(acc, curr, index) => {
							const { date, total } = curr;
							acc.total_record += total;
							acc[`day_${index + 1}`] = { title: date, total };
							return acc;
						},
						{ demarcation: selectedDemarcation, total_record: 0 }
					);

					setData(result);
				} else {
					return;
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		},
		[demarcationType, selectedDemarcation, selectedRange]
	);

	// useEffect(() => {
	// 	if (selectedRange) {
	// 		handleApplyFilter();
	// 	}
	// }, [selectedRange, handleApplyFilter]);

	const toggle = () => setOpenFilter(!openFilter);

	return (
		<Fragment>
			<Breadcrumb title="Bitácora" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader style={{ padding: '32px 40px' }}>
								<Row className="recruited-logs-header">
									<Col lg={4}>
										<h5>Registros</h5>
									</Col>
									<Col className="filter-recruited-logs-header">
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												height: '33px',
												padding: '0px 4px'
											}}
										>
											<Button
												className="button-content headers-button"
												style={{ padding: '0 25px' }}
												color="primary"
												onClick={toggle}
											>
												<span>Filtrar</span>
												<Filter size={16} />
											</Button>
										</div>
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												height: '33px',
												padding: '0px 4px'
											}}
										>
											<CustomRangePicker
												onChange={setSelectedRange}
												handleFilter={handleApplyFilter}
											/>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<DataTable
									customStyles={tableStyles}
									columns={columns}
									data={[data]}
									progressComponent={<InternalLoader />}
									progressPending={loading}
									noDataComponent={'No se han encontrado registros'}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<FilterLogs
				isOpen={openFilter}
				object
				toggle={toggle}
				states={{
					selectedDemarcation,
					setSelectedDemarcation,
					demarcationType,
					setDemarcationType
				}}
				handleFilter={handleApplyFilter}
				range={selectedRange}
			/>
		</Fragment>
	);
};
export default Bitacora;
