import React, { useEffect, useState } from 'react';
import { Minus, Plus, Save, X } from 'react-feather';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import Select from 'react-select';
import { useEntities } from 'utils/fetch/entities';
import { axiosInstance } from 'utils/axiosInstance';

import InputRange from 'react-input-range';
import { toast } from 'react-toastify';

const genders = [
	{ label: 'Masculino', value: 'Hombre' },
	{ label: 'Femenino', value: 'Mujer' }
];

const conditionals = [
	{ label: 'Mayor que', value: 'greaterThan' },
	{ label: 'Menor que', value: 'lessThan' }
];

const ratingValue = [
	{ value: 1, label: 1 },
	{ value: 2, label: 2 },
	{ value: 3, label: 3 },
	{ value: 4, label: 4 },
	{ value: 5, label: 5 },
	{ value: 6, label: 6 },
	{ value: 7, label: 7 },
	{ value: 8, label: 8 },
	{ value: 9, label: 9 },
	{ value: 10, label: 10 }
];

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const CondicionantePregunta = ({
	isOpen,
	toggle,
	categoriesOptions,
	subcategoriesOptions,
	title,
	handleAddQuestionCondition,
	allQuestions
}) => {
	const initialAge = { min: 20, max: 40 };
	/**
	 * Handle load entity
	 */
	const { loading: loadingEntities, data: entities } = useEntities();

	/**
	 * States for conditions
	 */
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedEntity, setSelectedEntity] = useState(null);
	const [selectedMunicipality, setSelectedMunicipality] = useState(null);
	const [selectedAge, setSelectedAge] = useState(initialAge);
	const [hasSelectedAge, setHasSelectedAge] = useState(false);

	/**
	 * States for municipality options
	 */
	const [entitiesOptions, setEntitiesOptions] = useState([]);
	const [municipalityOptions, setMunicipalityOptions] = useState([]);
	const [loadingMunicipalities, setLoadingMunicipalities] = useState(false);

	const [selectedCat, setSelectedCat] = useState(null);
	const [selectedCondition, setSelectedCondition] = useState(null);
	const [selectedRate, setSelectedRate] = useState(null);

	/**
	 * States for questions conditions
	 */
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [answersOptions, setAnswersOptions] = useState([]);

	/**
	 * State to add conditional questions
	 */
	const [conditionalQuestions, setConditionalQuestions] = useState([]);

	useEffect(() => {
		setEntitiesOptions(
			Object.values(entities || {})?.map((entity) => {
				return {
					label: entity.name,
					value: entity.code
				};
			})
		);
	}, [setEntitiesOptions, entities]);

	/**
	 * Select gender handler
	 */
	const handleCheckChange = (event) => {
		if (event.target.checked) {
			setSelectedGender([...selectedGender, event.target.value]);
		} else {
			setSelectedGender(selectedGender.filter((gender) => gender !== event.target.value));
		}
	};

	/**
	 * Select entity handler
	 */
	const handleSelectEntity = async (estado) => {
		if (!estado) {
			setSelectedEntity();
			return;
		}

		setSelectedEntity(estado);
		setMunicipalityOptions([]);
		setSelectedMunicipality(null);
		setLoadingMunicipalities(true);

		const mappedMunicipalities = await axiosInstance({
			method: 'GET',
			url: `/municipality/?no_paginate=true&fk_local_district__fk_district__fk_entity_id=${estado.value}`
		})
			.then((res) =>
				res.data.map((mun) => {
					return {
						label: mun.nombre,
						value: mun.code
					};
				})
			)
			.catch((err) => console.log(err));

		setMunicipalityOptions(mappedMunicipalities);
		setLoadingMunicipalities(false);
	};

	const resetConditions = () => {
		setSelectedGender([]);
		setSelectedEntity(null);
		setSelectedMunicipality(null);
		setSelectedAge(initialAge);
		setHasSelectedAge(false);
		setEntitiesOptions([]);
		setMunicipalityOptions([]);
		setLoadingMunicipalities(false);
	};

	const handleSaveConditions = async () => {
		let conditions = {};

		if (selectedGender.length === 1) conditions.gender = selectedGender[0].charAt(0).toUpperCase();

		if (hasSelectedAge) {
			conditions.minimum_age = selectedAge.min;
			conditions.maximum_age = selectedAge.max;
		}

		if (selectedCondition) conditions.conditional = selectedCondition.value;

		conditions.type_question = 6;

		if (selectedEntity) conditions.state = selectedEntity.value;

		if (selectedMunicipality) conditions.municipality = selectedMunicipality.value;

		if (selectedCat) conditions.category_subcategory = selectedCat.value;

		conditions.option_conditional = conditionalQuestions.map((obj) => obj?.answer.value);

		conditions.conditionals_questions = conditionalQuestions.map((obj) => obj?.question.value);

		handleAddQuestionCondition(conditions);

		resetConditions();
	};

	const addConditionalQuestion = () => {
		if (!selectedQuestion) {
			toast.error(`Debe seleccionar una pregunta condicional para añadir otra.`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		} else {
			if (!selectedAnswer) {
				toast.error(`Debe seleccionar una respuesta para añadir otra pregunta condicional.`, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			} else {
				setConditionalQuestions((prev) => [...prev, { question: selectedQuestion, answer: selectedAnswer }]);

				setSelectedQuestion(null);
				setSelectedAnswer(null);
				setAnswersOptions([]);
			}
		}
	};

	return (
		<Modal isOpen={isOpen} toggle={toggle} size="lg">
			<ModalBody>
				<Row style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row style={{ padding: '4px 16px', gap: '12px' }}>
					<Row className="create-event-title" style={{ width: '100%' }}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
							<span style={{ color: '#d32f34', fontSize: '16px' }}>Condicionantes para pregunta</span>
							<span style={{ color: '#333', fontSize: '14px', fontWeight: 400 }}>{title}</span>
							<span style={{ color: '#333', fontSize: '12px', fontWeight: 300 }}>
								Todas las preguntas “condicionadas” por lo que se elija en esta ventana serán mostradas
								a esos electores
							</span>
						</div>
					</Row>
					<Row style={{ margin: '0 4px', width: '100%', padding: '0px 16px' }}>
						<Col lg="12" sm="12" className="p-1">
							<FormGroup check style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
								<Label>{'Sexo'}</Label>
								{genders.map((gender, index) => {
									return (
										<li key={index}>
											<Input
												type="checkbox"
												onChange={handleCheckChange}
												name={gender.value}
												value={gender.value}
												style={{ accentColor: 'rgb(236, 22, 48)' }}
												checked={selectedGender.includes(gender.value)}
											/>
											<Label check>{gender.label}</Label>
										</li>
									);
								})}
							</FormGroup>
						</Col>
					</Row>
					<Row style={{ margin: '0 4px', width: '100%', padding: '0px 16px' }}>
						<Col lg="6" sm="12" className="p-1">
							<FormGroup>
								<Label>{'Estado'}</Label>
								<Select
									noOptionsMessage={() => 'No hay opciones'}
									isSearchable
									isClearable
									name="selectedState"
									id="selectedState"
									options={entitiesOptions}
									isLoading={loadingEntities}
									onChange={handleSelectEntity}
									placeholder={'Todos los estados'}
									value={selectedEntity || ''}
									styles={colourStyles}
								/>
							</FormGroup>
						</Col>
						<Col lg="6" sm="12" className="p-1">
							<FormGroup>
								<Label>{'Municipio'}</Label>
								<Select
									noOptionsMessage={() => 'No hay opciones'}
									isSearchable
									value={selectedMunicipality}
									options={municipalityOptions}
									placeholder="Todos los municipios"
									onChange={(e) => setSelectedMunicipality(e)}
									styles={colourStyles}
									isLoading={loadingMunicipalities}
									loadingMessage={() => 'Cargando...'}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row style={{ margin: '0 4px', width: '100%', padding: '0px 16px' }}>
						<Col lg="12" sm="12" className="p-1">
							<FormGroup>
								<Label>{'Edad'}</Label>
								<InputRange
									maxValue={100}
									minValue={18}
									value={selectedAge}
									onChange={(value) => {
										setSelectedAge(value);
										setHasSelectedAge(true);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className="create-event-title" style={{ width: '100%' }}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
							<span style={{ color: '#d32f34', fontSize: '16px' }}>Añadir condicionales</span>
							<span style={{ color: '#333', fontSize: '12px', fontWeight: 300 }}>
								La pregunta será mostrada a todo elector cuyo resultado coincida con lo elegido
							</span>
						</div>
					</Row>
					<Row style={{ margin: '0 4px', width: '100%', padding: '0px 16px' }}>
						<Col lg="12">
							<Row>
								<Col lg="4" sm="12" className="p-1">
									<FormGroup className="m-0">
										<Label>{'Categoría o subcategoría'}</Label>
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											name="selectedCategory"
											id="selectedCategory"
											options={[
												{ label: 'Categorías', options: categoriesOptions },
												{ label: 'Subcategorías', options: subcategoriesOptions }
											]}
											onChange={(option) => {
												setSelectedCat(option);
											}}
											value={selectedCat}
											placeholder={'Seleccionar modificador'}
											styles={colourStyles}
										/>
									</FormGroup>
								</Col>
								<Col lg="4" sm="12" className="p-1">
									<FormGroup className="m-0">
										<Label>{'Condicional'}</Label>
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											name="selectedConditional"
											id="selectedConditional"
											options={conditionals}
											onChange={(option) => {
												setSelectedCondition(option);
											}}
											value={selectedCondition}
											placeholder={'Seleccionar condicional'}
											styles={colourStyles}
										/>
									</FormGroup>
								</Col>
								<Col lg="4" sm="12" className="p-1">
									<FormGroup className="m-0">
										<Label>{'Valor asignado'}</Label>
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											name="selectedRate"
											id="selectedRate"
											options={ratingValue}
											onChange={(option) => {
												setSelectedRate(option);
											}}
											value={selectedRate}
											placeholder={'Asignar valor'}
											styles={colourStyles}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row style={{ margin: '0 4px', width: '100%', padding: '0px 16px' }}>
						<Col lg="12">
							<Row>
								<Col lg="5" sm="12" className="p-1">
									<FormGroup className="m-0">
										<Label>{'Pregunta'}</Label>
									</FormGroup>
								</Col>
								<Col lg="5" sm="12" className="p-1">
									<FormGroup className="m-0">
										<Label>{'Respuesta'}</Label>
									</FormGroup>
								</Col>
							</Row>
						</Col>
						{conditionalQuestions.map((question, index) => {
							return (
								<Col lg="12">
									<Row>
										<Col lg="5" sm="12" className="p-1">
											<span
												style={{
													color: '#333',
													fontSize: '14px',
													fontWeight: '400',
													lineHeight: 'normal'
												}}
											>
												{question.question.label}
											</span>
										</Col>
										<Col lg="5" sm="12" className="p-1">
											<span
												style={{
													color: '#333',
													fontSize: '14px',
													fontWeight: '400',
													lineHeight: 'normal'
												}}
											>
												{question.answer.label}
											</span>
										</Col>
										<Col lg="2" className="p-1">
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'flex-end',
													alignItems: 'center'
												}}
											>
												<Minus
													size={24}
													color="#d32f34"
													style={{ cursor: 'pointer' }}
													onClick={() => {
														const auxConditionals = conditionalQuestions.filter(
															(_, idx) => idx !== index
														);

														setConditionalQuestions(auxConditionals);
													}}
												/>
											</div>
										</Col>
									</Row>
								</Col>
							);
						})}
						<Col lg="12">
							<Row>
								<Col lg="5" sm="12" className="p-1">
									<FormGroup className="m-0">
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											isSearchable
											isClearable
											name="selectedSimpleQuestion"
											id="selectedSimpleQuestion"
											options={allQuestions.map((question) => ({
												label: question.question,
												value: question.id,
												answers: question.answers
											}))}
											placeholder={'Seleccione una pregunta'}
											value={selectedQuestion}
											onChange={(option) => {
												setSelectedQuestion(option);

												setSelectedAnswer(null);

												if (option) {
													setAnswersOptions(
														option.answers.map((ans) => ({
															label: ans.option,
															value: ans.id
														}))
													);
												} else {
													setAnswersOptions([]);
												}
											}}
											styles={colourStyles}
										/>
									</FormGroup>
								</Col>
								<Col lg="5" sm="12" className="p-1">
									<FormGroup className="m-0">
										<Select
											noOptionsMessage={() => 'No hay opciones'}
											isSearchable
											isClearable
											value={selectedAnswer}
											options={answersOptions}
											placeholder="Seleccione una respuesta"
											onChange={(e) => setSelectedAnswer(e)}
											styles={colourStyles}
											loadingMessage={() => 'Cargando...'}
										/>
									</FormGroup>
								</Col>
								<Col lg="2" className="p-1">
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-end',
											alignItems: 'center',
											height: '100%'
										}}
									>
										<Save
											size={24}
											color="#d32f34"
											style={{ cursor: 'pointer' }}
											onClick={addConditionalQuestion}
										/>
									</div>
								</Col>
							</Row>
							<Row style={{ justifyContent: 'flex-start' }}>
								<div style={{ cursor: 'pointer' }} onClick={addConditionalQuestion}>
									<Plus size={12} color="#d32f34" />{' '}
									<span style={{ fontSize: '12px', color: '#d32f34', fontWeight: 400 }}>
										Añadir otro
									</span>
								</div>
							</Row>
						</Col>
					</Row>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button outline color="light-2x txt-dark" onClick={resetConditions}>
					Restablecer
				</Button>
				<Button color="primary" onClick={handleSaveConditions}>
					Aplicar filtros
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default CondicionantePregunta;
