import React, { useEffect, useState } from 'react';
import { Container, Row, Col, TabPane } from 'reactstrap';
import './pyramidLevels.scss';

import InternalLoader from 'layout/internal-loader';
import Tab from 'components/tab/Tab.';
import TabContentContainer from 'components/tab/TabContent';
import { useTab } from 'components/tab/useTab';

import AssignUserToLevel from './AssignUserToLevelForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useStructure } from 'utils/fetch/pyramids';
import { useLevels } from 'utils/fetch/pyramidLevels';
import { axiosInstance } from 'utils/axiosInstance';
import LoadingAffiliation from 'components/dashboard/CustomLoader/CustomLoader';
import { ToastContainer, toast } from 'react-toastify';
import * as ROUTES from '../../routes';

export default function PyramidAffiliates() {
	const { pyramidId } = useParams();
	const { loading } = useStructure(pyramidId);
	const { data: levelsByStructure, loading: loadingLevels } = useLevels(pyramidId, { toObject: true });

	const [allSelectedUsers, setAllSelectedUsers] = useState([]);

	const [levelsAffiliates, setlevelsAffiliates] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (levelsByStructure) {
			const l = Object.values(levelsByStructure).reduce((obj, item) => {
				const i = item.affiliates?.map((aff) => {
					const {
						data_affiliate: { first_name, last_name, id_vote, electoral_key },
						id
					} = aff;

					return {
						label: `${first_name} ${last_name}`,
						value: id_vote,
						electoralKey: electoral_key,
						remId: id
					};
				});

				return {
					...obj,
					[item.id]: i
				};
			}, {});

			setlevelsAffiliates(l);

			const affiliatesByLevel = Object.values(levelsByStructure)
				.flatMap((level) => {
					return level.affiliates;
				})
				.map(({ data_affiliate: { id_vote } }) => id_vote);

			setAllSelectedUsers(affiliatesByLevel);
		}
	}, [levelsByStructure]);

	const levels = Object.values(levelsByStructure || {})
		?.map((level, idx) => ({
			key: level.id,
			label: `Nivel ${idx + 1}`,
			recruiter: level.recruiter
		}))
		.filter((item, index) => index !== 0);

	const { activeTab, handleChangeTab } = useTab(levels?.[0]?.key, { replaceUrl: false });

	if (loading || loadingLevels || levelsAffiliates.length === 0) {
		return <InternalLoader />;
	}

	const handleChangeSelectedAffiliated = (levelId, selected) => {
		let newLevelsAffiliates = { ...levelsAffiliates };

		newLevelsAffiliates[levelId] = selected;

		setAllSelectedUsers(
			Object.values(newLevelsAffiliates)
				.flat()
				.map(({ value }) => value)
		);

		setlevelsAffiliates(newLevelsAffiliates);
	};

	const handleSaveAffiliates = async (levelId, affiliatesToAdd, affiliatesToRemove) => {
		setIsLoading(true);

		try {
			const addAffId = affiliatesToAdd.map(({ value }) => value);
			const removeAffId = affiliatesToRemove.map((value) => value);

			if (removeAffId.length > 0) {
				removeAffId.forEach((affId) => {
					axiosInstance({
						url: `/activism/associate-affiliates/${affId}/`,
						method: 'DELETE'
					})
						.then((res) => {
							toast.success('Se han eliminado usuarios de este nivel', {
								position: 'top-right',
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: 'colored'
							});
						})
						.catch((error) => {
							toast.error('Error al eliminar usuarios', {
								position: 'top-right',
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: 'colored'
							});
						});
				});
			}

			if (addAffId.length > 0) {
				axiosInstance({
					url: `/activism/associate-affiliates/`,
					method: 'POST',
					data: {
						fk_affiliate: addAffId,
						fk_level: levelId
					}
				})
					.then((res) => {
						toast.success('Se han asignado los usuarios a este nivel', {
							position: 'top-right',
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored'
						});
						navigate(ROUTES.PYRAMIDS);
					})
					.catch(({ response }) => {
						toast.error(response.data.non_field_errors[0], {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored'
						});
					});
				//.finally(() => setIsLoading(false));
			}

			if (addAffId.length === 0 || removeAffId.length === 0) {
				toast.success('Se han guardado con existo este nivel', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			}
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className="create-pyramid-page">
				<Container fluid>
					<Row className="">
						<Col className="mr-auto">
							<h3>Crear pirámide</h3>
						</Col>
						<Col sm="auto" className="ml-auto">
							<h3 className="font-primary">Paso 3 de 3</h3>
						</Col>
					</Row>

					<div className="containerForm">
						<Row>
							<Col sm="12" xs="12">
								<h4 className="title">Asignar usuarios por nivel</h4>
							</Col>
						</Row>
						<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={levels} small gray />
						<div className="pt-3">
							<TabContentContainer activeTab={activeTab} noPadding>
								{levels?.map(({ key }) => {
									return (
										<TabPane tabId={key} key={key}>
											<AssignUserToLevel
												levelData={levelsByStructure?.[key]}
												handleSaveAffiliates={handleSaveAffiliates}
												levelAffiliates={levelsAffiliates[key]}
												handleChangeSelectedAffiliated={handleChangeSelectedAffiliated}
												allSelectedUsers={allSelectedUsers}
											/>
										</TabPane>
									);
								})}
							</TabContentContainer>
						</div>
					</div>
				</Container>
			</div>
			<LoadingAffiliation isOpen={isLoading} />
			<ToastContainer />
		</>
	);
}
