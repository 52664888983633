import React from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { X } from 'react-feather';
import InputRange from 'react-input-range';
import DatePicker from 'react-date-picker';
import { Calendar } from 'react-feather';
//import 'react-input-range/lib/css/index.css';

const orderByOptions = [
	{ label: 'Nombre A-Z', value: 'aToZ' },
	{ label: 'Nombre Z-A', value: 'zToA' },
	{ label: 'Mayor Edad', value: 'majorToMinor' },
	{ label: 'Menor Edad', value: 'minorToMajor' }
];

const genders = [
	{ label: 'Masculino', value: 'Male' },
	{ label: 'Femenino', value: 'Female' }
];

const registerType = [
	{ label: 'Manual', value: 'MANUAL' },
	{ label: 'Escaneo', value: 'OCR' }
];

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const FilterElectorsModal = ({ isOpen, toggle, filterStates, filterOptions, filterHandlers }) => {
	const {
		selectedOrder,
		setSelectedOrder,
		selectedGender,
		setSelectedGender,
		selectedMilitancy,
		setSelectedMilitancy,
		selectedState,
		setSelectedState,
		selectedSection,
		setSelectedSection,
		selectedAge,
		setSelectedAge,
		selectedDate,
		setSelectedDate,
		selectedMunicipality,
		setSelectedMunicipality,
		selectedLocaleDistrict,
		setSelectedLocaleDistrict,
		selectedFederalDistrict,
		setSelectedFederalDistrict,
		initialAge,
		setHasSelectedAge,
		setHasSelectedDate,
		selectedRegisterType,
		setSelectedRegisterType,
		selectedTypeAffiliate,
		setSelectedTypeAffiliate,
		selectedSpecialGroup,
		setSelectedSpecialGroup
	} = filterStates;

	const {
		loadingSections,
		entitiesOptions,
		localeDistrictsOptions,
		federalDistrictsOptions,
		municipalityOptions,
		politicalPartiesOptions,
		politicalPartiesLoading,
		sectionsOptions,
		typeAffiliateOptions,
		typeAffiliateLoading,
		specialGroupOptions,
		specialGroupsLoading
	} = filterOptions;

	const { handleApplyFilter, handleSelectedStateChange } = filterHandlers;

	return (
		<Modal isOpen={isOpen} toggle={toggle} centered>
			<ModalBody className="electors-filter-modal">
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Ordenar por'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedOrder"
								id="selectedOrder"
								options={orderByOptions}
								onChange={(option) => {
									setSelectedOrder(option);
								}}
								placeholder={orderByOptions[0].label}
								value={selectedOrder}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Sexo'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								isMulti
								name="selectedGender"
								id="selectedGender"
								options={genders}
								onChange={(option) => {
									setSelectedGender(option);
								}}
								placeholder={'Sexo'}
								value={selectedGender || ''}
								styles={colourStyles}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Militancia'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								name="selectedMilitancy"
								id="selectedMilitancy"
								options={politicalPartiesOptions}
								onChange={(option) => {
									setSelectedMilitancy(option);
								}}
								placeholder={'Todas las militancias'}
								value={selectedMilitancy || ''}
								styles={colourStyles}
								isLoading={politicalPartiesLoading}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Estado'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isSearchable
								isClearable
								name="selectedState"
								id="selectedState"
								options={entitiesOptions}
								onChange={handleSelectedStateChange}
								placeholder={'Todos los estados'}
								value={selectedState || ''}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'D. Federal'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedFederalDistrict}
								closeMenuOnSelect={false}
								options={federalDistrictsOptions}
								placeholder="Todas los D. Federales"
								onChange={(e) => setSelectedFederalDistrict(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'D. Local'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedLocaleDistrict}
								closeMenuOnSelect={false}
								options={localeDistrictsOptions}
								placeholder="Todas los D. Locales"
								onChange={(e) => setSelectedLocaleDistrict(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Municipio'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedMunicipality}
								closeMenuOnSelect={false}
								options={municipalityOptions}
								placeholder="Todos los municipios"
								onChange={(e) => setSelectedMunicipality(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Sección'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedSection}
								closeMenuOnSelect={false}
								options={sectionsOptions}
								placeholder="Todas las secciones"
								onChange={(e) => setSelectedSection(e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="12" sm="12">
						<FormGroup>
							<Label>{'Edad'}</Label>
							<InputRange
								maxValue={100}
								minValue={16}
								value={selectedAge}
								onChange={(value) => {
									setSelectedAge(value);
									setHasSelectedAge(true);
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Fecha de registro'}</Label>
							<DatePicker
								value={selectedDate}
								onChange={(value) => {
									setSelectedDate(value);
									setHasSelectedDate(true);
								}}
								clearIcon={false}
								format="dd/MM/yyyy"
								calendarIcon={<Calendar size={16} stroke="#d32f34" />}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Tipo de Registro'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isClearable
								isMulti
								name="selectedRegisterType"
								id="selectedRegisterType"
								options={registerType}
								onChange={(option) => {
									setSelectedRegisterType(option);
								}}
								placeholder={'Tipo de registro'}
								value={selectedRegisterType || ''}
								styles={colourStyles}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Tipo de simpatizante'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isSearchable
								value={selectedTypeAffiliate}
								closeMenuOnSelect={false}
								options={typeAffiliateOptions}
								placeholder="Todos los simpatizantes"
								onChange={(e) => setSelectedTypeAffiliate(e)}
								styles={colourStyles}
								isLoading={typeAffiliateLoading}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>

					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Grupos especiales'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isSearchable
								value={selectedSpecialGroup}
								closeMenuOnSelect={false}
								options={specialGroupOptions}
								placeholder="Todos los grupos"
								onChange={(e) => setSelectedSpecialGroup(e)}
								styles={colourStyles}
								isLoading={specialGroupsLoading}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={() => {
						/**
						 * Clears parameter of filter
						 */
						setSelectedOrder(null);
						setSelectedGender([]);
						setSelectedMilitancy();
						setSelectedState();
						setSelectedLocaleDistrict([]);
						setSelectedFederalDistrict([]);
						setSelectedMunicipality([]);
						setSelectedSection([]);
						setSelectedDate(new Date());
						setHasSelectedDate(false);
						setSelectedAge(initialAge);
						setHasSelectedAge(false);

						/**
						 * Clears multiselect options
						 */
						localeDistrictsOptions([]);
						federalDistrictsOptions([]);
						municipalityOptions([]);
						sectionsOptions([]);
					}}
					disabled={loadingSections}
				>
					Restablecer
				</Button>{' '}
				<Button color="primary" onClick={() => handleApplyFilter()} disabled={loadingSections}>
					Aplicar Filtro
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default FilterElectorsModal;
