import React, {useState, useReducer, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Card,
    Button,
    Alert,
    CardBody,
    CardHeader
} from 'reactstrap';
import {useNavigate, useLocation} from "react-router-dom";
import Breadcrumb from '../../../layout/breadcrumb';
import {Password, EmailAddress, ConfirmPassword, SelectRole} from '../../../constant';
import {CircularProgress} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {editUser, fetchCleanEditUserState} from '../../../redux/actions';
import userImage from '../../../assets/images/avtar/3.jpg';
import {ACCOUNTS_LIST} from '../../../routes';
import Select from "react-select";
import {ROLE_VALUES} from "../../../constant/roles-object";

const EditAccount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const account = location.state?.account;
    const [togglePassword, setTogglePassword] = useState(false);

    console.log(account)
    const [userForm, setUserForm] = useReducer((state, newState) => ({...state, ...newState}), {
        email: account?.email,
        name: account?.name,
        password: '',
        confirmPassword: '',
        roles: account?.role.split(','),
        picture: account?.picture,
        blocked: account?.status === 'Activo' ? false : true
    });

    const [errorForm, setErrorForm] = useReducer((state, newState) => ({...state, ...newState}), {
        nameError: false,
        usernameError: false,
        passwordError: false,
        confirmPasswordError: false
    });

    const loading = useSelector((state) => state.UI.isLoading);
    const editUserError = useSelector((state) => state.User.editUserError);
    const editUserSucceded = useSelector((state) => state.User.editUserSucceded);

    useEffect(() => {
        dispatch(fetchCleanEditUserState());
    }, [dispatch]);

    useEffect(() => {
        if (editUserSucceded) {
            dispatch(fetchCleanEditUserState());
            navigate(ACCOUNTS_LIST, {refresh: true});
        }
    }, [editUserSucceded, navigate, dispatch]);

    const selectPicture = (event) => {
        const fileReader = new FileReader();
        if (event.target.files.length > 0) {
            const photo = event.target.files[0];
            const file = photo;

            fileReader.onload = () => {
                const img = new Image();
                const dataURL = fileReader.result;
                img.src = dataURL;

                img.onload = () => {
                    setUserForm({
                        picture: fileReader.result
                    });
                };
            };
            fileReader.readAsDataURL(file);
        }
    };

    const handleBlockUser = (event) => {
        const checked = event.target.checked;

        setUserForm({
            blocked: checked
        });
    };

    const deEditUser = () => {
        const inputData = processInputData();

        dispatch(
            editUser({
                _id: account?.user_id,
                record: {
                    ...inputData
                }
            })
        );
    };

    const handleRoles = (value) => {

        let rolesArr;
        let adminRole = value.filter(element => element.value.toLocaleLowerCase() === 'admin');

        rolesArr = value.map(role => {
            return role.value;
        })

        if (adminRole && adminRole.length > 0) {
            rolesArr = ['ADMIN']
        }

        console.log(rolesArr)

        setUserForm({roles: rolesArr})
    }

    const processInputData = () => {
        const hasNewPicture = userForm.picture.includes('base64');
        let formStateKeys = Object.keys(userForm);

        if (!hasNewPicture) {
            formStateKeys = formStateKeys.filter((key) => key !== 'picture');
        }

        let inputData = {};

        formStateKeys.forEach((key) => {
            const value = userForm[key];
            if (value || value === false) {
                if (key === 'roles') {
                    inputData['app_metadata'] = {};
                    inputData['app_metadata']['roles'] = value;
                } else {
                    if (key.toLocaleLowerCase() !== 'confirmPassword'.toLocaleLowerCase()) {
                        inputData[key] = value;
                    }
                }
            }
        });
        return inputData;
    };

    return (
        <Container fluid={true}>
            <Breadcrumb parent="Configuración" title="Editar Usuario" isAccount={true}/>
            <Row>
                <Col sm={{size: 8, offset: 2}}>
                    <Card>
                        <CardHeader>
                            <span>Editar usuario</span>
                        </CardHeader>
                        <CardBody>
                            <div
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: 20,
                                    minHeight: '80vh',
                                    marginTop: 5,
                                    marginBottom: 5
                                }}
                            >
                                <Form className="theme-form">
                                    <FormGroup>
                                        <Label className="col-form-label">Imagen de usuario</Label>
                                        <label
                                            htmlFor="avatar-picture"
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <img
                                                src={userForm.picture === '' ? userImage : userForm.picture}
                                                alt="Generic placeholder"
                                                height={100}
                                                width={100}
                                                style={{cursor: 'pointer'}}
                                            />
                                        </label>
                                        <input
                                            type="file"
                                            id="avatar-picture"
                                            style={{display: 'none'}}
                                            onChange={selectPicture}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="col-form-label">{EmailAddress}</Label>
                                        <Input
                                            className="form-control"
                                            type="email"
                                            disabled
                                            onChange={null}
                                            defaultValue={userForm.email}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="col-form-label">Nombre y Apellido</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            required=""
                                            onChange={(e) => setUserForm({name: e.target.value})}
                                            value={userForm.name}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="col-form-label">{Password}</Label>
                                        <Input
                                            className="form-control"
                                            type={togglePassword ? 'text' : 'password'}
                                            onChange={(e) => {
                                                setUserForm({password: e.target.value});
                                                setErrorForm({passwordError: false});
                                            }}
                                            value={userForm.password}
                                            required=""
                                        />
                                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                            <span className={togglePassword ? '' : 'mostrar'}></span>
                                        </div>
                                        {userForm.password.length > 0 && userForm.password.length < 6 && (
                                            <span className="text-danger input-error">
												La contraseña debe tener al menos 8 caracteres, entre mayúsculas,
												números y caracteres especiales.
											</span>
                                        )}
                                        {errorForm.passwordError && (
                                            <span className="text-danger input-error">
												Por favor ingrese una contraseña.
											</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="col-form-label">{ConfirmPassword}</Label>
                                        <Input
                                            className="form-control"
                                            type={togglePassword ? 'text' : 'password'}
                                            onChange={(e) => {
                                                setUserForm({confirmPassword: e.target.value});
                                                setErrorForm({confirmPasswordError: false});
                                            }}
                                            value={userForm.confirmPassword}
                                            required=""
                                        />
                                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                            <span className={togglePassword ? '' : 'mostrar'}></span>
                                        </div>
                                        {userForm.confirmPassword.length > 0 &&
                                            userForm.password !== userForm.confirmPassword && (
                                                <span className="text-danger input-error">
													Las contraseñas deben ser iguales.
												</span>
                                            )}
                                        {errorForm.confirmPasswordError && (
                                            <span className="text-danger input-error">
												Por favor confirme la contraseña.
											</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="select-role">{SelectRole}</Label>
                                        <Select
                                            noOptionsMessage={() => 'No hay opciones'}
                                            value={userForm.roles.map(role => {
                                                return {
                                                    label: role,
                                                    value: role
                                                }
                                            })}
                                            isMulti
                                            isSearchable
                                            closeMenuOnSelect={false}
                                            options={ROLE_VALUES}
                                            onChange={(e) => handleRoles(e)}
                                            placeholder="Roles para asignar"
                                        />
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                onChange={handleBlockUser}
                                                checked={userForm.blocked}
                                            />
                                            Desactivar usuario
                                        </Label>
                                    </FormGroup>
                                    <br/>
                                    <div className="form-group mb-0">
                                        <Button color="primary" className="btn-block" onClick={deEditUser}>
                                            Editar Usuario
                                        </Button>
                                        {!loading && (
                                            <Button
                                                outline
                                                color="secondary"
                                                className="btn-block"
                                                onClick={() => navigate(ACCOUNTS_LIST)}
                                            >
                                                Cancelar
                                            </Button>
                                        )}
                                        {loading && (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    marginTop: '5%',
                                                    justifyContent: 'center',
                                                    display: 'flex'
                                                }}
                                            >
                                                <CircularProgress style={{color: '#f0c50d'}}/>
                                            </div>
                                        )}
                                        {editUserError && (
                                            <Alert style={{marginTop: '2em'}} color="danger">
                                                {editUserError}
                                            </Alert>
                                        )}
                                        {editUserSucceded && (
                                            <Alert style={{marginTop: '2em'}} color="success">
                                                Usuario editado satisfactoriamente
                                            </Alert>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default EditAccount;
