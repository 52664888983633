import React, { Fragment, useEffect, useReducer } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import {
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	Alert
} from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import InternalLoader from '../../../layout/internal-loader';
import { useDispatch, useSelector } from 'react-redux';
import { CompactPicker } from 'react-color';
import { getNeeds, createNeed, editNeed } from '../../../redux/actions';

const model = [
	{ id: 'id', name: 'Id' },
	{ id: 'name', name: 'Necesidad' },
	{ id: 'icon', name: 'Nombre del icono (Font-Awesome)' },
	{ id: 'color', name: 'Color' },
	{ id: 'status', name: 'Estado' },
	{ id: 'createdAt', name: 'Fecha de creación' },
	{ id: 'edit', name: 'Editar' }
];

const STATUSES = ['Activa', 'Inactiva'];

const Needs = () => {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.UI.isLoading);
	const metrics = useSelector((state) => state.Metrics);
	const needs = useSelector((state) => state.Metrics.needsList);

	const [needForm, setNeedForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
		id: '',
		label: '',
		iconName: '',
		color: '#000000',
		active: [STATUSES[0]]
	});

	useEffect(() => {
		dispatch(getNeeds());
	}, [dispatch]);

	useEffect(() => {
		if (metrics.createNeedSuccess) {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		}
	}, [metrics.createNeedSuccess]);

	function getUsersTableHeaders(model) {
		return model.map((x) => {
			if (x.id !== 'edit') {
				if (x.id === 'id') {
					return {
						omit: true
					};
				} else {
					return {
						name: x.name,
						selector: (row) => row[x.id],
						sortable: true,
						center: true
					};
				}
			} else {
				return {
					name: 'Editar',
					cell: (item) => {
						return (
							<Button color="primary" size="sm" onClick={() => editNeedForm(item)}>
								Editar
							</Button>
						);
					}
				};
			}
		});
	}

	const doCreateNeed = () => {
		if (needForm.label !== '' && needForm.iconName !== '') {
			dispatch(
				createNeed({
					record: {
						name: needForm.label,
						label: needForm.label,
						color: needForm.color,
						icon: needForm.iconName,
						active: needForm.active === 'Activa' ? true : false
					}
				})
			);
		}
	};

	const editNeedForm = (item) => {
		const status = STATUSES.find((status) => status === item.status);
		setNeedForm({
			label: item.label,
			iconName: item.icon,
			color: item.color,
			active: [status],
			id: item.id
		});

		window.scrollTo(0, 0);
	};

	const doEditNeed = () => {
		dispatch(
			editNeed({
				_id: needForm.id,
				record: {
					name: needForm.label,
					label: needForm.label,
					color: needForm.color,
					icon: needForm.iconName,
					active: needForm.active[0] === 'Activa' ? true : false
				}
			})
		);
	};

	const cancelEdition = () => {
		setNeedForm({
			id: '',
			label: '',
			iconName: '',
			color: '#000000',
			active: [STATUSES[0]]
		});
	};

	const needsData = needs?.map((need) => {
		const status = need.active ? 'Activa' : 'Inactiva';

		return {
			id: need?._id,
			name: need?.name,
			label: need?.label,
			icon: need?.icon,
			createdAt: moment(need?.createdAt).format('DD-MM-YYYY'),
			color: need?.color,
			status: status
		};
	});

	return (
		<Fragment>
			<Breadcrumb parent="Configuración" title="Opciones Necesidades" isAccount={true} />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<Form className="theme-form d-flex justify-content-around p-2">
								<Row>
									<Col xs="12" lg="3">
										<FormGroup>
											<Label className="col-form-label">
												Necesidad <span className="text-danger">*</span>
											</Label>
											<Input
												className="form-control"
												type="text"
												required=""
												onChange={(e) => {
													setNeedForm({
														label: e.target.value.toUpperCase().trim()
													});
												}}
												value={needForm.label}
												placeholder="ej: SALUD"
											/>
										</FormGroup>
									</Col>
									<Col xs="12" lg="3">
										<FormGroup>
											<Label className="col-form-label">
												Nombre del Icono <span className="text-danger">*</span>
											</Label>
											<Input
												className="form-control"
												type="text"
												onChange={(e) => {
													setNeedForm({
														iconName: e.target.value.trim()
													});
												}}
												value={needForm.iconName}
												placeholder="ej: hospital"
											/>
											<a
												href="https://fontawesome.com/icons"
												target="_blank"
												rel="noreferrer"
												style={{ fontSize: 12 }}
											>
												Usar solo nombre del icono de Font Awesome Icons (ver lista):
											</a>
										</FormGroup>
									</Col>
									<Col xs="12" lg="4">
										<FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
											<Label className="col-form-label">
												Color Hexadecimal<span className="text-danger">*</span>
											</Label>
											<CompactPicker
												color={needForm.color}
												disableAlpha={true}
												onChange={(color, event) => setNeedForm({ color: color.hex })}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" lg="2">
										<FormGroup>
											<Label className="col-form-label">Estado</Label>
											<Input
												type="select"
												name="select-status"
												id="select-status"
												value={needForm.active[0]}
												onChange={(e) =>
													setNeedForm({
														active: [e.target.value]
													})
												}
											>
												{STATUSES.map((status, index) => {
													return <option key={index}>{status}</option>;
												})}
											</Input>
										</FormGroup>
									</Col>
									<Col sm={{ size: 4, offset: 4 }} className="pt-2 pb-2">
										{needForm.id === '' && (
											<Button color="primary" className="btn-block" onClick={doCreateNeed}>
												Crear Necesidad
											</Button>
										)}
										{needForm.id !== '' && (
											<Button color="primary" className="btn-block" onClick={doEditNeed}>
												Editar Necesidad
											</Button>
										)}
										{metrics.loadingCreateNeed && (
											<div className="d-flex justify-content-center m-4">
												<CircularProgress style={{ color: '#f0c50d' }} />
											</div>
										)}
										{metrics.createNeedError && (
											<Alert style={{ marginTop: '2em' }} color="danger">
												{metrics.createNeedError}
											</Alert>
										)}
										{metrics.createNeedSuccess && (
											<Alert style={{ marginTop: '2em' }} color="success">
												Necesidad creada satisfactoriamente
											</Alert>
										)}
									</Col>
									{needForm.id !== '' && (
										<Col sm={{ size: 4, offset: 4 }} className="pt-2 pb-2">
											<Button color="danger" className="btn-block" onClick={cancelEdition}>
												Cancelar Edición
											</Button>
										</Col>
									)}
								</Row>
							</Form>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<span>Lista de metas</span>
							</CardHeader>
							<CardBody>
								<div className="table-responsive product-table">
									<DataTable
										noHeader
										columns={getUsersTableHeaders(model)}
										data={needsData}
										progressPending={loading}
										progressComponent={<InternalLoader />}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Needs;
