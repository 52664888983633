import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, TabContent, TabPane, Alert } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import * as ROUTES from '../routes/index';

import './login2fa.scss';
// eslint-disable-next-line
import LOGO_BRAND, { LOGO_PSI } from 'brand';
import { ChevronLeft } from 'react-feather';
import { verify2FACode } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { removeLocalStorageItem } from 'services/localStorageService';
import { TOKEN_DATA, USER_DATA, USES_2FA } from 'constant/localStorage';

const CodeInput = forwardRef((props, ref) => {
	const { value, onKeyDown, index, onChange, onPaste } = props;
	const handleChange = (e) => {
		const pattern = /^\d$/;
		const newValue = e.target.value;
		if (newValue.length !== 0 && !pattern.test(newValue)) {
			return;
		}
		onChange(newValue, index);
	};

	const handleKeyDown = (e) => {
		onKeyDown(e, index);
	};

	const handlePaste = (e) => {
		onPaste(e.clipboardData.getData('text'));
	};

	return (
		<input
			ref={ref}
			value={value}
			className="code-input"
			type="text"
			inputMode="numeric"
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			maxLength={1}
			onPaste={handlePaste}
		/>
	);
});

const initialCode = new Array(6).fill('');

const Login2FA = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();
	const { state } = location;

	const token = useSelector((state) => state.User.token);
	const verification2FASucess = useSelector((state) => state.User.verify2FACodeSuccesfull);
	const verification2FAError = useSelector((state) => state.User.verify2FACodeError);
	const loading = useSelector((state) => state.UI.isLoading);

	const [codes, setCodes] = useState(initialCode);

	const refs = useRef([]);

	useEffect(() => {
		refs.current[0].focus();
	}, []);

	const handleKeyDown = (e, index) => {
		if (e.key === 'Backspace' && index - 1 >= 0) {
			e.preventDefault();
			const newCodes = [...codes.slice(0, index), '', ...codes.slice(index + 1)];
			setCodes(newCodes);
			refs.current[index - 1].focus();
		}
		if (e.key === 'Delete' && index - 1 >= 0) {
			e.preventDefault();
			const newCodes = [...codes.slice(0, index), '', ...codes.slice(index + 1)];
			setCodes(newCodes);
		}

		if (e.key === 'ArrowLeft' && index - 1 >= 0) {
			refs.current[index - 1].focus();
		}

		if (e.key === 'ArrowRight' && index + 1 < codes.length) {
			refs.current[index + 1].focus();
		}
	};

	const handlePaste = (pastedValue) => {
		setCodes((prev) => prev.map((_, index) => pastedValue[index] || ''));

		if (pastedValue.length === 6) {
			dispatch(verify2FACode({ code: pastedValue.toString(), token, isLogin: true }));
		}
	};

	const handleCodeChange = (newCode, index) => {
		const newCodes = [...codes.slice(0, index), newCode, ...codes.slice(index + 1)];
		setCodes(newCodes);
		if (newCode.length > 0 && index + 1 < codes.length) {
			refs.current[index + 1].focus();
		}

		if (newCodes.every((item) => item !== '')) {
			dispatch(verify2FACode({ code: newCodes.join('').toString(), token, isLogin: true }));
		}
	};

	useEffect(() => {
		if (verification2FASucess) {
			navigate(ROUTES.INITIAL, { replace: true, state: { refresh: true } });
		}
	}, [verification2FASucess, navigate]);

	return (
		<Container fluid={true} className="p-0">
			<Row>
				<Col xs="12">
					<div className="login-card">
						<div className="login-main login-tab">
							<TabContent activeTab={'code'} className="content-login">
								<TabPane className="fade show" tabId={'code'}>
									<Form>
										<div
											className="d-flex flex-row justify-content-start align-items-center"
											style={{ gap: '8px', marginBottom: '16px' }}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													cursor: 'pointer'
												}}
												onClick={() => {
													removeLocalStorageItem(TOKEN_DATA);
													removeLocalStorageItem(USER_DATA);
													removeLocalStorageItem(USES_2FA);
													navigate(ROUTES.LOGIN);
												}}
											>
												<ChevronLeft size={16} color="#333333" />
												<span style={{ color: '#333333' }}>Volver</span>
											</div>
										</div>
										<div
											className="d-flex flex-row justify-content-center align-items-center"
											style={{ gap: '8px', marginBottom: '48px' }}
										>
											<div className="d-flex flex-column justify-content-center align-items-center">
												<div className="d-flex flex-row justify-content-center mb-2">
													<img
														className="img-fluid for-light"
														src={LOGO_PSI}
														alt="logo"
														width={40}
														height={40}
													/>
												</div>
												<div className="text-center font-weight-bold">
													{state?.username || '-'}
												</div>
											</div>
										</div>
										<div
											className="d-flex flex-row justify-content-center align-items-center mb-4"
											style={{ gap: '4px' }}
										>
											<div
												className="d-flex flex-column"
												style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
											>
												<p
													style={{
														fontSize: '24px',
														color: '#333333',
														width: '100%',
														textAlign: 'center',
														margin: 0
													}}
												>
													Autentifica tu inicio de sesión
												</p>
												<p
													style={{
														fontSize: '14px',
														color: '#AFAFAF',
														width: '100%',
														textAlign: 'center',
														margin: 0
													}}
												>
													Usa tu generador de códigos para generar un código 2FA e ingrésalo
													en el siguiente campo
												</p>
											</div>
										</div>
										<div style={{ marginBottom: '36px' }}>
											<div className="code-input-container">
												{codes.map((code, index) => (
													<CodeInput
														value={codes[index]}
														ref={(component) => {
															refs.current[index] = component;
														}}
														key={index}
														index={index}
														onKeyDown={handleKeyDown}
														onChange={handleCodeChange}
														onPaste={handlePaste}
													/>
												))}
											</div>
										</div>
										<div className="form-group mb-0">
											{loading && (
												<div
													style={{
														width: '100%',
														marginTop: '5%',
														justifyContent: 'center',
														display: 'flex'
													}}
												>
													<CircularProgress style={{ color: '#f0c50d' }} />
												</div>
											)}
											{verification2FAError && !loading && codes.every((i) => i !== '') && (
												<Alert style={{ marginTop: '2em' }} color="danger">
													Código incorrecto
												</Alert>
											)}
										</div>
									</Form>
								</TabPane>
							</TabContent>
						</div>
						<p style={{ color: '#AFAFAF', fontFamily: 'Rubik', fontSize: '14px', fontWeight: 400 }}>
							Valifai 2024
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Login2FA;
