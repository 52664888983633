import Breadcrumbs from 'layout/breadcrumb';
import InternalLoader from 'layout/internal-loader';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { customStyles } from '../electores/tableStyles';
import { ChevronRight, Slash } from 'react-feather';
import { CellAvatar, CellGender } from '../electores/Electores';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstanceV1 } from 'utils/axiosInstance';

function CellMilitancy({ srcImg }) {
	if (!srcImg) {
		return (
			<div
				style={{
					padding: '6px',
					width: '32px',
					height: '32px',
					background: '#AFAFAF',
					opacity: '0.4',
					borderRadius: '5px'
				}}
			>
				<Slash size={20} color="white" />
			</div>
		);
	} else {
		return (
			<div
				style={{
					width: '32px',
					height: '32px',
					background: '#AFAFAF',
					borderRadius: '5px'
				}}
			>
				<img src={srcImg} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="militancy" />
			</div>
		);
	}
}

async function mapVotersData(voters) {
	// console.log(voters);
	return await Promise.all(
		voters.map(async (voter) => {
			let voterObj = voter;

			let militancia = null;

			await axiosInstanceV1({
				method: 'GET',
				url: `/credentials/${voter?.curp}`
			})
				.then((res) => {
					militancia = res.data.affiliate_details ? res.data.affiliate_details.img_political_party : null;
				})
				.catch((err) => console.log(err))
				.finally(() => {
					voterObj.militancia = militancia;
				});

			return voterObj;
		})
	)
		.then((res) => res)
		.catch((err) => console.log(err));
}

const SmsDestinataries = () => {
	const navigate = useNavigate();

	const [destinatariesData, setDestinatariesData] = useState([]);

	const [loading, setLoading] = useState(false);

	const location = useLocation();
	const {
		state: { destinataries }
	} = location;

	const [currentPage, setCurrentPage] = useState(1);
	const [currentRowPerPage, setCurrentRowPerPage] = useState(10);

	const indexOfLastRecord = currentPage * currentRowPerPage;
	const indexOfFirstRecord = indexOfLastRecord - currentRowPerPage;

	useEffect(() => {
		if (destinataries.length > 0) {
			setLoading(true);

			mapVotersData(destinataries)
				.then((res) => setDestinatariesData(res))
				.finally(() => setLoading(false));
		}
	}, [destinataries]);

	const currentData = destinatariesData.slice(indexOfFirstRecord, indexOfLastRecord);

	const columns = [
		{
			name: 'Elector',
			selector: (row) => row.image,
			center: true,
			cell: (row) => (
				<CellAvatar image={row?.photo} firstName={row.firstname} lastName={row.lastname} gender={'male'} />
			),
			width: '20%'
		},
		{
			name: 'Sexo',
			center: true,
			width: '80px',
			cell: (row) => <CellGender gender={row?.gender || '-'} />
		},
		{
			name: 'Edad',
			width: '80px',
			cell: (row) => row?.age || '-',
			center: true
		},
		{
			name: 'Estado',
			center: true,
			cell: (row) => row?.state || '-'
		},
		{
			name: 'Sección Electoral',
			center: true,
			cell: (row) => row?.section || '-'
		},
		{
			name: 'Militancia',
			center: true,
			cell: (row) => <CellMilitancy srcImg={row?.militancia} />
		},
		{
			cell: (row) => (
				<ChevronRight
					color="#d32f34"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (row?.curp !== null) {
							navigate(`/electores/${row?.curp}`);
						}
					}}
				></ChevronRight>
				// <Link className="actionButton" to={row?.curp !== null ? `/electores/${row?.curp}` : ''}>
				// 	<i className="fa fa-angle-right"></i>
				// </Link>
			),
			width: '40px',
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	return (
		<Fragment>
			<Breadcrumbs title="Destinatarios" />
			<Container fluid>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader style={{ padding: '32px 40px' }}>
								<Row className="electores-header">
									<Col>
										<h5>Lista De Destinatarios </h5>
										<span
											style={{
												color: '#AFAFAF',
												fontWeight: '400',
												fontSize: '14px',
												lineHeight: '17px'
											}}
										>{`${destinataries.length} registros`}</span>
									</Col>
								</Row>
							</CardHeader>
							<CardBody className="p-0">
								<div className="table-responsive product-table">
									<DataTable
										noHeader
										customStyles={customStyles}
										columns={columns}
										data={currentData}
										progressComponent={<InternalLoader />}
										progressPending={loading}
										highlightOnHover
										paginationComponentOptions={{
											rowsPerPageText: 'Destinatarios por página:',
											rangeSeparatorText: 'de'
										}}
										noDataComponent={'No existen destinatarios para este mensaje'}
										pagination
										paginationServer
										paginationTotalRows={destinataries.length}
										onChangeRowsPerPage={(e) => setCurrentRowPerPage(e)}
										onChangePage={(e) => setCurrentPage(e)}
										paginationDefaultPage={currentPage}
										paginationPerPage={currentRowPerPage}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default SmsDestinataries;
