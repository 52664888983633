import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import DataTable from 'react-data-table-component';
import { customStyles } from 'components/dashboard/electores/tableStyles';
import { X } from 'react-feather';
import { useState } from 'react';
import { axiosInstance } from 'utils/axiosInstance';
import * as ROUTES from '../../routes';
import { useNavigate, useParams } from 'react-router-dom';

const colourStyles = {
	menu: (styles) => ({ ...styles, position: 'inherit' }),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

export default function AssignUserToLevel({
	levelData,
	handleSaveAffiliates,
	levelAffiliates,
	handleChangeSelectedAffiliated,
	allSelectedUsers
}) {
	const [affiliates, setAffiliates] = useState(levelAffiliates);
	const navigate = useNavigate();

	const [newAffiliates, setNewAffiliates] = useState([]);
	const [toDeleteAffiliates, setToDeleteAffiliates] = useState([]);

	const { pyramidId } = useParams();

	const handleFilterVoters = async (query) => {
		try {
			const { data } = await axiosInstance({
				url: `/get-affiliates/?full_name=${query}`,
				method: 'GET'
			});

			const nonSelectedAffiliates = data.results.filter(({ id }) => !allSelectedUsers.includes(id));

			return nonSelectedAffiliates.map((voter) => {
				const {
					id,
					text_detect: {
						fields: { first_name, last_name, electoral_key }
					}
				} = voter;

				return {
					label: `${first_name} ${last_name}`,
					value: id,
					electoralKey: electoral_key
				};
			});
		} catch (error) {
			return console.log(error);
		}
	};

	const handleChangeSelectedUsers = (option) => {
		handleChangeSelectedAffiliated(levelData.id, [...option, ...affiliates]);
		setToDeleteAffiliates((prev) => prev.filter((aff) => aff.value !== option.value));
		setNewAffiliates(option);
	};

	const handleDeleteAffiliatedUsers = (option) => {
		const removeId = option.value;
		const removeFromNewAffiliates = newAffiliates.filter((aff) => aff.value !== removeId);
		const removeFromAffiliates = affiliates.filter((aff) => aff.value !== removeId);

		if (option?.remId) {
			setToDeleteAffiliates((item) => [...item, option.remId]);
		}

		setNewAffiliates(removeFromNewAffiliates);
		setAffiliates(removeFromAffiliates);

		handleChangeSelectedAffiliated(levelData.id, [...removeFromNewAffiliates, ...removeFromAffiliates]);
	};

	const columns = [
		{
			name: 'Nombre',
			selector: (row) => row.label,
			width: '50%'
		},
		{
			name: 'Clave de elector',
			selector: (row) => row.electoralKey
		},
		{
			cell: (row) => (
				<>
					<X
						onClick={() => handleDeleteAffiliatedUsers(row)}
						size={16}
						color="#d32f34"
						style={{ cursor: 'pointer' }}
					/>
				</>
			),
			width: '40px',
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
			<Row>
				<Col>
					<FormGroup>
						<Label className="col-form-label">Nombre de nivel</Label>
						<AsyncSelect
							value={[...newAffiliates]}
							styles={colourStyles}
							name="pyramidAffiliatedVoters"
							placeholder="Ingresar nombre"
							valueKey="value"
							labelKey="label"
							matchPos="any"
							loadOptions={handleFilterVoters}
							hideSelectedOptions={true}
							onChange={handleChangeSelectedUsers}
							loadingMessage={() => 'Cargando...'}
							isMulti
							noOptionsMessage={() => 'Ingresar nombre'}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<DataTable
						onRowClicked
						noHeader
						customStyles={customStyles}
						columns={columns}
						data={[...affiliates, ...newAffiliates]}
						highlightOnHover={true}
						noDataComponent={'No se han asignado electores'}
					/>
				</Col>
			</Row>
			<div
				style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
			>
				<Button color="primary" outline onClick={() => navigate(`/piramides/${pyramidId}/levels/`)}>
					Volver atrás
				</Button>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'center',
						gap: '8px'
					}}
				>
					<Button outline color="light-2x txt-dark" onClick={() => navigate(ROUTES.PYRAMIDS)}>
						Cancelar
					</Button>
					<Button
						color="primary"
						onClick={() => handleSaveAffiliates(levelData.id, newAffiliates, toDeleteAffiliates)}
					>
						Crear pirámide
					</Button>
				</div>
			</div>
		</div>
	);
}
