import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Col, Container, Row } from 'reactstrap';
import { Check, Filter, Slash, X } from 'react-feather';
import InternalLoader from 'layout/internal-loader';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { CellAvatar, CellGender } from '../electores/Electores';
import { getAgeFromDate } from 'utils/getAgeFromDate';
import FilterModalEventDetails from './filter-modal-event-details';

import { useEntities } from 'utils/fetch/entities';
import { ToastContainer } from 'react-toastify';
import LoadingAffiliation from '../CustomLoader/CustomLoader';
import BasicEventDetails from './event-details';
import EventStats from './event-stats';
import ResponsiblesModal from './responsibles-modal';
import { axiosInstance, axiosInstanceV1 } from 'utils/axiosInstance';
import DetailNotFound from 'pages/detailNotFound';

const datatableCustomStyle = {
	cells: {
		style: {
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '0'
		}
	},
	rows: {
		style: {
			'&:hover': {
				backgroundColor: '#fbeaeb !important',
				'.actionButton': {
					color: '#d32f34 !important'
				}
			}
		}
	}
};

function CellSection({ isSection }) {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '24px',
				height: '24px',
				borderRadius: '50%',
				backgroundColor: isSection ? '#C5F7DD' : '#FFEFEF'
			}}
		>
			{isSection ? <Check color="#3BC279" size={16} /> : <X color="#DF1212" size={16} />}
		</div>
	);
}

function CellMilitancy({ srcImg }) {
	if (!srcImg) {
		return (
			<div
				style={{
					padding: '6px',
					width: '32px',
					height: '32px',
					background: '#AFAFAF',
					opacity: '0.4',
					borderRadius: '5px'
				}}
			>
				<Slash size={20} color="white" />
			</div>
		);
	} else {
		return (
			<div
				style={{
					width: '32px',
					height: '32px',
					background: '#AFAFAF',
					borderRadius: '5px'
				}}
			>
				<img src={srcImg} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="militancy" />
			</div>
		);
	}
}

const attendeesHeader = [
	{
		name: 'Elector',
		center: true,
		cell: (row) => (
			<CellAvatar image={row.image} firstName={row.firstName} lastName={row.lastName} gender={row.sexo} />
		),
		width: '220px'
	},
	{
		name: 'Edad',
		selector: (row) => row.age,
		center: true,
		width: '180px'
	},
	{
		name: 'Género',
		selector: (row) => row.sexo,
		center: true,
		width: '200px',
		cell: (row) => <CellGender gender={row.sexo} />
	},
	{
		name: '¿Cumple sección?',
		selector: (row) => row.isSection,
		center: true,
		width: '270px',
		cell: (row) => <CellSection isSection={row.isSection} />
	},
	{
		name: 'Militancia',
		center: true,
		selector: (row) => row.militancia,
		cell: (row) => <CellMilitancy srcImg={row.militancia} />,
		width: '180px'
	},
	{
		name: 'Fecha de validación',
		center: true,
		selector: (row) => row.validationDate,
		width: '290px'
	},
	{
		cell: (row) => (
			<Link className="actionButton" to={row.curp ? `/electores/${row.curp}` : '#'}>
				<i className="fa fa-angle-right"></i>
			</Link>
		),
		width: '40px',
		ignoreRowClick: true,
		allowOverflow: true,
		button: true
	}
];

const assistance = (assistanceData, setVotersWithCorrectSection, eventSection) => {
	return Object(assistanceData || {})?.map((voter) => {
		const { passport_photo, first_name, last_name, date_birth, gender, curp, section } =
			voter.participant.text_detect.fields;

		const { militancia } = assistanceData;

		const [dob, mob, yob] = date_birth.split('/');
		const age = getAgeFromDate(dob, mob, yob);

		const isSection = eventSection?.some((sec) => {
			if (section !== sec.section) return false;

			setVotersWithCorrectSection((prev) => prev + 1);
			return true;
		});

		const validation_date = new Date(voter.create_at).toLocaleDateString('es-ES', {
			month: 'short',
			day: '2-digit',
			hour12: true,
			hour: '2-digit',
			minute: '2-digit'
		});

		return {
			image: passport_photo,
			firstName: first_name,
			lastName: last_name,
			age,
			sexo: gender,
			isSection,
			militancia,
			validationDate: validation_date,
			curp: curp
		};
	});
};

const EventDetails = () => {
	const { eventId } = useParams();

	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const [assistanceData, setAssistanceData] = useState([]);
	const [assistanceLoading, setAssistanceLoading] = useState(false);
	const [assistanceCount, setAssistanceCount] = useState(0);

	const [mappedAssistance, setMappedAssistance] = useState([]);

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedMilitancy, setSelectedMilitancy] = useState([]);
	const [selectedValidatedBy, setSelectedValidatedBy] = useState([]);
	const [selectedSection, setSelectedSection] = useState([]);

	const initialAge = { min: 20, max: 40 };
	const [selectedAge, setSelectedAge] = useState(initialAge);
	const [hasSelectedAge, setHasSelectedAge] = useState(false);

	const fetchEventDetails = useCallback(async () => {
		setLoading(true);

		await axiosInstance({
			url: `/events/${eventId}`,
			method: 'GET'
		})
			.then(({ data }) => {
				setData(data);
			})
			.catch((err) => console.log({ err }))
			.finally(() => setLoading(false));
	}, [eventId]);

	useEffect(() => {
		fetchEventDetails();
	}, [fetchEventDetails]);

	useEffect(() => {
		axiosInstance({
			method: 'GET',
			url: `/assistance/?fk_event_id=${eventId}&page=1&size=10`
		})
			.then(async ({ data: { results, count } }) => {
				const attendees = await results.map((attendee) => {
					const curp = attendee.participant.text_detect.fields.curp;

					const { affiliate, affiliate_details } = axiosInstanceV1({
						method: 'GET',
						url: `/credentials/${curp}`
					})
						.then(({ data }) => data)
						.catch((err) => console.log(err));

					return { ...attendee, militancia: affiliate ? affiliate_details.img_political_party : null };
				});

				setAssistanceData(attendees);
				setAssistanceCount(count);
			})
			.catch((err) => console.log({ err }))
			.finally(() => setAssistanceLoading(false));
	}, [eventId]);

	const [votersWithCorrectSection, setVotersWithCorrectSection] = useState(0);

	const [filterModal, setFilterModal] = useState(false);
	const [responsablesModal, setResponsablesModal] = useState(false);

	const { loading: loadingEntities, data: entities } = useEntities();

	const [loadingEdit, setLoadingEdit] = useState(false);

	const filterStates = {
		selectedOrder,
		setSelectedOrder,
		selectedGender,
		setSelectedGender,
		selectedMilitancy,
		setSelectedMilitancy,
		selectedValidatedBy,
		setSelectedValidatedBy,
		selectedSection,
		setSelectedSection,
		selectedAge,
		setSelectedAge,
		hasSelectedAge,
		setHasSelectedAge,
		initialAge
	};

	const toggleModal = () => setFilterModal(!filterModal);
	const toggleResponsablesModal = () => setResponsablesModal(!responsablesModal);

	useEffect(() => {
		if (assistanceData && data) {
			setMappedAssistance(assistance(assistanceData, setVotersWithCorrectSection, data.sections));
		}
	}, [eventId, assistanceData, data]);

	const handleApplyFilter = () => {
		setAssistanceLoading(true);

		let url = `/assistance/?fk_event_id=${eventId}&page=${page}&size=${perPage}`;

		if (selectedOrder) {
			url +=
				selectedOrder.value === 'aToZ'
					? '&ordering=fk_participants__text_detect__fields__full_name'
					: '&ordering=-fk_participants__text_detect__fields__full_name';
		}

		if (selectedGender.length === 1) url += selectedGender[0] === 'Hombre' ? '&gender=1' : '$gender=2';

		if (selectedMilitancy.length > 0) {
			selectedMilitancy.forEach((militancy) => {
				url += `&militancy=${militancy.value}`;
			});
		}

		if (hasSelectedAge) url += `&ages_min=${selectedAge.min}&ages_max=${selectedAge.max}`;

		toggleModal();

		axiosInstance({
			method: 'GET',
			url
		})
			.then(async ({ data: { results, count } }) => {
				const attendees = await results.map((attendee) => {
					const curp = attendee.participant.text_detect.fields.curp;

					const { affiliate, affiliate_details } = axiosInstanceV1({
						method: 'GET',
						url: `/credentials/${curp}`
					})
						.then(({ data }) => data)
						.catch((err) => console.log(err));

					return { ...attendee, militancia: affiliate ? affiliate_details.img_political_party : null };
				});

				setAssistanceData(attendees);
				setAssistanceCount(count);
			})
			.catch((err) => console.log({ err }))
			.finally(() => {
				setAssistanceLoading(false);
			});
	};

	const entitiesOptions = Object.values(entities || {})?.map((entity) => {
		return {
			label: entity.name,
			value: entity.code
		};
	});

	if (loading || loadingEntities || entities.length === 0) return <InternalLoader />;
  
	if (!data) return <DetailNotFound moduleTitle={'Evento'} />;
  
	return (
		<Fragment>
			<Breadcrumb title="Detalle" parent="Evento" />
			<Container
				fluid
				style={{
					height: '100%',
					background: '#ffffff',
					boxShadow: '0px 0px 21px rgba(89, 102, 122, 0.1)',
					borderRadius: '8px',
					padding: '24px'
				}}
			>
				<Row>
					<Col lg="6" xl="6" sm="12">
						<BasicEventDetails
							setLoadingEdit={setLoadingEdit}
							data={data}
							eventId={eventId}
							entitiesOptions={entitiesOptions}
							fetchEventDetails={fetchEventDetails}
						/>
					</Col>
					<Col lg="6" xl="6" sm="12">
						<EventStats
							assistance={data?.num_assistances || 0}
							percWithCorrectSection={
								assistanceCount !== 0
									? parseFloat((votersWithCorrectSection * 100) / assistanceCount).toFixed(2)
									: 0
							}
							cantResponsables={data?.responsable || 0}
							abrirModal={() => {
								setResponsablesModal(true);
							}}
						/>
					</Col>
				</Row>
				<Row
					className="create-event-title"
					style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
				>
					<span>Lista de asistentes</span>
					<span>
						<Button className="button-content" color="primary" onClick={() => setFilterModal(true)}>
							Filtrar y Ordenar &nbsp;
							<Filter size={12} />
						</Button>
					</span>
				</Row>
				<Row className="mt-2">
					<div className="table-responsive product-table">
						<DataTable
							columns={attendeesHeader}
							data={mappedAssistance}
							progressComponent={<InternalLoader />}
							progressPending={loading}
							selectableRows={false}
							pagination={true}
							paginationTotalRows={assistanceCount}
							paginationComponentOptions={{
								rowsPerPageText: 'Electores por página:',
								rangeSeparatorText: 'de'
							}}
							customStyles={datatableCustomStyle}
							onChangeRowsPerPage={(e) => setPerPage(e)}
							onChangePage={(e) => setPage(e)}
							noDataComponent={'No se han encontrado asistentes'}
						/>
					</div>
				</Row>
			</Container>
			<FilterModalEventDetails
				isOpen={filterModal}
				toggle={toggleModal}
				filterStates={filterStates}
				handleApplyFilter={handleApplyFilter}
			/>
			<ToastContainer />
			<LoadingAffiliation isOpen={loadingEdit || assistanceLoading} />
			{responsablesModal && (
				<ResponsiblesModal
					isOpen={responsablesModal}
					toggle={toggleResponsablesModal}
					setLoadingEdit={setLoadingEdit}
					event={data}
					fetchEventDetails={fetchEventDetails}
				/>
			)}
		</Fragment>
	);
};

export default EventDetails;