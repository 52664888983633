import React, { useEffect, useState } from 'react';
import { Col, Container, Row, TabPane } from 'reactstrap';
import { gql, useMutation, useQuery } from '@apollo/client';
import './sendTab.scss';

import { graphQlClient } from 'store/graphql';
import InternalLoader from 'layout/internal-loader';
import FilterSMSTarget from './filter-sms-target';
import PreviewMessage from 'components/sms/PreviewMessage';
import SendMessageForm from 'components/sms/SendMessageForm';
import Multiselect from 'components/multiselect/Multiselect';
import Tab from 'components/tab/Tab.';
import TabContentContainer from 'components/tab/TabContent';
import { useTab } from 'components/tab/useTab';
import { FilterFormSummary } from './filterFormSummary';
import {
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from '../../../../utils/fetch/entities';
import { useGetStatesByCredentials } from '../../../../utils/fetch/useGetStatesByCredentials';
import { useMultiSelectState } from '../../../multiselect/useMultiSelectState';
import AppliedTerritories from '../../../appliedTerritories/appliedTerritories';
import { useGetAndCountVotersSms, useGetSmsAvailable } from '../../../../utils/fetch/electores';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import { getAgeFromDate } from 'utils/getAgeFromDate';

const GET_NEEDS = gql`
	query NeedMany($filter: FilterFindManyNeeds_ListInput) {
		needMany(filter: $filter) {
			name
			label
		}
	}
`;

const CREATE_SMS = gql`
	mutation Insert_sms($record: CreateOnesmsInput!) {
		insert_sms(record: $record) {
			record {
				_id
				clientId
				dateTimeToSend
				destinataries {
					firstname
					lastname
					phoneNumber
					gender
					state
					section
					age
					curp
					photo
				}
				filter {
					necessity
					city
					state
					sections
					localDistrict
					federalDistrict
					gender
				}
				message
				status
			}
		}
	}
`;

const TABS = ['Sección', 'Municipio', 'D. Local', 'D. Federal'];

const voterAge = (elector) => {
	if (elector.ocr.date_birth) {
		const [dob, mob, yob] = elector.ocr.date_birth.split('/');
		return getAgeFromDate(dob, mob, yob);
	} else {
		return '-';
	}
};

const filter = {
	ocr: {}
};

function SMSView() {
	const { activeTab, handleChangeTab } = useTab(TABS[0], { replaceUrl: false });
	const { data: states } = useGetStatesByCredentials();

	const [stateOptions, setStateOptions] = useState([]);
	const [selectedState, setSelectedState] = useState(null);
	const [selectedNeed, setSelectedNeed] = useState(null);
	const [selectedGender, setSelectedGender] = useState([]);
	const [selectedAge, setSelectedAge] = useState(0);
	const [selectedRangeAge, setSelectedRangeAge] = useState('');

	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedState);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedState);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedState);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedState);

	const { selectedOptions: selectedSections, handleChange: setSelectedOptions } = useMultiSelectState();
	const { selectedOptions: selectedFDistrics, handleChange: setSelectedFDistrics } = useMultiSelectState();
	const { selectedOptions: selectedLDistrics, handleChange: setSelectedLDistrics } = useMultiSelectState();
	const { selectedOptions: selectedMunicipalities, handleChange: setSelectedMunicipalities } = useMultiSelectState();

	const [isScheduledSms, setIsScheduledSms] = useState(false);

	const [filterParams, setFilterParams] = useState(filter);
	const [message, setMessage] = useState('');
	const [dateToSend, setDateToSend] = useState('');
	const [timeToSend, setTimeToSend] = useState('');

	const [clientId, setClientId] = useState('demo');

	const [confirmModal, setConfirmModal] = useState(false);
	const [confirmModalMsg, setConfirmModalMsg] = useState('');
	const [record, setRecord] = useState({});

	const toggle = (state) => {
		setConfirmModal(!confirmModal);

		if (state) {
			createSms({
				variables: {
					record
				}
			});
		}
	};

	const [
		getVotersSms,
		{
			loading: loadingVoterSms,
			data: { items, count }
		}
	] = useGetAndCountVotersSms({ filter: filterParams });

	const [getSmsAvailable, { loading: loadingSmsAvailable }] = useGetSmsAvailable({ clientId });

	const {
		loading,
		data: needs,
		refetch
	} = useQuery(GET_NEEDS, {
		fetchPolicy: 'network-only',
		client: graphQlClient
	});

	useEffect(() => {
		const baseUrl = window.location.host;
		console.log('Base URL:', baseUrl);
		if (window.location) {
			if (baseUrl.indexOf('localhost') !== -1) {
				setClientId('demo');
			} else {
				console.log(baseUrl.split('.'));
				console.log(baseUrl.split('.')[0]);
				let clientIdFound = baseUrl.split('.')[0];

				setClientId(clientIdFound);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location]);

	needs?.needMany?.map(({ label, name }) => ({ label, value: name }));
	const [createSms, { loading: loadingCreate }] = useMutation(CREATE_SMS, {
		errorPolicy: 'all',
		client: graphQlClient,
		onCompleted: () => {
			refetch();
			toast.success('SMS masivo enviado con exito!', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		},
		onError: () => {
			toast.error('Error al enviar SMS masivo', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	});

	useEffect(() => {
		if (states) {
			const mappedStates = Object.values(states || [])?.map((entity) => {
				return {
					label: entity.name,
					value: entity.code
				};
			});
			setStateOptions(mappedStates);
		}
	}, [states]);

	useEffect(() => {
		if (selectedState && selectedState !== '') {
			setSelectedNeed(null);
			setSelectedGender([]);

			let filter = {};

			let ocr = {};

			if (selectedState && selectedState !== '') {
				ocr.state = [selectedState.label];
			}

			if (Object.keys(ocr).length > 0) filter.ocr = ocr;

			setFilterParams(filter);

			getVotersSms();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedState]);

	if (loading) {
		return <InternalLoader />;
	}

	const onSubmitMessage = () => {
		if (message === null || message === '') {
			return toast.error('El mensaje a enviar es requerido', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}

		if (!items || items.length < 1) {
			return toast.error('No hay personas a la cual enviar el mensaje', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}

		if (isScheduledSms) {
			if (!dateToSend || !timeToSend || dateToSend === '' || timeToSend === '') {
				return toast.error('Debe incluir una fecha y hora', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			}
		}

		const filter = {
			city: selectedMunicipalities.length > 0 ? selectedMunicipalities[0].label : '',
			federalDistrict: selectedFDistrics.map((fd) => fd.value.toString()),
			gender: selectedGender.length === 1 ? selectedGender.recordFilterValue : ['Hombre', 'Mujer'],
			localDistrict: selectedLDistrics.map((ld) => ld.value.toString()),
			necessity: null,
			sections: selectedSections.map((sec) => sec.value.toString()),
			state: selectedState.label
		};

		const destinataries = items.map((elector) => ({
			firstname: elector?.ocr?.first_name || '-',
			lastname: elector?.ocr?.last_name || '-',
			phoneNumber: elector?.phoneNumber || '-',
			gender: elector?.ocr?.gender || '-',
			state: elector?.ocr?.state || '-',
			section: elector?.ocr?.section || '-',
			age: voterAge(elector),
			curp: elector?.ocr?.curp || '-',
			photo: elector?.ocr?.passport_photo || '-'
		}));

		const messageRecord = {
			clientId,
			dateTimeToSend: isScheduledSms ? moment(dateToSend + ' ' + timeToSend).format() : moment().format(),
			destinataries,
			filter,
			message: message,
			status: isScheduledSms ? 'SCHEDULED' : 'SEND'
		};

		setRecord(messageRecord);

		getSmsAvailable().then((response) => {
			setConfirmModalMsg(
				`Tienes ${response.data?.getSMSAvailable} mensajes disponibles. ¿Estás seguro de programar el mensaje?`
			);
			setConfirmModal(true);
		});
	};

	const applyFilter = () => {
		let filter = {};

		let ocr = {};

		if (selectedState && selectedState !== '') {
			ocr.state = [selectedState.label];
		}

		if (selectedNeed && selectedNeed !== '') {
			ocr.need = [selectedNeed.label];
		}

		if (selectedGender.length === 1) ocr.gender = selectedGender;

		if (selectedRangeAge && selectedRangeAge !== '') {
			filter.age = { key: selectedRangeAge.value, value: selectedAge };
		}

		if (selectedLDistrics.length > 0) ocr.lDistric = selectedLDistrics;

		if (selectedFDistrics.length > 0) ocr.dDistric = selectedFDistrics;

		if (selectedMunicipalities.length > 0)
			ocr.municipality = selectedMunicipalities.map((municipality) => municipality.value.toString());

		if (selectedSections.length > 0) ocr.section = selectedSections.map((section) => section.value.toString());

		if (Object.keys(ocr).length > 0) filter.ocr = ocr;

		setFilterParams(filter);

		getVotersSms();
	};

	return (
		<div className="sms-page">
			<Container fluid className="p-0">
				<div className="row">
					<Col xs="12">
						<div className="title">
							<h3>Envío masivo</h3>
						</div>
					</Col>
				</div>
				<Row>
					<Col md="6">
						<FilterSMSTarget
							states={stateOptions}
							selectedState={selectedState}
							setSelectedState={setSelectedState}
							selectedGender={selectedGender}
							setSelectedGender={setSelectedGender}
							setSelectedAge={setSelectedAge}
							selectedAge={selectedAge}
							selectedRangeAge={selectedRangeAge}
							setSelectedRangeAge={setSelectedRangeAge}
						/>

						<Col lg="12" className="pl-4">
							<AppliedTerritories
								sections={selectedSections}
								municipalities={selectedMunicipalities}
								lDistricts={selectedLDistrics}
								fDistrics={selectedFDistrics}
							/>
						</Col>
					</Col>
					<Col md="6">
						<Container className="" fluid={true}>
							<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS} small />

							<TabContentContainer activeTab={activeTab}>
								<TabPane tabId={TABS[0]}>
									<Multiselect
										options={!selectedState ? [] : sections}
										onChange={setSelectedOptions}
										loading={loadingSections}
										disabled={
											selectedMunicipalities.length > 0 ||
											selectedLDistrics.length > 0 ||
											selectedFDistrics.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[1]}>
									<Multiselect
										options={!selectedState ? [] : municipalities}
										onChange={setSelectedMunicipalities}
										loading={loadingMunicipalities}
										disabled={
											selectedSections.length > 0 ||
											selectedLDistrics.length > 0 ||
											selectedFDistrics.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[2]}>
									<Multiselect
										options={!selectedState ? [] : lDistrics}
										onChange={setSelectedLDistrics}
										loading={loadingLDistrics}
										disabled={
											selectedSections.length > 0 ||
											selectedMunicipalities.length > 0 ||
											selectedFDistrics.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[3]}>
									<Multiselect
										options={!selectedState ? [] : fDistrics}
										onChange={setSelectedFDistrics}
										loading={loadingFDistrics}
										disabled={
											selectedSections.length > 0 ||
											selectedMunicipalities.length > 0 ||
											selectedLDistrics.length > 0
										}
									/>
								</TabPane>
								<FilterFormSummary
									onSubmitFilter={applyFilter}
									results={count}
									isLoading={
										loadingVoterSms ||
										loadingSections ||
										loadingMunicipalities ||
										loadingLDistrics ||
										loadingFDistrics ||
										loadingCreate
									}
								/>
							</TabContentContainer>
						</Container>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col sm="8">
						<SendMessageForm
							text={message}
							setText={setMessage}
							setDateToSend={setDateToSend}
							dateToSend={dateToSend}
							isScheduledSms={isScheduledSms}
							setIsScheduledSms={setIsScheduledSms}
							timeToSend={timeToSend}
							setTimeToSend={setTimeToSend}
							onSubmitMessage={onSubmitMessage}
							disabledText={loadingCreate || loadingSmsAvailable}
							isDetail
						/>
					</Col>
					<Col sm="4">
						<PreviewMessage message={message} />
					</Col>
				</Row>
			</Container>
			<ConfirmModal isOpen={confirmModal} msg={confirmModalMsg} toggle={toggle} />
			<ToastContainer />
		</div>
	);
}

export default SMSView;
