import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, TabContent, TabPane } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Password, EmailAddress, RememberPassword, LoginWithJWT } from '../constant';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchLoginError, fetchLoginSuccess, move2FA } from '../redux/actions';
import { Alert } from 'reactstrap';
import * as ROUTES from '../routes/index';

// eslint-disable-next-line
import LOGO_BRAND, { LOGO_PSI } from 'brand';
import { gql, useLazyQuery } from '@apollo/client';
import { graphQlClient } from 'store/graphql';
import { setLocalStorageItem } from 'services/localStorageService';
import { TOKEN_DATA, USES_2FA } from 'constant/localStorage';

const loginQuery = gql`
	query getAuthTokens($username: String!, $password: String!, $clientId: String!) {
		getAuthTokens(username: $username, password: $password, clientId: $clientId) {
			access_token
			refresh_token
			uses_2FA
		}
	}
`;

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [loginError, setLoginError] = useState('');

	const selected = 'jwt';
	const [togglePassword, setTogglePassword] = useState(false);

	const [clientId, setClientId] = useState('demo');

	const [login] = useLazyQuery(loginQuery);

	useEffect(() => {
		const baseUrl = window.location.host;
		if (window.location) {
			if (baseUrl.indexOf('localhost') !== -1) {
				setClientId('demo');
			} else {
				let clientIdFound = baseUrl.split('.')[0];

				setClientId(clientIdFound);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location]);

	const loginWithJwt = async () => {
		setIsLoading(true);

		try {
			const { data, error } = await login({
				fetchPolicy: 'network-only',
				client: graphQlClient,
				variables: {
					username: email,
					password: password,
					clientId
				}
			});

			if (error) {
				setLoginError(error.message);
			} else {
				setLoginError('');

				const tokens = data?.getAuthTokens;
				const uses2FA = data?.getAuthTokens?.uses_2FA;

				setLocalStorageItem(TOKEN_DATA, tokens);
				setLocalStorageItem(USES_2FA, JSON.stringify(uses2FA));

				if (!uses2FA) {
					dispatch(fetchLoginSuccess(tokens));
					setTimeout(() => {
						window.location.reload();
					}, 300);

					navigate(ROUTES.INITIAL, { replace: true, state: { refresh: true } });
				} else {
					dispatch(move2FA({ tokens }));

					navigate(ROUTES.LOGIN_2FA, { replace: true, state: { username: email } });
				}
			}
		} catch (error) {
			fetchLoginError(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Container fluid={true} className="p-0">
			<Row>
				<Col xs="12">
					<div className="login-card">
						<img className="img-fluid for-light" src={LOGO_PSI} alt="logo" width={88} height={88} />
						<div className="login-main login-tab">
							<TabContent activeTab={selected} className="content-login">
								<TabPane className="fade show" tabId={'jwt'}>
									<Form className="theme-form">
										<h4>{'Inicia sesión con credenciales'}</h4>
										<p>{'Ingresa tu email y contraseña para continuar'}</p>
										<FormGroup>
											<Label className="col-form-label">{EmailAddress}</Label>
											<Input
												className="form-control"
												type="email"
												required=""
												onChange={(e) => setEmail(e.target.value)}
												defaultValue={email}
											/>
										</FormGroup>
										<FormGroup>
											<Label className="col-form-label">{Password}</Label>
											<Input
												className="form-control"
												type={togglePassword ? 'text' : 'password'}
												onChange={(e) => setPassword(e.target.value)}
												defaultValue={password}
												required=""
											/>
											<div
												className="show-hide"
												onClick={() => setTogglePassword(!togglePassword)}
											>
												<span className={togglePassword ? '' : 'mostrar'}></span>
											</div>
										</FormGroup>
										<div className="form-group mb-0">
											<div className="checkbox ml-3">
												<Input id="checkbox1" type="checkbox" />
												<Label className="text-muted" for="checkbox1">
													{RememberPassword}
												</Label>
											</div>
											<Button
												color="primary"
												className="btn-block"
												onClick={() => loginWithJwt(email, password)}
											>
												{LoginWithJWT}
											</Button>
											{isLoading && (
												<div
													style={{
														width: '100%',
														marginTop: '5%',
														justifyContent: 'center',
														display: 'flex'
													}}
												>
													<CircularProgress style={{ color: '#f0c50d' }} />
												</div>
											)}
											{loginError && (
												<Alert style={{ marginTop: '2em' }} color="danger">
													{loginError}
												</Alert>
											)}
										</div>
									</Form>
								</TabPane>
							</TabContent>
						</div>
						<p style={{ color: '#AFAFAF', fontFamily: 'Rubik', fontSize: '14px', fontWeight: 400 }}>
							Valifai 2024
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Login;
