export const customStyles = {
	cells: {
		style: {
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '0'
		}
	},
	rows: {
		style: {
			'&:hover': {
				backgroundColor: '#fbeaeb !important',
				'.actionButton': {
					color: '#d32f34 !important'
				}
			}
		}
	}
};
