import React from 'react';

import Select from 'react-select';
import { CircularProgress } from '@material-ui/core';

export default function SelectElectorFilter({ changeSelectedVoter, voters, loading }) {
	const electors = voters.map((voter) => {
		return {
			label: `CURP: ${voter.ocr?.curp} NOMBRE: ${voter.ocr?.first_name} ${voter.ocr?.last_name}`,
			value: voter
		};
	});

	return (
		<>
			{loading && (
				<div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
					<CircularProgress style={{ color: '#f0c50d' }} />
				</div>
			)}
			{electors.length > 0 ? (
				<div style={{ width: '100%' }}>
					<Select
						placeholder="Seleccione un votante"
						onChange={(e) => changeSelectedVoter(e.value)}
						options={electors}
					/>
				</div>
			) : null}
		</>
	);
}
