import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import LoadingAffiliation from '../CustomLoader/CustomLoader';
import { axiosInstance } from 'utils/axiosInstance';
import StarRating from './star-rating/star-rating';

const OrdenValoracion = ({ questions, setQuestions, categories, stageId, poll }) => {
	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	const handleCategoryRate = async (id, rate) => {
		setIsLoading(true);

		const {
			data: { results }
		} = await axiosInstance({ url: `/polls/question/?category=${id}&type_question=4&poll=${poll}`, method: 'GET' });

		if (results.length > 0) {
			const cat = await categories.find((obj) => obj.value === id);

			axiosInstance({
				url: `/polls/question/${results[0].id}/`,
				method: 'PUT',
				data: {
					question: cat.label,
					question_score: rate,
					type_question: 4,
					poll,
					stage: stageId,
					category: cat.value,
					is_conditional: false,
					conditionals_questions: [],
					option_conditional: []
				}
			})
				.then(() => {
					const auxQuestion = questions.map((question) => {
						if (results[0].id === question.id) {
							return {
								...question,
								rate: rate,
								type: 4
							};
						}

						return question;
					});

					setQuestions(auxQuestion);
				})
				.catch((err) => console.log(err))
				.finally(() => setIsLoading(false));
		} else {
			const cat = await categories.find((obj) => obj.value === id);

			axiosInstance({
				url: '/polls/question/',
				method: 'POST',
				data: {
					question: cat.label,
					question_score: rate,
					type_question: 4,
					poll,
					stage: stageId,
					category: cat.value,
					is_conditional: false
				}
			})
				.then(({ data }) => setQuestions((prev) => [...prev, { id: data.id, question: cat.label, rate: rate }]))
				.catch((err) => {
					toast.error(err.response.data.stage[0], {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	return (
		<Container fluid style={{ padding: '16px', display: 'flex', gap: '12px', flexDirection: 'column' }}>
			<Row>
				<Col lg={12}>
					<span className="survey-question-type-title">Orden por valoración</span>
				</Col>
			</Row>
			<Row>
				{categories.map((cat) => {
					const { value, label } = cat;

					return (
						<div
							className="mb-2"
							style={{
								display: 'flex',
								flexDirection: 'row',
								backgroundColor: '#F8F8F8',
								padding: '8px 16px',
								gap: '8px',
								alignItems: 'start',
								width: '100%',
								borderRadius: '5px'
							}}
							key={value}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									padding: '8px 16px',
									width: '100%'
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										gap: '24px'
									}}
								>
									<div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
										{label}
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', width: '15%' }}>
										<StarRating
											handleSetQuestionRate={(rate) => {
												handleCategoryRate(value, rate);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</Row>
			<ToastContainer />
			<LoadingAffiliation isOpen={isLoading} />
		</Container>
	);
};

export default OrdenValoracion;
