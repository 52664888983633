import React from 'react';
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row} from 'reactstrap';
import Select from 'react-select';
import {Calendar, X} from 'react-feather';
import DatePicker from "react-date-picker";

const orderByOptions = [
    {label: 'Más Reciente', value: 'newer'},
    {label: 'Más Antiguo', value: 'older'}
];

const smsStatusOptions = [
    {label: 'Enviado', value: 'SEND'},
    {label: 'Programado', value: 'SCHEDULED'},
    {label: 'Error', value: 'ERROR'}
];


const genders = [
    {label: 'Masculino', value: 'Hombre'},
    {label: 'Femenino', value: 'Mujer'}
];

const colourStyles = {
    option: (provided) => ({
        ...provided,
        color: '#d32f34',
        backgroundColor: 'white',
        '&:hover': {backgroundColor: '#d32f3422'}
    }),
    control: (provided) => ({
        ...provided,
        color: '#d32f34'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#d32f34'
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#d32f34'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#d32f34',
        backgroundColor: '#fbeaeb'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#d32f34',
        backgroundColor: '#fbeaeb'
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#d32f34'
        };
    }
};

const FilterSmsModal = ({isOpen, toggle, filterSms, filterOptions, filterHandlers}) => {
    const {
        selectedOrder,
        setSelectedOrder,
        selectedDate,
        setSelectedDate,
        selectedStatus,
        setSelectedStatus,
        selectedGender,
        setSelectedGender,
        selectedState,
        setSelectedState,
        selectedLocaleDistrict,
        setSelectedLocaleDistrict,
        selectedFederalDistrict,
        setSelectedFederalDistrict,
        selectedMunicipality,
        setSelectedMunicipality,
        selectedSection,
        setSelectedSection,
        setLocaleDistrictOptions,
        setFederalDistrictOptions,
        setMunicipalityOptions,
        setSectionOptions
    } = filterSms;

    const {
        loadingDemarcations,
        stateOptions,
        localeDistrictOptions,
        federalDistrictOptions,
        municipalityOptions,
        sectionOptions,
    } = filterOptions;

    const {handleApplyFilter, handleSelectedStateChange} = filterHandlers;

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody className="electors-filter-modal">
                <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <X style={{cursor: 'pointer', color: 'gray'}} className="mr-2" onClick={toggle}/>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>Ordenar por</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isClearable
                                name="selectedOrder"
                                id="selectedOrder"
                                options={orderByOptions}
                                onChange={(option) => {
                                    setSelectedOrder(option);
                                }}
                                placeholder={orderByOptions[0].label}
                                value={selectedOrder}
                                styles={colourStyles}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                        <FormGroup>
                            <Label>{'Fecha de registro'}</Label>
                            <DatePicker
                                clearIcon={false}
                                value={selectedDate}
                                onChange={(value) => {
                                    setSelectedDate(value);
                                }}
                                format="dd/MM/yyyy"
                                calendarIcon={<Calendar size={16} stroke="#d32f34"/>}
                            />
                        </FormGroup>
                    </Col>
                    {/*<Col lg="6" sm="12">*/}
                    {/*    <FormGroup>*/}
                    {/*        <Label>Necesidad</Label>*/}
                    {/*        <Select*/}
                    {/*            isClearable*/}
                    {/*            options={orderByOptions}*/}
                    {/*            placeholder="Todas las necesidades"*/}
                    {/*            styles={colourStyles}*/}
                    {/*        />*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Estado'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isSearchable
                                isClearable
                                name="selectedState"
                                id="selectedState"
                                options={stateOptions}
                                onChange={handleSelectedStateChange}
                                placeholder={'Todos los estados'}
                                value={selectedState || ''}
                                styles={colourStyles}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Sección'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedSection}
                                closeMenuOnSelect={false}
                                options={sectionOptions}
                                placeholder="Todas las secciones"
                                onChange={(e) => setSelectedSection(e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Municipio'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedMunicipality}
                                closeMenuOnSelect={false}
                                options={municipalityOptions}
                                placeholder="Todos los municipios"
                                onChange={(e) => setSelectedMunicipality(e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'D. Local'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedLocaleDistrict}
                                closeMenuOnSelect={false}
                                options={localeDistrictOptions}
                                placeholder="Todas los D. Locales"
                                onChange={(e) => setSelectedLocaleDistrict(e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'D. Federal'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                defaultValue={[]}
                                isMulti
                                isSearchable
                                value={selectedFederalDistrict}
                                closeMenuOnSelect={false}
                                options={federalDistrictOptions}
                                placeholder="Todas los D. Federales"
                                onChange={(e) => setSelectedFederalDistrict(e)}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>{'Sexo'}</Label>
                            <Select
                                noOptionsMessage={() => 'No hay opciones'}
                                isClearable
                                isMulti
                                name="selectedGender"
                                id="selectedGender"
                                options={genders}
                                onChange={(option) => {
                                    setSelectedGender(option);
                                }}
                                placeholder={'Sexo'}
                                value={selectedGender || ''}
                                styles={colourStyles}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg="6" sm="12">
                        <FormGroup>
                            <Label>Status</Label>
                            <Select
                                isClearable
                                options={smsStatusOptions}
                                placeholder={'Todos los status'}
                                styles={colourStyles}
                                onChange={(option) => {
                                    setSelectedStatus(option);
                                }}
                                value={selectedStatus}
                                isLoading={loadingDemarcations}
                                loadingMessage={() => 'Cargando...'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="light-2x txt-dark"
                        onClick={() => {
                            /**
                             * Clears parameter of filter
                             */
                            setSelectedOrder(null);
                            setSelectedState();
                            setSelectedLocaleDistrict([]);
                            setSelectedFederalDistrict([]);
                            setSelectedMunicipality([]);
                            setSelectedSection([]);
                            setSelectedStatus(null)

                            setLocaleDistrictOptions([]);
                            setFederalDistrictOptions([]);
                            setMunicipalityOptions([]);
                            setSectionOptions([]);
                        }}
                        disabled={loadingDemarcations}>
                    Restablecer
                </Button>
                <Button color="primary" disabled={loadingDemarcations} onClick={() => handleApplyFilter()}>Aplicar
                    Filtro</Button>
            </ModalFooter>
        </Modal>
    );
};

export default FilterSmsModal;
