import React from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { X } from 'react-feather';

const colourStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const FilterCartographysModal = ({ isOpen, toggle, filterStates, filterOptions, filterHandlers }) => {
	const {
		selectedState,
		selectedFederalDistrict,
		selectedLocaleDistrict,
		selectedMunicipality,
		selectedSection,
		setSelectedState,
		setSelectedLocaleDistrict,
		setSelectedFederalDistrict,
		setSelectedMunicipality,
		setSelectedSection
	} = filterStates;

	const {
		stateOptions,
		fDistrics,
		loadingFDistrics,
		lDistrics,
		loadingLDistrics,
		municipalities,
		loadingMunicipalities,
		sections,
		loadingSections
	} = filterOptions;

	const { handleApplyFilter, handleClearFilter } = filterHandlers;

	const handleDemarcations = (type, value) => {
		if (type === 'federalDistricts') {
			setSelectedFederalDistrict(value);
			setSelectedLocaleDistrict([]);
			setSelectedMunicipality([]);
			setSelectedSection([]);
		}

		if (type === 'localDistricts') {
			setSelectedLocaleDistrict(value);
			setSelectedFederalDistrict([]);
			setSelectedMunicipality([]);
			setSelectedSection([]);
		}

		if (type === 'municipality') {
			setSelectedMunicipality(value);
			setSelectedFederalDistrict([]);
			setSelectedLocaleDistrict([]);
			setSelectedSection([]);
		}

		if (type === 'sections') {
			setSelectedSection(value);
			setSelectedFederalDistrict([]);
			setSelectedLocaleDistrict([]);
			setSelectedMunicipality([]);
		}
	};

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalBody className="electors-filter-modal">
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row>
					<Col lg="12" sm="12">
						<FormGroup>
							<Label>{'Estado'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								isSearchable
								isClearable
								name="selectedState"
								id="selectedState"
								options={stateOptions}
								onChange={(value) => {
									setSelectedState(value);
									setSelectedFederalDistrict([]);
									setSelectedLocaleDistrict([]);
									setSelectedMunicipality([]);
									setSelectedSection([]);
								}}
								placeholder={'Todos los estados'}
								value={selectedState}
								styles={colourStyles}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'D. Federal'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedFederalDistrict}
								closeMenuOnSelect={false}
								options={fDistrics}
								placeholder="Todos los D. Federales"
								onChange={(e) => handleDemarcations('federalDistricts', e)}
								styles={colourStyles}
								isLoading={loadingFDistrics}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'D. Local'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedLocaleDistrict}
								closeMenuOnSelect={false}
								options={lDistrics}
								placeholder="Todos los D. Locales"
								onChange={(e) => handleDemarcations('localDistricts', e)}
								styles={colourStyles}
								isLoading={loadingLDistrics}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Municipio'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedMunicipality}
								closeMenuOnSelect={false}
								options={municipalities}
								placeholder="Todos los municipios"
								onChange={(e) => handleDemarcations('municipality', e)}
								styles={colourStyles}
								isLoading={loadingMunicipalities}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Sección'}</Label>
							<Select
								noOptionsMessage={() => 'No hay opciones'}
								defaultValue={[]}
								isMulti
								isSearchable
								value={selectedSection}
								closeMenuOnSelect={false}
								options={sections}
								placeholder="Todas las secciones"
								onChange={(e) => handleDemarcations('sections', e)}
								styles={colourStyles}
								isLoading={loadingSections}
								loadingMessage={() => 'Cargando...'}
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={handleClearFilter}
					disabled={loadingFDistrics || loadingLDistrics || loadingMunicipalities || loadingSections}
				>
					Restablecer
				</Button>{' '}
				<Button
					color="primary"
					onClick={() => handleApplyFilter()}
					disabled={loadingFDistrics || loadingLDistrics || loadingMunicipalities || loadingSections}
				>
					Aplicar Filtro
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default FilterCartographysModal;
