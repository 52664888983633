import React, { Fragment, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import CreateEventHeader from './create-event-header';
import DataTable from 'react-data-table-component';
import * as ROUTES from '../../../routes';

import AsyncSelect from 'react-select/async';
import { axiosInstance } from 'utils/axiosInstance';
import { customStyles } from '../electores/tableStyles';
import { toast } from 'react-toastify';

const DropdownIndicator = () => {
	return null;
};

const colourStyles = {
	menu: (styles) => ({ ...styles, position: 'inherit' }),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const tableColums = [
	{ name: 'Nombre', selector: (row) => row.name, sortable: true, width: '35%' },
	{ name: 'Apellido', selector: (row) => row.lastName, sortable: true, width: '35%' },
	{ name: 'Clave de elector', selector: (row) => row.electoralKey, sortable: true, width: '25%' }
];

const CreateEvent2 = () => {
	const location = useLocation();
	const { state } = location;

	const navigate = useNavigate();

	const [electoresData, setElectoresData] = useState([]);

	const handleFilterVoters = async (query) => {
		try {
			const { data } = await axiosInstance({
				url: `users/profiles/?full_name=${query}&user__groups__name=Responsable%20de%20eventos`,
				method: 'GET'
			});

			return data.results.map((voter) => {
				const label = voter?.info_voter
					? voter.info_voter?.info?.full_name
					: voter?.user_extra_info?.first_name + ' ' + voter?.user_extra_info?.last_name;

				return {
					label,
					value: voter.id,
					voter: voter
				};
			});
		} catch (err) {
			return console.log(err);
		}
	};

	const handleChangeFilterVoters = (option) => {
		setElectoresData(
			option.map((opt) => {
				const {
					value,
					voter: {
						info_voter: {
							info: { first_name, last_name, curp }
						}
					},
					voter
				} = opt;

				return {
					id: value,
					name: first_name,
					lastName: last_name,
					electoralKey: curp,
					voter
				};
			})
		);
	};

	const handleCrearEvento = () => {
		const data = state.eventsInfo;

		const params = {
			name: data.name,
			target_people: parseInt(data.targetPeople),
			description: data.name,
			location: data.place,
			start_time: new Date(data.date),
			entities: [data.entity],
			districts: data.fDistricts,
			districts_local: data.lDistricts,
			municipalities: data.municipalities,
			fk_electoral_section: data.sections,
			users: electoresData.map((elector) => elector.id)
		};

		axiosInstance({
			method: 'POST',
			url: '/events/',
			data: params
		})
			.then(() => {
				toast.success('Se ha creado el evento con éxito', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			})
			.catch((response) => {
				console.log(response);
				toast.error(response?.response?.data ? response?.response?.data.name[0] : 'Error al crear el evento', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			})
			.finally(() => {
				navigate(ROUTES.EVENTOS);
			});
	};

	return (
		<Fragment>
			<CreateEventHeader step={2} lastStep={2} />
			<Container fluid={true} style={{ height: 'inherit' }}>
				<Row>
					<Col>
						<Card>
							<CardHeader className="py-3 px-3" style={{ borderBottom: '0px' }}>
								<Row className="create-event-title">Asignar responsable del evento</Row>
							</CardHeader>
							<CardBody style={{ display: 'flex' }} className="p-3 d-flex flex-column">
								<Row className="my-2">
									<Col>
										<AsyncSelect
											components={{ DropdownIndicator }}
											styles={colourStyles}
											name="eventResponsibles"
											placeholder="Ingresar nombre"
											valueKey="value"
											labelKey="label"
											matchPos="any"
											loadOptions={handleFilterVoters}
											hideSelectedOptions={true}
											onChange={(option) => handleChangeFilterVoters(option)}
											loadingMessage={() => 'Cargando...'}
											isMulti
											noOptionsMessage={() => 'Ingresar nombre'}
										/>
									</Col>
								</Row>
								<Row className="my-2">
									<DataTable
										columns={tableColums}
										data={electoresData}
										noDataComponent={'No se han cargado electores'}
										highlightOnHover={true}
										customStyles={customStyles}
									/>
								</Row>
							</CardBody>
							<CardFooter
								className="pt-2 px-3"
								style={{ display: 'flex', flex: 'row', justifyContent: 'space-between' }}
							>
								<div style={{ display: 'flex', flex: 'row', justifyContent: 'flex-start' }}>
									<Link to={ROUTES.CREATE_EVENT_1}>
										<Button outline color="primary">
											Volver atrás
										</Button>
									</Link>
								</div>
								<div style={{ display: 'flex', flex: 'row', justifyContent: 'flex-end' }}>
									<Link to={ROUTES.EVENTOS}>
										<Button outline color="primary" style={{ marginRight: '4px' }}>
											Cancelar
										</Button>{' '}
									</Link>
									<Button color="primary" style={{ marginLeft: '4px' }} onClick={handleCrearEvento}>
										Crear evento
									</Button>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default CreateEvent2;
