import { Button, Col, Container, Input, InputGroup, Row } from 'reactstrap';
import userImg from './rounded.png';
import userImgGray from './rounded-gray.png';
import './pyramidTab.scss';
import { Check, ChevronRight, Search, X } from 'react-feather';
import DataTable from 'react-data-table-component';
import InternalLoader from 'layout/internal-loader';
import { CellAvatar, CellGender, CellMilitancy } from 'components/dashboard/electores/Electores';
import { Link } from 'react-router-dom';
import { customStyles } from 'components/dashboard/electores/tableStyles';
import { useEffect, useState } from 'react';
// import FilterVotersModal from './FilterVotersModal';
import { axiosInstance } from 'utils/axiosInstance';
import axios from 'axios';
import { getLocalStorageItem } from 'services/localStorageService';
import { TOKEN_DATA } from 'constant/localStorage';
import { getAgeFromDate, getAgeWithMoment } from 'utils/getAgeFromDate';
import { usePagination } from 'utils/fetch/pagination';
import moment from 'moment';

const columns = [
	{
		name: 'Nombre y apellido',
		selector: (row) => row.image,
		center: true,
		cell: (row) => (
			<CellAvatar image={row.image} firstName={row.firstName} lastName={row.lastName} gender={row.sexo} />
		)
	},

	{
		name: 'Edad',
		width: '10%',
		selector: (row) => row.age,
		center: true
	},
	{
		name: 'Genero',
		selector: (row) => row.sexo,
		center: true,
		cell: (row) => {
			console.log(row);
			return <CellGender gender={row.sexo} />;
		},
		width: '10%'
	},
	{
		name: 'Militancia',
		center: true,
		cell: (row) => <CellMilitancy srcImg={row?.data_affiliate?.affiliate_details?.img_political_party} />,
		width: '10%'
	},
	{
		name: 'Fecha de Registro',
		center: true,
		cell: (row) => row.created_at,
		width: '15%'
	},
	{
		name: 'Reclutados',
		center: true,
		cell: (row) => row.total_voters_recruited,
		width: '10%'
	},
	{
		cell: (row) => (
			<Link className="actionButton" to={row.curp ? `/electores/${row.curp}` : '#'}>
				<i className="fa fa-angle-right"></i>
			</Link>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true
	}
];

function FilterComponent({ search, setSearch, handleFilter }) {
	return (
		<>
			<Row className="mt-2">
				<Col>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '0 24px',
							height: '51px',
							background: '#fbeaeb',
							borderRadius: '4px'
						}}
					>
						<span
							style={{
								fontWeight: '500',
								fontSize: '16px',
								lineHeight: '19px',
								color: '#d32f34'
							}}
						>
							Lista de reclutados
						</span>
						<div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
							{/* <div>
								<button
									className="btn btn-primary"
									onClick={onShowModal}
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										fontWeight: '400',
										fontSize: '12px',
										lineHeight: '14px',
										padding: '8px 16px'
									}}
								>
									<span>Filtrar y Ordenar</span>
									<Filter size={16} />
								</button>
							</div> */}
							<div>
								<InputGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<Input
										placeholder="Buscar por..."
										className="pl-4 search-input"
										style={{
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px'
										}}
										onChange={(e) => setSearch(e.target.value)}
										value={search}
										onKeyDown={(e) => e.key === 'Enter' && handleFilter()}
									/>
									<Search
										style={{
											position: 'absolute',
											left: '6px',
											bottom: '10px',
											color: 'gray',
											zIndex: 10
										}}
										size={16}
									/>
									<button
										className="btn btn-primary"
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											fontWeight: '400',
											fontSize: '12px',
											lineHeight: '14px',
											padding: '8px 16px',
											borderRadius: '0 6px 6px 0'
										}}
										onClick={handleFilter}
									>
										Buscar
									</button>
								</InputGroup>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}

const RecruitedBy = ({ affiliateData }) => {
	if (!affiliateData?.recruiter_info) {
		return <div className="item-description">-</div>;
	}

	const {
		recruiter_info: { curp, full_name }
	} = affiliateData;

	return affiliateData ? (
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
			{full_name && curp ? (
				<>
					<div className="item-description">{`${full_name}`}</div>
					<Link className="actionButton" to={curp ? `/electores/${curp} ` : '#'}>
						<ChevronRight size={18} color="#d32f34" />
					</Link>
				</>
			) : (
				<div className="item-description">SISTEMA</div>
			)}
		</div>
	) : (
		<div className="item-description">{`${full_name}`}</div>
	);
};

const IsRecruiter = ({ isRecruiter }) => {
	const recruiter = isRecruiter.length > 0;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				gap: '10px'
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '18px',
					height: '18px',
					borderRadius: '50%',
					backgroundColor: '#fbeaeb',
					color: 'd32f34'
				}}
			>
				{recruiter ? <Check color="#d32f34" /> : <X color="#d32f34" />}
			</div>
			<div className="item-description">{recruiter ? 'Es reclutador' : 'No es reclutador'}</div>
		</div>
	);
};

export default function PyramidTab({ dataCredentials }) {
	const { handlePageChange, handlePerRowsChange, page, perPage } = usePagination();

	// const [modal, setModal] = useState(false);

	const credentialsId = dataCredentials?.id;

	/**
	 * Indirectly promoted data
	 */
	const [indirectlyPromoted, setIndirectlyPromoted] = useState(0);

	/* State for pyramid data */
	const [pyramidData, setPyramidData] = useState(null);

	/* State for level data */
	const [affiliateData, setAffiliateData] = useState(null);

	/* State for recruited voters */
	const [votersRecruited, setVotersRecruited] = useState([]);

	/* State for searcher */
	const [search, setSearch] = useState('');

	const [loading, setLoading] = useState(false);

	// const [selectedOrder, setSelectedOrder] = useState(null);
	// const [selectedGender, setSelectedGender] = useState([]);
	// const [selectedMilitancy, setSelectedMilitancy] = useState([]);
	// const [selectedAge, setSelectedAge] = useState({ min: 20, max: 30 });
	// const [hasSelectedAge, setHasSelectedAge] = useState(false);

	const isRecruiter = dataCredentials?.is_recruiter || [];

	const [count, setCount] = useState(0);

	// const toggle = () => setModal(!modal);

	useEffect(() => {
		if (dataCredentials && dataCredentials.user_profile) {
			axiosInstance({
				url: `/activism/associate-affiliates/indirectly-promoted/${dataCredentials.user_profile}/`,
				method: `GET`
			}).then(({ data }) => {
				setIndirectlyPromoted(data.count);
			});
		}
	}, [dataCredentials]);

	useEffect(() => {
		setLoading(true);

		if (credentialsId && dataCredentials) {
			if (dataCredentials.recruited_list && dataCredentials.recruited_list !== 'No es reclutador') {
				axios({
					url: `${dataCredentials.recruited_list}&page=${page}&size=${perPage}`,
					method: 'GET',
					headers: {
						Accept: 'application/json',
						authorization: getLocalStorageItem(TOKEN_DATA)?.access_token
							? `Bearer ${getLocalStorageItem(TOKEN_DATA)?.access_token}`
							: ''
					}
				})
					.then(({ data }) => {
						const { results, count } = data;

						setCount(count || 0);

						setVotersRecruited(
							results.map((voter) => {
								const {
									data_affiliate: { passport_photo, first_name, last_name, date_birth, sexo, curp },
									total_voters_recruited
								} = voter;

								const age = getAgeWithMoment(date_birth);

								return {
									image: passport_photo,
									firstName: first_name,
									lastName: last_name,
									age: age < 0 ? 0 : age,
									sexo: sexo,
									curp: curp,
									total_voters_recruited: total_voters_recruited,
									created_at: moment(voter.created_at).format('DD/MM/YYYY')
								};
							})
						);
					})
					.catch((err) => console.log(err));
			}
			axiosInstance({ url: `activism/associate-affiliates/?fk_affiliate=${credentialsId}`, method: 'GET' })
				.then(({ data: { results } }) => {
					setAffiliateData(results[0] || null);

					if (results[0]) {
						const { id: pyramidId } = results[0].pyramid;

						axiosInstance({ url: `activism/estructure/${pyramidId}/`, method: 'GET' })
							.then(({ data }) => setPyramidData(data))
							.catch((err) => console.log(err));
					}
				})
				.catch((err) => console.log(err))
				.finally(() => setLoading(false));
		}
	}, [credentialsId, dataCredentials, page, perPage]);

	const handleFilter = () => {
		setLoading(true);
		let params = '';

		if (search) {
			const finalSearch = search.replace(/\s/, '%20');
			params += `&name_affiliate=${finalSearch}`;
		}

		axios({
			url: `${dataCredentials.recruited_list}&page=${page}&size=${perPage}${params}`,
			method: 'GET',
			headers: {
				Accept: 'application/json',
				authorization: getLocalStorageItem(TOKEN_DATA)?.access_token
					? `Bearer ${getLocalStorageItem(TOKEN_DATA)?.access_token}`
					: ''
			}
		})
			.then(({ data }) => {
				const { results, count } = data;

				setCount(count || 0);

				setVotersRecruited(
					results.map((voter) => {
						const {
							data_affiliate: { passport_photo, first_name, last_name, date_birth, sexo, curp }
						} = voter;

						const [yyyy, mm, dd] = date_birth.split('-');
						const age = getAgeFromDate(dd, mm, yyyy);

						return {
							image: passport_photo,
							firstName: first_name,
							lastName: last_name,
							age: age < 0 ? 0 : age,
							sexo,
							curp
						};
					})
				);
			})
			.catch((err) => console.log(err))
			.finally(() => setLoading(false));
	};

	// const filterStates = {
	// 	selectedOrder,
	// 	setSelectedOrder,
	// 	selectedGender,
	// 	setSelectedGender,
	// 	selectedMilitancy,
	// 	setSelectedMilitancy,
	// 	selectedAge,
	// 	setSelectedAge,
	// 	hasSelectedAge,
	// 	setHasSelectedAge
	// };

	if (loading) return <InternalLoader />;

	return (
		<>
			<Container fluid className="bg-white">
				{/* Shows the basic pyramid information component */}
				<Row className="mb-4">
					{/* <Col lg={4}> */}
					<div className="custom-title-pyramid">
						<h3 className="m-0">Pirámide actual</h3>
					</div>
					<div className={`custom-detail-card horizontal`}>
						<div className={`d-flex`}>
							<div className={`d-flex justify-content-center flex-column w-100`}>
								<p className="item-title">Nombre de pirámide</p>
								<div className="item-description">{affiliateData?.pyramid?.name || '-'}</div>
							</div>
						</div>

						<div className={`d-flex`}>
							<div className={`d-flex justify-content-center flex-column w-100`}>
								<p className="item-title">Puesto en pirámide</p>
								<div className="item-description">
									{affiliateData ? affiliateData?.level_name : '-'}
								</div>
							</div>
						</div>

						<div className={`d-flex`}>
							<div className={`d-flex justify-content-center flex-column w-100`}>
								<p className="item-title">Reclutado por</p>
								{<RecruitedBy affiliateData={affiliateData} />}
							</div>
						</div>

						<div className={`d-flex`}>
							<div className={`d-flex justify-content-center flex-column w-100`}>
								<p className="item-title">Fecha de afiliación a pirámide</p>
								<div className="item-description">
									{affiliateData
										? new Date(dataCredentials?.creat_at).toLocaleDateString('es-ES', {
												month: '2-digit',
												day: '2-digit',
												year: 'numeric'
										  }) || '-'
										: '-'}
								</div>
							</div>
						</div>

						<div className={`d-flex`}>
							<div className={`d-flex justify-content-center flex-column w-100`}>
								<p className="item-title">Reclutador</p>
								{affiliateData ? <IsRecruiter isRecruiter={isRecruiter} /> : '-'}
							</div>
						</div>
						{isRecruiter?.length > 0 && (
							<div className={`d-flex`}>
								<div className={`d-flex justify-content-center flex-column w-100`}>
									<p className="item-title">Cantidad a reclutar</p>
									<div className="item-description">{`${
										affiliateData?.people_to_recruit || '-'
									}`}</div>
								</div>
							</div>
						)}
						<div className={`d-flex`}>
							<div className={`d-flex justify-content-center flex-column w-100`}>
								<p className="item-title">Reclutados impactados</p>
								<div className="item-description">{indirectlyPromoted}</div>
							</div>
						</div>
					</div>
				</Row>
				{/* Shows the detail of pyramid  */}
				<Row className="mb-4">
					<div className="custom-detail-card horizontal" style={{ display: 'flex', flexDirection: 'column' }}>
						<div
							className="bg-primary-light-gray"
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'flex-start',
									gap: '8px'
								}}
							>
								<span className="item-title">Estado</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-start',
										alignItems: 'center',
										gap: '10px'
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											width: '18px',
											height: '18px',
											borderRadius: '50%',
											backgroundColor: '#fbeaeb',
											color: 'd32f34'
										}}
									>
										{affiliateData ? (
											<>
												<Check color="#d32f34" />
											</>
										) : (
											<>
												<X color="#d32f34" />
											</>
										)}
									</div>
									{affiliateData ? <span>Asignado a pirámide</span> : <span>No Asignado</span>}
								</div>
							</div>
							<div>
								<div className="d-flex justify-content-center flex-column bg-primary-light-gray">
									<Link to={`/piramides/${pyramidData?.id}/`}>
										<Button
											size="sm"
											className="px-2 d-inline-flex align-items-center"
											color="primary"
											disabled={pyramidData ? false : true}
										>
											Ir a pirámide <ChevronRight size={16} />
										</Button>
									</Link>
								</div>
							</div>
						</div>
						<div
							className="bg-primary-light-gray"
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<div className="d-flex align-items-center">
								<div className="mr-2">
									<img
										className="imgAvatarSmall"
										src={affiliateData ? userImg : userImgGray}
										alt="Militancia"
									/>
								</div>
								{affiliateData && (
									<div>
										<h4 className="f-16 m-0">Reclutados: {affiliateData?.total_voters_recruited || 0}</h4>
										<h5 className="f-12 m-0 f-w-400">Nivel: {affiliateData?.level_name}</h5>
									</div>
								)}
							</div>
							<div>
								<div className="d-flex justify-content-center flex-column">
									<span className="font-primary m-0 f-14">
										{affiliateData && 'Asignado actualmente'}
									</span>
								</div>
							</div>
						</div>
					</div>
				</Row>

				{/* Shows info of voters associated to pyramid */}
				{affiliateData ? (
					<div className="custom-title-pyramid">
						<FilterComponent search={search} setSearch={setSearch} handleFilter={handleFilter} />
						<DataTable
							columns={columns}
							customStyles={customStyles}
							data={votersRecruited}
							highlightOnHover={true}
							noDataComponent="No hay reclutados para este nivel"
							onChangePage={handlePageChange}
							onChangeRowsPerPage={handlePerRowsChange}
							pagination
							paginationDefaultPage={page}
							paginationPerPage={perPage}
							paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
							paginationServer
							paginationTotalRows={count}
							progressComponent={<InternalLoader />}
							progressPending={loading}
							selectableRows={false}
							paginationComponentOptions={{
								rowsPerPageText: 'Reclutados por página:',
								rangeSeparatorText: 'de'
							}}
						/>
					</div>
				) : null}
			</Container>
			{/* <FilterVotersModal isOpen={modal} toggle={toggle} filterStates={filterStates} /> */}
		</>
	);
}