import React, { useState } from 'react';
import {
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	TabContent,
	TabPane
} from 'reactstrap';
import { axiosInstance } from 'utils/axiosInstance';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { Plus, Slash, User } from 'react-feather';
import InternalLoader from 'layout/internal-loader';

import DataTable from 'react-data-table-component';
import { CellGender } from '../electores/Electores';
import { getAgeFromDate } from 'utils/getAgeFromDate';
import { toast } from 'react-toastify';
import { useGetVotersDetails } from '../../../utils/fetch/electores';
import manAvatar from '../../../assets/images/avatars/man-avatar.svg';
import womanAvatar from '../../../assets/images/avatars/woman-avatar.svg';
import nonBinaryAvatar from '../../../assets/images/avatars/nonbinary-avatar.svg';
import { getAgefromDate } from '../../../utils/dates';
import GeneralTab from '../voterDetails/general-tab/GeneralTab';
import { useCredentials } from '../../../utils/fetch/credentials';

const datatableCustomStyle = {
	cells: {
		style: {
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '0'
		}
	},
	rows: {
		style: {
			'&:hover': {
				backgroundColor: '#fbeaeb !important',
				'.actionButton': {
					color: '#d32f34 !important'
				}
			}
		}
	}
};

const DropdownIndicator = (props) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<Plus size={14} color="#F04F62" />
			</components.DropdownIndicator>
		)
	);
};

const colourStyles = {
	menu: (styles) => ({ ...styles, position: 'inherit' }),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

function CellAvatar({ image, fullName }) {
	return (
		<div className="cell-avatar">
			<div>
				{image ? (
					<img src={image} style={{ width: 30, height: 30 }} alt={fullName} />
				) : (
					<div
						style={{
							width: '30px',
							height: '30px',
							opacity: '0.4',
							borderRadius: '50%',
							border: '2px solid #B2B2B2',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<User size={20} color="#B2B2B2" />
					</div>
				)}
			</div>
			<div className="ml-2 mb-0">
				<p className="mb-0">{fullName}</p>
			</div>
		</div>
	);
}

const ResponsiblesModal = ({ isOpen, toggle, setLoadingEdit, event, fetchEventDetails }) => {
	const [activeTab, setActiveTab] = useState('0');
	const [curpSelected, setCurpSelected] = useState('');

	const [responsables, setResponsables] = useState(
		event?.responsable?.length > 0
			? event.responsable.map((responsable) => {
					const { date_of_birth, gender, full_name, id, curp, avatar } = responsable;

					const [yob, mob, dob] = date_of_birth.split('-');
					const age = getAgeFromDate(dob, mob, yob);

					return {
						id,
						fullName: full_name,
						age,
						sexo: gender,
						curp,
						passport_photo: avatar
					};
			  })
			: []
	);

	const [getVoterDetails, { loading: loadingVoterDetails, data }] = useGetVotersDetails({ curp: curpSelected });

	const { loading: loadingCredentials, data: dataCredentials } = useCredentials(curpSelected);

	const [newResponsables, setNewResponsables] = useState([]);

	const handleFilterVoters = async (query) => {
		try {
			const { data } = await axiosInstance({
				url: `users/profiles/?full_name=${query}&user__groups__name=Responsable%20de%20eventos`,
				method: 'GET'
			});

			const nonSelectedVoters = data.results.filter(
				(voter) => !responsables.some((resp) => resp.id === voter.id)
			);

			return nonSelectedVoters.map((voter) => {
				const {
					id,
					info_voter: {
						info: { full_name, curp }
					}
				} = voter;

				return {
					label: `${full_name} - ${curp}`,
					value: id,
					voter: voter
				};
			});
		} catch (err) {
			return console.log(err);
		}
	};

	const handleChangeFilterVoters = (option) => {
		const newResponsablesVoters = option.map((opt) => {
			const {
				voter: {
					id,
					info_voter: {
						info: { full_name, date_birth, gender, curp, passport_photo }
					}
				}
			} = opt;

			const [dob, mob, yob] = date_birth.split('/');
			const age = getAgeFromDate(dob, mob, yob);

			return {
				id,
				fullName: full_name,
				age,
				sexo: gender,
				curp,
				passport_photo
			};
		});

		setNewResponsables([...newResponsablesVoters]);
	};

	const viewElector = (curp) => {
		setCurpSelected(curp);
		getVoterDetails();
		setActiveTab('1');
	};

	const selectSrcImg = (data) => {
		if (data.ocr?.passport_photo) {
			return data.ocr?.passport_photo;
		}
		if (data.ocr?.gender.toLocaleLowerCase() === 'male') {
			return manAvatar;
		}
		if (data.ocr?.gender.toLocaleLowerCase() === 'female') {
			return womanAvatar;
		}
		if (data.ocr?.gender.toLocaleLowerCase() === 'non-binary') {
			return nonBinaryAvatar;
		}
	};
	const saveResponsibles = () => {
		const params = {
			users: [...responsables, ...newResponsables].map((res) => res.id),
			name: event.name,
			start_time: event.start_time,
			target_people: event.target_people
		};

		setLoadingEdit(true);

		axiosInstance({
			method: 'PUT',
			url: `/events/${event.id}/`,
			data: params
		})
			.then(() => {
				fetchEventDetails();

				toast.success('Se han modificado los responsables del evento con éxito', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			})
			.catch(() => {
				setResponsables(responsables);
				toast.error('Error al modificar los responsables del evento', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			})
			.finally(() => {
				setLoadingEdit(false);
				toggle();
			});
	};

	const deleteResponsible = (id) => {
		setResponsables(responsables.filter((resp) => resp.id !== id));
		setNewResponsables(newResponsables.filter((newResp) => newResp.id !== id));
	};

	const responsibleHeader = [
		{
			name: 'Elector',
			center: true,
			width: '25%',
			cell: (row) => <CellAvatar image={row.passport_photo} fullName={row.fullName} />
		},
		{
			name: 'Edad',
			selector: (row) => row.age,
			width: '10%',
			center: true
		},
		{
			name: 'Género',
			selector: (row) => row.sexo,
			center: true,
			width: '10%',
			cell: (row) => <CellGender gender={row.sexo} />
		},
		{
			name: 'Militancia',
			center: true,
			width: '20%',
			cell: (row) => (
				<div
					style={{
						padding: '6px',
						width: '32px',
						height: '32px',
						background: '#AFAFAF',
						opacity: '0.4',
						borderRadius: '5px'
					}}
				>
					<Slash size={20} color="white" />
				</div>
			)
		},
		{
			cell: (row) => (
				<Button outline color="primary" onClick={() => deleteResponsible(row.id)}>
					Eliminar
				</Button>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
			width: '25%'
		},
		{
			cell: (row) => (
				<div color="primary" className="actionIconButton" onClick={() => viewElector(row.curp)}>
					<i className="fa fa-angle-right"></i>
				</div>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		}
	];

	return (
		<Modal isOpen={isOpen} toggle={toggle} size="xl" scrollable>
			<ModalHeader toggle={toggle} style={{ padding: '20px' }}></ModalHeader>
			<ModalBody style={{ width: '1000px' }}>
				<Container>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="0">
							<Row className="my-2">
								<Col>
									<Row className="my-1">
										<span
											style={{
												fontWeight: 500,
												fontSize: '14px',
												lineHeight: '16px',
												color: '#333333'
											}}
										>
											Responsables del evento
										</span>
									</Row>
									<Row className="my-1">
										<span
											style={{
												fontWeight: 200,
												fontSize: '13px',
												lineHeight: '16px',
												color: '#333333'
											}}
										>
											{event?.name}
										</span>
									</Row>
								</Col>
								<Col>
									<AsyncSelect
										components={{ DropdownIndicator }}
										styles={colourStyles}
										name="eventResponsibles"
										placeholder="Ingresar nombre"
										valueKey="value"
										labelKey="label"
										matchPos="any"
										loadOptions={handleFilterVoters}
										hideSelectedOptions={true}
										onChange={(option) => handleChangeFilterVoters(option)}
										loadingMessage={() => 'Cargando...'}
										isMulti
										noOptionsMessage={() => 'Ingresar nombre'}
									/>
								</Col>
							</Row>
							<Row className="my-4">
								<DataTable
									columns={responsibleHeader}
									data={[...responsables, ...newResponsables]}
									progressComponent={<InternalLoader />}
									selectableRows={false}
									//pagination={true}
									//paginationTotalRows={assistanceCount}
									paginationComponentOptions={{
										rowsPerPageText: 'Responsables por página:',
										rangeSeparatorText: 'de'
									}}
									customStyles={datatableCustomStyle}
									/* onChangeRowsPerPage={(e) => setPerPage(e)}
                                    onChangePage={(e) => setPage(e)} */
									noDataComponent={'No se han encontrado responsables'}
								/>
							</Row>
						</TabPane>

						<TabPane tabId="1">
							{loadingCredentials || loadingVoterDetails ? (
								<InternalLoader />
							) : (
								<Container className="page-voter-details" fluid>
									<div className="main-data-container">
										<div className="avatar-name">
											<img src={selectSrcImg(data)} alt="user avatar" />
											<h2>{data.ocr?.full_name}</h2>
										</div>
										<div className="main-data-list">
											<ul className="list-horizontal">
												<li key={0}>
													<CellGender gender={data.ocr?.gender} />
												</li>
												<li key={1}>{getAgefromDate(data.ocr?.date_birth)} años</li>
												<li key={2}>{data.ocr?.section}</li>
												<li key={3}>{data.ocr?.state}</li>
												<li key={4}>{data.ocr?.date_birth}</li>
											</ul>
										</div>
									</div>
									<GeneralTab
										readOnly={true}
										scanLocation={data.scanLocation}
										dataCredentials={dataCredentials || {}}
										otherDataIne={data || {}}
										personData={data.ocr}
									/>
								</Container>
							)}
						</TabPane>
					</TabContent>
				</Container>
			</ModalBody>
			<ModalFooter>
				<Button outline color="light-2x txt-dark" onClick={toggle}>
					Cancelar
				</Button>
				<Button color="primary" onClick={() => saveResponsibles()}>
					Guardar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ResponsiblesModal;
