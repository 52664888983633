const { useRef, useEffect } = require('react');

const CustomSlider = ({ label, min, max, step, value, onChange }) => {
	const sliderRef = useRef(null); // Reference to the slider element
	const thumbValueRef = useRef(null); // Reference to the thumb value element

	// Update the position of the thumb value dynamically
	useEffect(() => {
		const slider = sliderRef.current;
		const thumbValue = thumbValueRef.current;

		if (slider && thumbValue) {
			const { width } = slider.getBoundingClientRect();
			const percentage = (value - slider.min) / (slider.max - slider.min);
			const thumbPosition = percentage * width;
			thumbValue.style.left = `${thumbPosition + 5}px`;
		}
	}, [value]); // Re-run effect when the slider value changes

	return (
		<div className="scaleFieldBlock">
			<div className="controlItem" style={{ position: 'relative' }}>
				{/* Slider */}
				<input
					type="range"
					min={min}
					max={max}
					step={step}
					value={value}
					aria-label={label.toLowerCase()}
					ref={sliderRef}
					onChange={(e) => onChange(e.target.value)}
					style={{ width: '100%' }}
				/>

				{/* Label */}
				<span style={{ fontSize: '12px', color: '#333', fontWeight: '500', lineHeight: '16px' }}>{label}</span>
			</div>
		</div>
	);
};

export default CustomSlider;
