import React, { useState } from 'react';
import { Minus, Plus } from 'react-feather';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Input, Row } from 'reactstrap';
import LoadingAffiliation from '../CustomLoader/CustomLoader';
import { axiosInstance } from 'utils/axiosInstance';

const QuestionRow = ({ setQuestions, categories, stageId, poll }) => {
	/**
	 * Question value
	 */
	const [questionValue, setQuestionValue] = useState('');

	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Handle add open question
	 */
	const handleAddQuestion = () => {
		if (questionValue) {
			const params = {
				question: questionValue,
				question_score: 0,
				type_question: 5,
				poll,
				stage: stageId,
				category: categories[0].value,
				is_conditional: false,
				conditionals_questions: [],
				option_conditional: []
			};

			setIsLoading(true);

			axiosInstance({
				url: '/polls/question/',
				method: 'POST',
				data: params
			})
				.then(({ data }) => {
					setQuestions((prev) => [...prev, { id: data.id, question: questionValue, type: 5 }]);
				})
				.catch((err) => {
					toast.error(err.response.data.stage[0], {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				})
				.finally(() => {
					setQuestionValue('');

					setIsLoading(false);
				});
		} else {
			toast.error(`Debe escribir a la pregunta.`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	};

	return (
		<div
			className="mb-2"
			style={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#F8F8F8',
				padding: '8px 16px',
				gap: '8px',
				alignItems: 'start',
				width: '100%',
				borderRadius: '5px'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					width: '100%'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: '24px'
					}}
				>
					<Input
						bsSize="sm"
						placeholder="Ingrese pregunta abierta"
						value={questionValue}
						onChange={(e) => setQuestionValue(e.target.value)}
						onKeyDown={({ key }) => key === 'Enter' && handleAddQuestion()}
					/>
					<Plus size={24} color="#d32f34" style={{ cursor: 'pointer' }} onClick={handleAddQuestion} />
				</div>
			</div>
			<LoadingAffiliation isOpen={isLoading} />
		</div>
	);
};

const PreguntasAbiertas = ({ questions, setQuestions, stageId, poll, categories }) => {
	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Handlers to modify questions
	 */
	const [selectedId, setSelectedId] = useState(null);
	const [modifyValue, setModifyValue] = useState('');

	const handleRemoveExistingQuestion = (questionId) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'DELETE'
		})
			.then(() => {
				const newQuestions = questions.filter((question) => question.id !== questionId);

				setQuestions(newQuestions);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleModifyQuestion = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${selectedId}/`,
			method: 'PUT',
			data: {
				question: modifyValue,
				question_score: 0,
				type_question: 5,
				poll,
				stage: stageId,
				category: categories[0].value,
				is_conditional: false,
				conditionals_questions: [],
				option_conditional: []
			}
		})
			.then(() => {
				const auxQuestions = questions.map((question) => {
					if (selectedId === question.id) {
						return { ...question, question: modifyValue };
					}

					return question;
				});

				setQuestions(auxQuestions);
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
				setModifyValue('');
				setSelectedId(null);
			});
	};

	return (
		<Container fluid style={{ padding: '16px', display: 'flex', gap: '12px', flexDirection: 'column' }}>
			<Row>
				<Col lg={12}>
					<span className="survey-question-type-title">Preguntas abiertas</span>
				</Col>
			</Row>
			<Row>
				{questions.length > 0 &&
					questions.map((qstn) => {
						const { id: questionId, question } = qstn;

						return (
							<div
								className="mb-2"
								style={{
									display: 'flex',
									flexDirection: 'row',
									backgroundColor: '#F8F8F8',
									padding: '8px 16px',
									gap: '8px',
									alignItems: 'start',
									width: '100%',
									borderRadius: '5px'
								}}
								key={questionId}
							>
								{selectedId === questionId ? (
									<Input
										bsSize="sm"
										placeholder="Ingrese pregunta abierta"
										value={modifyValue}
										onChange={(e) => setModifyValue(e.target.value)}
										onKeyDown={({ key }) => {
											if (key === 'Enter') handleModifyQuestion();
											if (key === 'Escape') setSelectedId(null);
										}}
									/>
								) : (
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												padding: '8px 16px',
												width: '100%'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
													alignItems: 'center',
													gap: '24px'
												}}
											>
												<span
													style={{
														color: '#333',
														fontSize: '14px',
														fontWeight: '700',
														lineHeight: 'normal'
													}}
													onDoubleClick={() => {
														setSelectedId(questionId);
														setModifyValue(question);
													}}
												>
													{question}
												</span>
												<Minus
													size={24}
													color="#d32f34"
													style={{ cursor: 'pointer' }}
													onClick={() => handleRemoveExistingQuestion(questionId)}
												/>
											</div>
										</div>
									</>
								)}
							</div>
						);
					})}
				<QuestionRow setQuestions={setQuestions} stageId={stageId} poll={poll} categories={categories} />
			</Row>
			<ToastContainer />
			<LoadingAffiliation isOpen={isLoading} />
		</Container>
	);
};

export default PreguntasAbiertas;
