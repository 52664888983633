import React from 'react';
import { Calendar, X } from 'react-feather';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import Select from 'react-select';
import DatePicker from 'react-date-picker';

const orderByOptions = [
	{ label: 'Nombre A-Z', value: 'aToZ' },
	{ label: 'Nombre Z-A', value: 'zToA' },
	{ label: 'Más Antiguo', value: 'older' },
	{ label: 'Más Reciente', value: 'newer' }
];

const statusOptions = [
	{ label: 'Programado', value: 'Programado' },
	{ label: 'En progreso', value: 'En progreso' },
	{ label: 'Finalizado', value: 'Finalizado' }
];

const customStyles = {
	option: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: 'white',
		'&:hover': { backgroundColor: '#d32f3422' }
	}),
	control: (provided) => ({
		...provided,
		color: '#d32f34',
		overflowX: 'auto',
		minHeight: 'auto'
	}),
	singleValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValue: (provided) => ({
		...provided,
		color: '#d32f34'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: '#d32f34',
		backgroundColor: '#fbeaeb'
	}),
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#d32f34'
		};
	}
};

const EventosTabFiltro = ({ isOpen, toggle, states, filterOptions, handleFilter }) => {
	const {
		selectedOrder,
		setSelectedOrder,
		selectedDate,
		setSelectedDate,
		selectedEntity,
		setSelectedEntity,
		selectedStatus,
		setSelectedStatus
	} = states;

	const { entitiesOptions } = filterOptions;

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalBody className="events-filter-modal">
				<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<X style={{ cursor: 'pointer', color: 'gray' }} className="mr-2" onClick={toggle} />
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Ordenar por'}</Label>
							<Select
								isClearable
								name="selectedOrder"
								id="selectedOrder"
								options={orderByOptions}
								onChange={(option) => {
									setSelectedOrder(option);
								}}
								placeholder={orderByOptions[0].label}
								value={selectedOrder}
								styles={customStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Fecha de realización'}</Label>
							<DatePicker
								value={selectedDate}
								onChange={(value) => {
									setSelectedDate(value);
								}}
								clearIcon={false}
								format="dd/MM/yyyy"
								calendarIcon={<Calendar size={16} stroke="#d32f34" />}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Ubicación'}</Label>
							<Select
								isClearable
								name="selectedEntity"
								id="selectedEntity"
								options={entitiesOptions}
								onChange={(option) => {
									setSelectedEntity(option);
								}}
								placeholder={'Todas las ubicaciones'}
								value={selectedEntity}
								styles={customStyles}
							/>
						</FormGroup>
					</Col>
					<Col lg="6" sm="12">
						<FormGroup>
							<Label>{'Status'}</Label>
							<Select
								isClearable
								name="selectedStatus"
								id="selectedStatus"
								options={statusOptions}
								onChange={(option) => {
									setSelectedStatus(option);
								}}
								placeholder={'Todos los status'}
								isMulti
								value={selectedStatus}
								styles={customStyles}
							/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button
					outline
					color="light-2x txt-dark"
					onClick={() => {
						setSelectedDate();
						setSelectedEntity(null);
						setSelectedOrder();
						setSelectedStatus([]);
					}}
				>
					Restablecer
				</Button>{' '}
				<Button
					color="primary"
					onClick={() => {
						handleFilter();
						toggle();
					}}
				>
					Aplicar Filtro
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EventosTabFiltro;
