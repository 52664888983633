import React, { useState } from 'react';
import { ChevronRight, Minus, Plus } from 'react-feather';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Input, Row, UncontrolledTooltip } from 'reactstrap';
import ValoracionRespuestas from './valoracion-respuestas';
import LoadingAffiliation from '../CustomLoader/CustomLoader';
import CondicionantePregunta from './condicionante-pregunta';
import { axiosInstance } from 'utils/axiosInstance';

import Select from 'react-select';

const OpenQuestion = ({ setQuestions, categories, subcategories, stageId, poll, allQuestions }) => {
	/**
	 * Question value
	 */
	const [questionValue, setQuestionValue] = useState('');

	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Open question conditionals modal
	 */
	const [questionConditionsIsOpen, setQuestionConditionsIsOpen] = useState(false);
	const toggleQuestionConditions = () => setQuestionConditionsIsOpen(!questionConditionsIsOpen);

	/**
	 * Handler for question conditions
	 */
	const [questionConditions, setQuestionConditions] = useState({});

	const handleAddQuestionCondition = (conditions) => {
		setQuestionConditions(conditions);

		toggleQuestionConditions();
	};

	const handleAddQuestion = () => {
		if (questionValue) {
			setIsLoading(true);

			let data = {
				question: questionValue,
				poll: poll,
				stage: stageId,
				type_question: 5,
				question_score: 0,
				category: categories[0].value,
				is_conditional: true
			};

			if (Object.keys(questionConditions).length > 0) {
				data = { ...data, ...questionConditions };
			} else {
				data = { ...data, conditionals_questions: [], option_conditional: [] };
			}

			axiosInstance({
				url: '/polls/question/',
				method: 'POST',
				data
			})
				.then(({ data }) => {
					setQuestions((prev) => [...prev, { id: data.id, question: questionValue, type: 5 }]);
				})
				.catch((err) => {
					toast.error('No se pudo guardar la pregunta', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				})
				.finally(() => {
					setQuestionConditions({});
					setQuestionValue('');
					setIsLoading(false);
				});
		} else {
			toast.error(`Debe escribir a la pregunta.`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	};

	return (
		<div
			className="mb-2"
			style={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#F8F8F8',
				padding: '8px 16px',
				gap: '8px',
				alignItems: 'start',
				width: '100%',
				borderRadius: '5px'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					width: '100%'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: '24px'
					}}
				>
					<Input
						bsSize="sm"
						placeholder="Ingrese pregunta abierta"
						value={questionValue}
						onChange={(e) => setQuestionValue(e.target.value)}
						onKeyDown={({ key }) => key === 'Enter' && handleAddQuestion()}
					/>
					<Plus size={24} color="#d32f34" style={{ cursor: 'pointer' }} onClick={handleAddQuestion} />
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
						gap: '8px'
					}}
				>
					<i class="fa fa-filter"></i>
					<span
						style={{
							color: '#333',
							fontSize: '12px',
							fontWeight: '400',
							lineHeight: 'normal',
							cursor: 'pointer',
							textDecoration: 'underline'
						}}
						onClick={toggleQuestionConditions}
					>
						Condicionar pregunta
					</span>
				</div>
			</div>
			<CondicionantePregunta
				isOpen={questionConditionsIsOpen}
				toggle={toggleQuestionConditions}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				title={questionValue}
				handleAddQuestionCondition={handleAddQuestionCondition}
				allQuestions={allQuestions}
			/>
			<LoadingAffiliation isOpen={isLoading} />
		</div>
	);
};

const MultiQuestion = ({ setQuestions, categories, subcategories, stageId, poll, allQuestions }) => {
	/**
	 * Question values
	 */
	const [questionValue, setQuestionValue] = useState('');
	const [questionId, setQuestionId] = useState(null);

	/**
	 * Question value conditional
	 */
	const [questionIsSaved, setQuestionIsSaved] = useState(false);

	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Open answer value modal
	 */
	const [answerValueIsOpen, setAnswerValueIsOpen] = useState(false);
	const toggleAnswerValue = () => setAnswerValueIsOpen(!answerValueIsOpen);

	/**
	 * Handle the answers of multiple options question
	 */
	const [answers, setAnswers] = useState([]);
	const [newAnswer, setNewAnswer] = useState('');

	/**
	 * Handle selected answer tod set value
	 */
	const [selectedAnswerToValue, setSelectedAnswerToValue] = useState(null);
	const [answerTitle, setAnswerTitle] = useState('');

	/**
	 * Handle editable question
	 */
	const [editableQuestion, setEditableQuestion] = useState(false);
	const [auxQuestionValue, setAuxQuestionValue] = useState('');

	/**
	 * Handle editable answer
	 */
	const [editableAnswer, setEditableAnswer] = useState(null);
	const [auxEditableAnswer, setAuxEditableAnswer] = useState('');

	/**
	 * Open question conditionals modal
	 */
	const [questionConditionsIsOpen, setQuestionConditionsIsOpen] = useState(false);
	const toggleQuestionConditions = () => setQuestionConditionsIsOpen(!questionConditionsIsOpen);

	/**
	 * Handler for question conditions
	 */
	const [questionConditions, setQuestionConditions] = useState({});

	const handleSaveQuestion = () => {
		if (questionValue) {
			setIsLoading(true);

			let data = {
				question: questionValue,
				poll: poll,
				stage: stageId,
				type_question: 2,
				question_score: 0,
				category: categories[0].value,
				is_conditional: true
			};

			if (Object.keys(questionConditions).length > 0) {
				data = { ...data, ...questionConditions };
			} else {
				data = { ...data, conditionals_questions: [], option_conditional: [] };
			}

			axiosInstance({
				url: '/polls/question/',
				method: 'POST',
				data
			})
				.then(({ data }) => {
					setQuestionId(data.id);

					setAnswers((prev) =>
						prev.map((answer) => ({
							question: data.id,
							...answer
						}))
					);

					setQuestionIsSaved(true);
				})
				.catch((err) => {
					toast.error('No se pudo guardar la pregunta', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				})
				.finally(() => setIsLoading(false));
		} else {
			toast.error(`Debe escribir a la pregunta.`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	};

	const handleUpdateQuestion = () => {
		setIsLoading(true);

		let data = {
			question: questionValue,
			poll: poll,
			stage: stageId,
			type_question: 2,
			question_score: 0,
			category: categories[0].value,
			is_conditional: true
		};

		if (Object.keys(questionConditions).length > 0) data = { ...data, ...questionConditions };

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'PUT',
			data
		})
			.then(() => setQuestionValue(auxQuestionValue))
			.catch(() => {
				setIsLoading(false);
				setAuxQuestionValue('');
				setEditableQuestion(false);
			});
	};

	const handleAddQuestion = async () => {
		/**
		 * Boolean to check if any answer has not value
		 */
		const notAllValue = answers.some((obj) => obj.option_score === null || obj.category_subcategory.length === 0);

		if (!notAllValue) {
			setIsLoading(true);

			try {
				let auxQuestion = { id: questionId, question: questionValue, type: 2 };

				let auxAnswers = [];

				for (let i in answers) {
					const answer = answers[i];

					await axiosInstance({
						url: '/polls/option/',
						method: 'POST',
						data: {
							option: answer.option,
							option_score: answer.option_score,
							question: questionId,
							category_subcategory: answer.category_subcategory
						}
					}).then(({ data }) => {
						auxAnswers.push({ ...answer, id: data.id });
					});
				}

				auxQuestion.answers = auxAnswers;

				setQuestions((prev) => [...prev, auxQuestion]);
			} catch (err) {
				toast.error(err.response.data.question[0], {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			} finally {
				setQuestionValue('');
				setQuestionId(null);
				setQuestionIsSaved(false);

				setAnswers([]);

				setIsLoading(false);
			}
		} else {
			toast.error('Alguna de las respuestas no tiene un valor asignado.', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	};

	const removeQuestion = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'DELETE'
		})
			.then(() => {
				setQuestionValue('');
				setQuestionId(null);
				setQuestionIsSaved(false);
				setAnswerValueIsOpen(false);
				setAnswers([]);
				setNewAnswer('');
				setSelectedAnswerToValue(null);
				setAnswerTitle('');
				setEditableQuestion(false);
				setAuxQuestionValue('');
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleUpdateAnswer = () => {
		try {
			const auxAnswers = answers.map((answer, index) => {
				if (index === editableAnswer) {
					return { ...answer, option: auxEditableAnswer };
				}

				return answer;
			});

			setAnswers(auxAnswers);
		} catch (e) {
			console.log(e);
		} finally {
			setEditableAnswer(false);
		}
	};

	const handleRemoveAnswer = (idx) => {
		setAnswers((prev) => prev.filter((_, index) => idx !== index));
	};

	/**
	 * Handle add new answer to question
	 */
	const handleAddAnswer = () => {
		if (newAnswer) {
			setAnswers((prev) => [
				...prev,
				{
					option: newAnswer,
					option_score: null,
					question: questionId,
					category_subcategory: null
				}
			]);
			setNewAnswer('');
		} else {
			toast.error('La respuesta no puede estar vacía', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	};

	const handleSetAnswerValue = (rate, cat_subcat) => {
		const auxAnswers = answers.map((obj, index) => {
			if (index === selectedAnswerToValue) {
				setSelectedAnswerToValue(null);

				return { ...obj, option_score: rate, category_subcategory: [cat_subcat] };
			}

			return obj;
		});

		setAnswers(auxAnswers);
		setAnswerTitle('');

		toggleAnswerValue();
	};

	const handleCancelAnswerValue = () => {
		setSelectedAnswerToValue(null);
		setAnswerTitle('');

		toggleAnswerValue();
	};

	const handleAddQuestionCondition = (conditions) => {
		if (questionIsSaved) {
			setIsLoading(true);
			axiosInstance({
				url: `/polls/question/${questionId}/`,
				method: 'PUT',
				data: {
					...conditions,
					...questionConditions,
					poll: poll,
					stage: stageId,
					question: questionValue,
					type_question: 1,
					question_score: 0,
					category: categories[0].value,
					is_conditional: true
				}
			})
				.catch((err) => console.log(err))
				.finally(() => setIsLoading(false));
		} else {
			setQuestionConditions(conditions);
		}

		toggleQuestionConditions();
	};

	return (
		<div
			className="mb-2"
			style={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#F8F8F8',
				padding: '8px 16px',
				gap: '8px',
				alignItems: 'start',
				width: '100%',
				borderRadius: '5px'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					width: '100%',
					gap: '8px'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: '24px'
					}}
				>
					{!questionIsSaved ? (
						<>
							<Input
								bsSize="sm"
								placeholder="Ingrese pregunta múltiple"
								value={questionValue}
								onChange={(e) => setQuestionValue(e.target.value)}
								onKeyDown={({ key }) => key === 'Enter' && handleSaveQuestion()}
							/>
							<ChevronRight
								size={24}
								color="#d32f34"
								style={{ cursor: 'pointer' }}
								onClick={handleSaveQuestion}
							/>
						</>
					) : (
						<>
							{editableQuestion ? (
								<>
									<Input
										bsSize="sm"
										placeholder="Ingrese pregunta múltiple"
										value={auxQuestionValue}
										onChange={(e) => setAuxQuestionValue(e.target.value)}
										onKeyDown={({ key }) => {
											if (key === 'Enter') handleUpdateQuestion();
											if (key === 'Escape') setEditableQuestion(false);
										}}
									/>
								</>
							) : (
								<>
									<span
										style={{
											color: '#333',
											fontSize: '14px',
											fontWeight: '700',
											lineHeight: 'normal'
										}}
										onDoubleClick={() => {
											setEditableQuestion(true);
											setAuxQuestionValue(questionValue);
										}}
									>
										{questionValue}
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-end',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										<Plus
											size={24}
											color="#d32f34"
											style={{ cursor: 'pointer' }}
											id="addNewQuestion"
											onClick={handleAddQuestion}
										/>
										<UncontrolledTooltip target="addNewQuestion">
											Agregar nueva pregunta
										</UncontrolledTooltip>
										<Minus
											size={24}
											color="#d32f34"
											style={{ cursor: 'pointer' }}
											id="removeQuestion"
											onClick={removeQuestion}
										/>
										<UncontrolledTooltip target="removeQuestion">
											Eliminar pregunta
										</UncontrolledTooltip>
									</div>
								</>
							)}
						</>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
						gap: '8px'
					}}
				>
					<i class="fa fa-filter"></i>
					<span
						style={{
							color: '#333',
							fontSize: '12px',
							fontWeight: '400',
							lineHeight: 'normal',
							cursor: 'pointer',
							textDecoration: 'underline'
						}}
						onClick={toggleQuestionConditions}
					>
						Condicionar pregunta
					</span>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					gap: '8px',
					width: '100%'
				}}
			>
				{questionIsSaved && (
					<>
						{answers.map((answer, index) => {
							if (editableAnswer === index) {
								return (
									<Input
										bsSize="sm"
										placeholder="Ingrese la respuesta"
										value={auxEditableAnswer}
										onChange={(e) => setAuxEditableAnswer(e.target.value)}
										onKeyDown={({ key }) => {
											if (key === 'Enter') handleUpdateAnswer();
											if (key === 'Escape') setEditableAnswer(null);
										}}
									/>
								);
							} else {
								return (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											height: '32px',
											gap: '24px'
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '100%'
											}}
										>
											<span
												style={{
													color: '#333',
													fontSize: '14px',
													fontWeight: '400',
													lineHeight: 'normal'
												}}
												onDoubleClick={() => {
													setEditableAnswer(index);
													setAuxEditableAnswer(answer.option);
												}}
											>
												{answer.option}
											</span>
											<span
												style={{
													color: '#333',
													fontSize: '12px',
													fontWeight: '400',
													lineHeight: 'normal',
													cursor: 'pointer',
													textDecoration: 'underline'
												}}
												onClick={() => {
													setAnswerTitle(answer.option);
													setSelectedAnswerToValue(index);
													toggleAnswerValue();
												}}
											>
												Valorar respuesta
											</span>
										</div>
										<Minus
											size={24}
											color="#d32f34"
											style={{ cursor: 'pointer' }}
											onClick={() => handleRemoveAnswer(index)}
										/>
									</div>
								);
							}
						})}
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								height: '32px',
								gap: '24px'
							}}
						>
							<Input
								bsSize="sm"
								id={`new-subcategoryName`}
								name={`new-subcategoryName`}
								placeholder="Ingrese la respuesta"
								value={newAnswer}
								onChange={(e) => setNewAnswer(e.target.value)}
								onKeyDown={({ key }) => key === 'Enter' && handleAddAnswer()}
							/>
							<Plus size={24} color="#d32f34" style={{ cursor: 'pointer' }} onClick={handleAddAnswer} />
						</div>
					</>
				)}
			</div>
			<ValoracionRespuestas
				answerTitle={answerTitle}
				handleSetAnswerValue={handleSetAnswerValue}
				handleCancelAnswerValue={handleCancelAnswerValue}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				isOpen={answerValueIsOpen}
				toggle={toggleAnswerValue}
			/>
			<CondicionantePregunta
				isOpen={questionConditionsIsOpen}
				toggle={toggleQuestionConditions}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				title={questionValue}
				handleAddQuestionCondition={handleAddQuestionCondition}
				allQuestions={allQuestions}
			/>
			<LoadingAffiliation isOpen={isLoading} />
		</div>
	);
};

const SimpleQuestion = ({ setQuestions, categories, subcategories, stageId, poll, allQuestions }) => {
	/**
	 * Question value
	 */
	const [questionValue, setQuestionValue] = useState('');
	const [questionId, setQuestionId] = useState(null);

	/**
	 * Question value conditional
	 */
	const [questionIsSaved, setQuestionIsSaved] = useState(false);

	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Open answer value modal
	 */
	const [answerValueIsOpen, setAnswerValueIsOpen] = useState(false);
	const toggleAnswerValue = () => setAnswerValueIsOpen(!answerValueIsOpen);

	/**
	 * Handlers to modify questions
	 */
	const [editableQuestion, setEditableQuestion] = useState(false);
	const [auxQuestionValue, setAuxQuestionValue] = useState('');

	/**
	 * Handle the answers of simple question
	 */
	const [answers, setAnswers] = useState([
		{
			option: 'Sí',
			option_score: null,
			question: null,
			category_subcategory: null
		},
		{
			option: 'No',
			option_score: null,
			question: null,
			category_subcategory: null
		}
	]);

	/**
	 * Handle selected answer to set value
	 */
	const [selectedAnswerToValue, setSelectedAnswerToValue] = useState(null);
	const [answerTitle, setAnswerTitle] = useState('');

	/**
	 * Open question conditionals modal
	 */
	const [questionConditionsIsOpen, setQuestionConditionsIsOpen] = useState(false);
	const toggleQuestionConditions = () => setQuestionConditionsIsOpen(!questionConditionsIsOpen);

	/**
	 * Handler for question conditions
	 */
	const [questionConditions, setQuestionConditions] = useState({});

	const handleSaveQuestion = () => {
		if (questionValue) {
			setIsLoading(true);

			let data = {
				question: questionValue,
				poll: poll,
				stage: stageId,
				type_question: 1,
				question_score: 0,
				category: categories[0].value,
				is_conditional: true
			};

			if (Object.keys(questionConditions).length > 0) {
				data = { ...data, ...questionConditions };
			} else {
				data = { ...data, conditionals_questions: [], option_conditional: [] };
			}

			axiosInstance({
				url: '/polls/question/',
				method: 'POST',
				data
			})
				.then(({ data }) => {
					setQuestionId(data.id);

					setAnswers((prev) =>
						prev.map((answer) => ({
							question: data.id,
							...answer
						}))
					);

					setQuestionIsSaved(true);
				})
				.catch((err) => {
					toast.error('No se pudo guardar la pregunta', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				})
				.finally(() => setIsLoading(false));
		} else {
			toast.error(`Debe escribir a la pregunta.`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	};

	const handleModifyQuestion = () => {
		setIsLoading(true);

		let data = {
			question: questionValue,
			poll: poll,
			stage: stageId,
			type_question: 1,
			question_score: 0,
			category: categories[0].value,
			is_conditional: true
		};

		if (Object.keys(questionConditions).length > 0) data = { ...data, ...questionConditions };

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'PUT',
			data
		})
			.then(() => setQuestionValue(auxQuestionValue))
			.catch(() => {
				setIsLoading(false);
				setAuxQuestionValue('');
				setEditableQuestion(false);
			});
	};

	const handleAddQuestion = async () => {
		/**
		 * Boolean to check if any answer has not value
		 */
		const notAllValue = answers.some((obj) => obj.option_score === null || obj.category_subcategory.length === 0);

		if (!notAllValue) {
			setIsLoading(true);

			try {
				let auxQuestion = { id: questionId, question: questionValue, type: 3 };

				let auxAnswers = [];

				for (const i in answers) {
					const answer = answers[i];

					await axiosInstance({
						url: '/polls/option/',
						method: 'POST',
						data: {
							option: answer.option,
							option_score: answer.option_score,
							question: questionId,
							category_subcategory: answer.category_subcategory
						}
					}).then(({ data }) => {
						auxAnswers.push({ ...answer, id: data.id });
					});
				}

				auxQuestion.answers = auxAnswers;

				setQuestions((prev) => [...prev, auxQuestion]);
			} catch (err) {
				toast.error(err.response.data.question[0], {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			} finally {
				setQuestionValue('');
				setQuestionId(null);
				setQuestionIsSaved(false);

				setAnswers([
					{
						option: 'Sí',
						option_score: null,
						question: null,
						category_subcategory: null
					},
					{
						option: 'No',
						option_score: null,
						question: null,
						category_subcategory: null
					}
				]);

				setIsLoading(false);
			}
		} else {
			toast.error('Alguna de las respuestas no tiene un valor asignado.', {
				position: 'top-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored'
			});
		}
	};

	const removeQuestion = () => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'DELETE'
		})
			.then(() => {
				setQuestionValue('');
				setQuestionId(null);
				setQuestionIsSaved(false);
				setAnswerValueIsOpen(false);
				setSelectedAnswerToValue(null);
				setAnswerTitle('');
				setEditableQuestion(false);
				setAuxQuestionValue('');
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleSetAnswerValue = async (rate, cat_subcat) => {
		const auxAnswers = answers.map((obj, index) => {
			if (index === selectedAnswerToValue) {
				setSelectedAnswerToValue(null);

				return { ...obj, option_score: rate, category_subcategory: [cat_subcat], question: questionId };
			}

			return { ...obj, question: questionId };
		});

		setAnswers(auxAnswers);
		setAnswerTitle('');

		toggleAnswerValue();
	};

	const handleCancelAnswerValue = () => {
		setSelectedAnswerToValue(null);
		setAnswerTitle('');

		toggleAnswerValue();
	};

	const handleAddQuestionCondition = (conditions) => {
		if (questionIsSaved) {
			setIsLoading(true);
			axiosInstance({
				url: `/polls/question/${questionId}/`,
				method: 'PUT',
				data: {
					...conditions,
					...questionConditions,
					poll: poll,
					stage: stageId,
					question: questionValue,
					type_question: 1,
					question_score: 0,
					category: categories[0].value,
					is_conditional: true
				}
			})
				.catch((err) => console.log(err))
				.finally(() => setIsLoading(false));
		} else {
			setQuestionConditions(conditions);
		}

		toggleQuestionConditions();
	};

	return (
		<div
			className="mb-2"
			style={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#F8F8F8',
				padding: '8px 16px',
				gap: '8px',
				alignItems: 'start',
				width: '100%',
				borderRadius: '5px'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					width: '100%',
					gap: '8px'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: '24px'
					}}
				>
					{!questionIsSaved ? (
						<>
							<Input
								bsSize="sm"
								placeholder="Ingrese pregunta simple"
								value={questionValue}
								onChange={(e) => setQuestionValue(e.target.value)}
								onKeyDown={({ key }) => key === 'Enter' && handleSaveQuestion()}
							/>
							<ChevronRight
								size={24}
								color="#d32f34"
								style={{ cursor: 'pointer' }}
								onClick={handleSaveQuestion}
							/>
						</>
					) : (
						<>
							{editableQuestion ? (
								<>
									<Input
										bsSize="sm"
										placeholder="Ingrese pregunta simple"
										value={auxQuestionValue}
										onChange={(e) => setAuxQuestionValue(e.target.value)}
										onKeyDown={({ key }) => {
											if (key === 'Enter') handleModifyQuestion();
											if (key === 'Escape') setEditableQuestion(false);
										}}
									/>
								</>
							) : (
								<>
									<span
										style={{
											color: '#333',
											fontSize: '14px',
											fontWeight: '700',
											lineHeight: 'normal'
										}}
										onDoubleClick={() => {
											setEditableQuestion(true);
											setAuxQuestionValue(questionValue);
										}}
									>
										{questionValue}
									</span>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-end',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										<Plus
											size={24}
											color="#d32f34"
											style={{ cursor: 'pointer' }}
											id="addNewQuestion"
											onClick={handleAddQuestion}
										/>
										<UncontrolledTooltip target="addNewQuestion">
											Agregar nueva pregunta
										</UncontrolledTooltip>
										<Minus
											size={24}
											color="#d32f34"
											style={{ cursor: 'pointer' }}
											id="removeQuestion"
											onClick={removeQuestion}
										/>
										<UncontrolledTooltip target="removeQuestion">
											Eliminar pregunta
										</UncontrolledTooltip>
									</div>
								</>
							)}
						</>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
						gap: '8px'
					}}
				>
					<i class="fa fa-filter"></i>
					<span
						style={{
							color: '#333',
							fontSize: '12px',
							fontWeight: '400',
							lineHeight: 'normal',
							cursor: 'pointer',
							textDecoration: 'underline'
						}}
						onClick={toggleQuestionConditions}
					>
						Condicionar pregunta
					</span>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '8px 16px',
					gap: '8px',
					width: '100%'
				}}
			>
				{questionIsSaved && (
					<>
						{answers.map((answer, index) => {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										height: '32px',
										gap: '24px'
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%'
										}}
									>
										<span
											style={{
												color: '#333',
												fontSize: '14px',
												fontWeight: '400',
												lineHeight: 'normal'
											}}
										>
											{answer.option}
										</span>
										<span
											style={{
												color: '#333',
												fontSize: '12px',
												fontWeight: '400',
												lineHeight: 'normal',
												cursor: 'pointer',
												textDecoration: 'underline'
											}}
											// onClick={toggleAnswerValue}
											onClick={() => {
												setAnswerTitle(answer.option);
												setSelectedAnswerToValue(index);
												toggleAnswerValue();
											}}
										>
											Valorar respuesta
										</span>
									</div>
								</div>
							);
						})}
					</>
				)}
			</div>
			<ValoracionRespuestas
				answerTitle={answerTitle}
				handleSetAnswerValue={handleSetAnswerValue}
				handleCancelAnswerValue={handleCancelAnswerValue}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				isOpen={answerValueIsOpen}
				toggle={toggleAnswerValue}
			/>
			<CondicionantePregunta
				isOpen={questionConditionsIsOpen}
				toggle={toggleQuestionConditions}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				title={questionValue}
				handleAddQuestionCondition={handleAddQuestionCondition}
				allQuestions={allQuestions}
			/>
			<LoadingAffiliation isOpen={isLoading} />
		</div>
	);
};

const PreguntasRelacionables = ({
	questions,
	setQuestions,
	categories,
	subcategories,
	stageId,
	poll,
	questionTypes,
	allQuestions
}) => {
	/**
	 * Loader
	 */
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Update value to existing answers
	 */
	const [answerToUpdateIsOpen, setAnswerToUpdateIsOpen] = useState(false);
	const toggleAnswerToUpdate = () => setAnswerToUpdateIsOpen(!answerToUpdateIsOpen);

	/**
	 * Answer to uodate values
	 */
	const [answerIdToUpdate, setAnswerIdToUpdate] = useState(null);
	const [answerTitleToUpdate, setAnswerTitleToUpdate] = useState('');

	const [selectedType, setSelectedType] = useState(null);

	/**
	 * Exiting question conditions handlers
	 */
	const [questionConditionsIsOpen, setQuestionConditionsIsOpen] = useState(false);

	const toggleQuestionConditions = () => setQuestionConditionsIsOpen(!questionConditionsIsOpen);

	/**
	 * Question to add conditions
	 */
	const [questionIdCondition, setQuestionIdCondition] = useState(null);
	const [questionTitleCondition, setQuestionTitleCondition] = useState('');

	/**
	 * Handlers to update question values
	 */
	const [selectedId, setSelectedId] = useState(null);
	const [modifyValue, setModifyValue] = useState('');

	/**
	 * Handle answer new value
	 */
	const handleUpdateAnswerValue = (rate, cat_subcat) => {
		setIsLoading(true);

		const auxAnswer = questions
			.flatMap((question) => question.answers)
			.filter((answer) => answer.id === answerIdToUpdate)[0];

		axiosInstance({
			url: `/polls/option/${answerIdToUpdate}/`,
			method: 'PUT',
			data: {
				option: auxAnswer.option,
				option_score: rate,
				question: auxAnswer.question,
				category_subcategory: [cat_subcat]
			}
		})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));

		setAnswerIdToUpdate(null);
		setAnswerTitleToUpdate('');

		toggleAnswerToUpdate();
	};

	const handleCancelUpdateAnswerValue = () => {
		setAnswerIdToUpdate(null);
		setAnswerTitleToUpdate('');

		toggleAnswerToUpdate();
	};

	const handleRemoveExistingQuestion = (questionId) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionId}/`,
			method: 'DELETE'
		})
			.then(() => {
				const newQuestions = questions.filter((question) => question.id !== questionId);

				setQuestions(newQuestions);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleRemoveExistingAnswer = (questionId, answerId) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/option/${answerId}/`,
			method: 'DELETE'
		})
			.then(() => {
				const newQuestions = questions.map((question) => {
					if (question.id === questionId) {
						const newAnswers = question.answers.filter((answer) => answer.id !== answerId);

						return { ...question, answers: newAnswers };
					}

					return question;
				});

				setQuestions(newQuestions);
			})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleAddQuestionCondition = (conditions) => {
		setIsLoading(true);

		axiosInstance({
			url: `/polls/question/${questionIdCondition}/`,
			method: 'PUT',
			data: {
				...conditions,
				poll: poll,
				stage: stageId,
				question: questionTitleCondition,
				type_question: 1,
				question_score: 0,
				category: categories[0].value,
				is_conditional: true
			}
		})
			.catch((err) => console.log(err))
			.finally(() => setIsLoading(false));
	};

	const handleModifyQuestion = () => {
		setIsLoading(true);

		let data = {
			question: modifyValue,
			question_score: 0,
			type_question: selectedType.value === 3 ? 1 : selectedType.value,
			poll,
			stage: stageId,
			category: categories[0].value,
			is_conditional: false,
			conditionals_questions: [],
			option_conditional: []
		};

		// if (Object.keys(questionConditions).length > 0) data = { ...data, ...questionConditions };

		axiosInstance({
			url: `/polls/question/${selectedId}/`,
			method: 'PUT',
			data
		})
			.then(() => {
				const auxQuestion = questions.map((question) => {
					if (selectedId === question.id) {
						return { ...question, question: modifyValue };
					}

					return question;
				});
				setQuestions(auxQuestion);
			})
			.catch(() => {
				setIsLoading(false);
				setModifyValue('');
				setSelectedId(false);
			});
	};

	return (
		<Container fluid style={{ padding: '16px', display: 'flex', gap: '12px', flexDirection: 'column' }}>
			<Row>
				<Col lg={6}>
					<span className="survey-question-type-title">Pregunta relacionable</span>
				</Col>
				<Col lg={6}>
					<span className="survey-question-type-title">Respuestas</span>
				</Col>
			</Row>
			<Row className="mb-0">
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						backgroundColor: '#F8F8F8',
						padding: '8px 16px',
						gap: '8px',
						alignItems: 'start',
						width: '100%',
						borderRadius: '5px'
					}}
				>
					<Col lg={6}>
						<Select
							name="selectedTypeQuestions"
							options={questionTypes.filter(({ value }) => value === 5 || value === 2 || value === 3)}
							id="selectedSurveOption"
							placeholder="Seleccione un tipo de pregunta"
							value={selectedType}
							onChange={(option) => {
								if (questions.length > 0) {
									toast.error('No puede cambiar el tipo de pregunta con preguntas existentes.', {
										position: 'top-right',
										autoClose: 3000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'colored'
									});

									return;
								}

								setSelectedType(option);
							}}
						/>
					</Col>
				</div>
			</Row>
			<Row>
				{questions.length > 0 &&
					questions.map((qstn) => {
						const { id: questionId, question } = qstn;

						const placeholder =
							selectedType.value === 2
								? 'Ingrese pregunta múltiple'
								: selectedType.value === 3
								? 'Ingrese pregunta simple'
								: 'Ingrese pregunta abierta';

						return (
							<div
								className="mb-2"
								style={{
									display: 'flex',
									flexDirection: 'row',
									backgroundColor: '#F8F8F8',
									padding: '8px 16px',
									gap: '8px',
									alignItems: 'start',
									width: '100%',
									borderRadius: '5px'
								}}
								key={questionId}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										padding: '8px 16px',
										width: '100%',
										gap: '8px'
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											gap: '24px'
										}}
									>
										{selectedId === questionId ? (
											<Input
												bsSize="sm"
												placeholder={placeholder}
												value={modifyValue}
												onChange={(e) => setModifyValue(e.target.value)}
												onKeyDown={({ key }) => {
													if (key === 'Enter') handleModifyQuestion();
													if (key === 'Escape') setSelectedId(null);
												}}
											/>
										) : (
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
													alignItems: 'center',
													gap: '24px',
													width: '100%'
												}}
											>
												<span
													style={{
														color: '#333',
														fontSize: '14px',
														fontWeight: '700',
														lineHeight: 'normal'
													}}
													onDoubleClick={() => {
														setSelectedId(questionId);
														setModifyValue(question);
													}}
												>
													{question}
												</span>
												<Minus
													size={24}
													color="#d32f34"
													style={{ cursor: 'pointer' }}
													onClick={() => handleRemoveExistingQuestion(questionId)}
												/>
											</div>
										)}
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-start',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										<i class="fa fa-filter"></i>
										<span
											style={{
												color: '#333',
												fontSize: '12px',
												fontWeight: '400',
												lineHeight: 'normal',
												cursor: 'pointer',
												textDecoration: 'underline'
											}}
											onClick={() => {
												setQuestionIdCondition(questionId);
												setQuestionTitleCondition(question);
												toggleQuestionConditions();
											}}
										>
											Condicionar pregunta
										</span>
									</div>
								</div>
								{qstn?.answers?.length > 0 && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											padding: '8px 16px',
											gap: '4px',
											width: '100%'
										}}
									>
										{qstn.answers.map((answer) => (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													width: '100%'
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'space-between',
														alignItems: 'center',
														height: '32px',
														gap: '24px'
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '100%'
														}}
													>
														<span
															style={{
																color: '#333',
																fontSize: '14px',
																fontWeight: '400',
																lineHeight: 'normal'
															}}
														>
															{answer.option}
														</span>
														<span
															style={{
																color: '#333',
																fontSize: '12px',
																fontWeight: '400',
																lineHeight: 'normal',
																cursor: 'pointer',
																textDecoration: 'underline'
															}}
															onClick={() => {
																setAnswerIdToUpdate(answer.id);
																setAnswerTitleToUpdate(answer.option);

																toggleAnswerToUpdate();
															}}
														>
															Valorar respuesta
														</span>
													</div>
													<Minus
														size={24}
														color="#d32f34"
														style={{ cursor: 'pointer' }}
														onClick={() =>
															handleRemoveExistingAnswer(questionId, answer.id)
														}
													/>
												</div>
											</div>
										))}
									</div>
								)}
							</div>
						);
					})}
				{selectedType?.value === 2 && (
					<MultiQuestion
						setQuestions={setQuestions}
						categories={categories}
						subcategories={subcategories}
						stageId={stageId}
						poll={poll}
						allQuestions={allQuestions}
					/>
				)}
				{selectedType?.value === 3 && (
					<SimpleQuestion
						setQuestions={setQuestions}
						categories={categories}
						subcategories={subcategories}
						stageId={stageId}
						poll={poll}
						allQuestions={allQuestions}
					/>
				)}
				{selectedType?.value === 5 && (
					<OpenQuestion
						setQuestions={setQuestions}
						categories={categories}
						subcategories={subcategories}
						stageId={stageId}
						poll={poll}
						allQuestions={allQuestions}
					/>
				)}
			</Row>
			<ToastContainer />
			<ValoracionRespuestas
				answerTitle={answerTitleToUpdate}
				handleSetAnswerValue={handleUpdateAnswerValue}
				handleCancelAnswerValue={handleCancelUpdateAnswerValue}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				isOpen={answerToUpdateIsOpen}
				toggle={toggleAnswerToUpdate}
			/>
			<CondicionantePregunta
				isOpen={questionConditionsIsOpen}
				toggle={toggleQuestionConditions}
				categoriesOptions={categories}
				subcategoriesOptions={subcategories}
				title={questionTitleCondition}
				handleAddQuestionCondition={handleAddQuestionCondition}
				allQuestions={allQuestions}
			/>
			<LoadingAffiliation isOpen={isLoading} />
		</Container>
	);
};

export default PreguntasRelacionables;
