import React, { useState } from 'react';
import { Container, Row, Col, TabPane, FormGroup, Label, Input, Button } from 'reactstrap';
import './createPyramid.scss';

import InternalLoader from 'layout/internal-loader';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';

import Multiselect from 'components/multiselect/Multiselect';
import Tab from 'components/tab/Tab.';
import TabContentContainer from 'components/tab/TabContent';
import { useTab } from 'components/tab/useTab';
import {
	createPyramid,
	useEntities,
	useFDistrictsWithFilters,
	useLocalDistricsWithFilters,
	useMunicipalitiesWithFilters,
	useSectionsWithFilters
} from 'utils/fetch/entities';
import { useMultiSelectState } from 'components/multiselect/useMultiSelectState';
import { Link, useNavigate } from 'react-router-dom';
import AppliedTerritories from 'components/appliedTerritories/appliedTerritories';

const TABS = ['D. Federal', 'D. Local', 'Municipio', 'Sección'];

function CreatePyramid(props) {
	const navigate = useNavigate();
	const { activeTab, handleChangeTab } = useTab(TABS[0], { replaceUrl: false });
	const { loading: loadingEntities, data: entities } = useEntities();

	const [name, setName] = useState('');
	const [numberOfLevels, setNumberOfLevels] = useState();
	const [description, setDescription] = useState('');
	const [selectedEntity, setSelectedEntity] = useState();
	const { selectedOptions: selectedSections, handleChange: setSelectedOptions } = useMultiSelectState();
	const { selectedOptions: selectedFDistrics, handleChange: setSelectedFDistrics } = useMultiSelectState();
	const { selectedOptions: selectedLDistrics, handleChange: setSelectedLDistrics } = useMultiSelectState();
	const { selectedOptions: selectedMunicipalities, handleChange: setSelectedMunicipalities } = useMultiSelectState();

	const { data: sections, loading: loadingSections } = useSectionsWithFilters(selectedEntity);
	const { data: lDistrics, loading: loadingLDistrics } = useLocalDistricsWithFilters(selectedEntity);
	const { data: fDistrics, loading: loadingFDistrics } = useFDistrictsWithFilters(selectedEntity);
	const { data: municipalities, loading: loadingMunicipalities } = useMunicipalitiesWithFilters(selectedEntity);

	if (loadingEntities) {
		return <InternalLoader />;
	}

	const handleSubmit = async () => {
		try {
			const dataToSubmit = {
				levels_number: numberOfLevels,
				name,
				description,
				entities: [selectedEntity?.value],
				districts: selectedFDistrics.map((item) => item.id),
				districts_local: selectedLDistrics.map((item) => item.id),
				municipalities: selectedMunicipalities.map((item) => item.id),
				sections: selectedSections.map((section) => section.id)
			};

			if (
				numberOfLevels &&
				name &&
				description &&
				selectedEntity?.value &&
				(selectedLDistrics.length > 0 ||
					selectedFDistrics.length > 0 ||
					selectedMunicipalities.length > 0 ||
					selectedSections.length > 0)
			) {
				try {
					const { data } = await createPyramid(dataToSubmit);
					navigate(`/piramides/${data.id}/levels`);
				} catch (error) {
					const errorMessage = error?.response?.data
						? error?.response?.data.name[0]
						: 'Error interno del servidor';
					toast.error(errorMessage, {
						autoClose: 2000,
						position: 'top-right',
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored'
					});
				}
			} else {
				toast.error('Faltan datos en el formulario', {
					position: 'top-right',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored'
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const entiesforSelect = entities?.map(({ id, name }) => ({ label: name, value: id }));

	return (
		<div className="create-pyramid-page">
			<Container fluid>
				<Row className="">
					<Col className="mr-auto">
						<h3>Crear pirámide</h3>
					</Col>
					<Col sm="auto" className="ml-auto">
						<h3 className="font-primary">Paso 1 de 3</h3>
					</Col>
				</Row>
				<div className="containerForm">
					<Row>
						<Col sm="12" xs="12">
							<h4 className="title">Datos de pirámide</h4>
						</Col>
						<Col sm="6">
							<FormGroup>
								<Label className="col-form-label">Nombre de pirámide</Label>
								<Input
									className="form-control"
									type="text"
									required=""
									placeholder="Ingresar nombre"
									onChange={(evt) => setName(evt.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col sm="6" xs="12">
							<FormGroup>
								<Label className="col-form-label">Número de niveles</Label>
								<Input
									type="number"
									placeholder="Número de niveles"
									max={10}
									min={1}
									onChange={(evt) => setNumberOfLevels(evt.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col sm="6" xs="12">
							<FormGroup>
								<Label className="col-form-label">Descripción corta</Label>
								<Input
									className="form-control"
									type="text"
									required=""
									placeholder="Descripción de pirámide"
									onChange={(evt) => setDescription(evt.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col sm="6" xs="12">
							<FormGroup>
								<Label className="col-form-label">Entidad</Label>
								<Select
									isClearable
									placeholder="Seleccionar Entidad"
									options={entiesforSelect}
									onChange={setSelectedEntity}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col sm="12" xs="12">
							<h4 className="title m-0">Demarcación de territorios</h4>
						</Col>
					</Row>

					<Row className="">
						<Col sm="6">
							<Tab activeTab={activeTab} setActiveTab={handleChangeTab} tabs={TABS} small gray />

							<TabContentContainer activeTab={activeTab} noPadding>
								<TabPane tabId={TABS[0]}>
									<Multiselect
										options={fDistrics}
										onChange={setSelectedFDistrics}
										loading={loadingFDistrics}
										disabled={
											selectedLDistrics.length > 0 ||
											selectedMunicipalities.length > 0 ||
											selectedSections.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[1]}>
									<Multiselect
										options={lDistrics}
										onChange={setSelectedLDistrics}
										loading={loadingLDistrics}
										disabled={
											selectedFDistrics.length > 0 ||
											selectedMunicipalities.length > 0 ||
											selectedSections.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[2]}>
									<Multiselect
										options={municipalities}
										onChange={setSelectedMunicipalities}
										loading={loadingMunicipalities}
										disabled={
											selectedLDistrics.length > 0 ||
											selectedLDistrics.length > 0 ||
											selectedSections.length > 0
										}
									/>
								</TabPane>
								<TabPane tabId={TABS[3]}>
									<Multiselect
										options={sections}
										onChange={setSelectedOptions}
										loading={loadingSections}
										disabled={
											selectedLDistrics.length > 0 ||
											selectedFDistrics.length > 0 ||
											selectedMunicipalities.length > 0
										}
									/>
								</TabPane>
							</TabContentContainer>
						</Col>
						<Col sm="6">
							<AppliedTerritories
								sections={selectedSections}
								municipalities={selectedMunicipalities}
								lDistricts={selectedLDistrics}
								fDistrics={selectedFDistrics}
							/>
						</Col>
					</Row>

					<Row>
						<Col className="mr-auto">
							<Link to="/piramides">
								<Button size="md" className="" color="primary">
									Volver atras
								</Button>
							</Link>
						</Col>

						<Col sm="auto">
							<Button size="md" className="" color="primary" onClick={handleSubmit}>
								Guardar
							</Button>
						</Col>
					</Row>
				</div>
			</Container>
			<ToastContainer />
		</div>
	);
}

export default CreatePyramid;
